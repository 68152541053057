import ServiceBase from '../base/ServiceBase'

export default class TokenIntegracaoService {
  static async getToken (codigoEmp) {
    return await ServiceBase.getBase(`token-integracao/getToken/${codigoEmp}`)
  }

  static async saveToken (codigoEmp) {
    return await ServiceBase.getBase(`token-integracao/saveToken/${codigoEmp}`)
  }
}
