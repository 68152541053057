import React from 'react'

class InputFile extends React.Component {
  constructor (props) {
    super(props)
    this.fileInput = React.createRef()
  }

    uploadHandler = (event) => {
      event.preventDefault()
      this.props.uploadHandler(this.fileInput.current)
      this.setState({ file: this.fileInput.current.files[0] })
    }

    chooseLabel = ({ label, size = 31 }) => {
      if (window.innerWidth < 1500) {
        size = 23
      }
      if (label) {
        if (label.length > size) {
          return label.slice(0, size) + '...' + label.slice(-3)
        }
        return label
      } else {
        return 'Selecione o arquivo'
      }
    }

    render () {
      return (
        <label className={`custom-file-upload ${this.props.className}`}>
          <div className={this.props.disabled ? 'disabled' : 'inputfile p-button justify-content-center'} >
            <input type="file" ref={this.fileInput} onInput={this.uploadHandler} />
            <i className="pi pi-upload pr-3"/>
            <span>
              {this.chooseLabel(this.props.label)}
            </span>
          </div>
        </label>
      )
    }
}

export default InputFile
