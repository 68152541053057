import { postMensagemGlobalDTO } from '../../dtos/suporte/MensagemGlobalDTO'
import ServiceBase from '../base/ServiceBase'

export default class MensagemGlobalService {
  async get (id) {
    return await ServiceBase.getBase(`mensagemGlobal/${id}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('mensagemGlobal', postMensagemGlobalDTO(data))
  }
}
