import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { getPessoaDTO } from '../../../../dtos/cadastro/PessoaDTO'
import { formatCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import EmpresasAndPessoasJuridicasService from '../../../../services/cadastro/EmpresasAndPessoasJuridicasService'
import TransferenciaITEService from '../../../../services/zeroKm/ITE/TransferenciaITEService'
import { Checkbox } from 'primereact/checkbox'

const TransferirIteModal = ({ visible, onHide, veiculoParaTransferir, getAll }) => {
  const [ITE, setITE] = useState(null)
  const [sugestaoITE, setSugestaoITE] = useState([])
  const [valor, setValor] = useState(veiculoParaTransferir.valorVeiculo)
  const [leasing, setLeasing] = useState(false)

  useEffect(() => {
    if (veiculoParaTransferir.valorVeiculo) {
      setValor(veiculoParaTransferir.valorVeiculo)
    }
  }, [veiculoParaTransferir])

  const sugerirITEs = async () => {
    try {
      const empresasAndPessoasJuridicasService = new EmpresasAndPessoasJuridicasService()

      const ITEs = (await empresasAndPessoasJuridicasService.filter(`descricaoFiltro=${ITE.nome}`)).data

      setSugestaoITE(ITEs)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma ITE!')
    }
  }

  const handleTransferirVeiculo = async () => {
    try {
      if (!ITE?.cnpj) {
        showErrorMessage('Por favor selecionar uma ITE válida')
        return
      }

      if (valor < 0.01) {
        showErrorMessage('Por favor informar um valor válido')
        return
      }

      await TransferenciaITEService.autorizarTransferenciaITE(ITE.cnpj, veiculoParaTransferir.id, valor, leasing)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValor(formatCurrencyToDecimal(novoValor))
  }

  function handleChangeITE (event) {
    setITE({ nome: event.target.value })
  }

  function handleChangeLeasing (value) {
    setLeasing(!value)
  }

  function hideModal () {
    setITE(null)
    setValor(0)
    onHide()
  }

  return (
    <Modal
      header="Autorizar transferência para ITE"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleTransferirVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <SearchInput
          //AutoComplete
            label="ITE"
            field="nome"
            value={ITE?.nome && ITE}
            suggestions={sugestaoITE}
            completeMethod={sugerirITEs}
            onChange={handleChangeITE}
            onSelect={(e) => setITE(e.value)}
            className="inputfield w-full"
            //FiltroModal
            filtroTitle="Pesquisa de pessoa jurídica e empresas"
            service={EmpresasAndPessoasJuridicasService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nome', nome: 'Nome' },
              { campo: 'cnpj', nome: 'CNPJ', format: formatCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            label="Valor"
            placeholder="Valor do veículo"
            className="inputfield w-full"
            onChange={(e) => handleChangeValor(e)}
            value={formatToCurrency(valor)}
          />
        </div>
        <div className="field checkbox-container col-3">
          <Checkbox
            name="leasing"
            value={leasing}
            onChange={(e) => handleChangeLeasing(e.target.value)}
            checked={leasing}
            disabled={false}
          />
          <label className="p-checkbox-label mb-0 pl-2">Possui Leasing?</label>
        </div>
      </div>
    </Modal>
  )
}

export default TransferirIteModal
