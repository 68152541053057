import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class ReceberTransferenciaNaITEService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`autorizacoes-transferencia-na-ITE?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`autorizacoes-transferencia-na-ITE?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async receberTransferencia (estoqueOrigemEmIte, codigoClienteMontadora, chaveRemessa, idAutorizacao, valorProduto, chaveProduto, chaveServico, chassi) {
    const receberTransferenciaVindoITERequest = {
      estoqueOrigemEmIte,
      codigoClienteMontadora,
      chaveRemessa,
      chaveProduto,
      chaveServico,
      idAutorizacao,
      valorProduto,
      chassi
    }

    return await ServiceBase.postBase('ite/receber-transferencia-na-ITE', receberTransferenciaVindoITERequest, ComunicaRenave)
  }
}
