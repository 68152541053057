import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { getVeiculoEntradaDTO } from '../../../dtos/renave/VeiculoEntradaDTO'
import { formatCnpj, formatDate } from '../../../helpers/formaters'
import ReceberTransferenciaNaITEService from '../../../services/zeroKm/ITE/ReceberTransferenciaNaITEService'
import ReceberTransferenciaNaITEModal from './ReceberTransferenciaNaITEModal'

export class ReceberTransferenciaNaITE extends List {
  constructor (props) {
    super(
      props,
      getVeiculoEntradaDTO,
      ReceberTransferenciaNaITEService,
      '',
      '',
      'pendenteRecebimento'
    )

    this.state = {
      ...this.state,
      veiculoSelecionado: {},
      visibleReceberTransferenciaNaITEModal: false
    }
  }

  render () {
    const state = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          <Button
            label={data.estoqueOrigemEmIte ? 'Receber de uma ITE' : 'Receber de um estabelecimento'}
            className="expansion-button"
            onClick={() => this.setState({ veiculoSelecionado: data, visibleReceberTransferenciaNaITEModal: true })}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Receber transferência na ITE</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'pendenteRecebimento' }, 'filter-option')}>
              <span className="option-label">Pendente de recebimento</span>
              <div className="option-quantity">{state.totalRecords}</div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Pesquisar"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column
              style={{ width: '25%' }}
              header="Veículo"
              field="chassi"
            />
            <Column
              style={{ width: '45%' }}
              header="Enviado de"
              body={(data) => `${data.nomeEstabelecimentoAutorizador} - (${formatCnpj(data.cnpjEstabelecimentoAutorizador)})`}
            />
            <Column
              style={{ width: '10%' }}
              header="Enviado em"
              body={(data) => data.dataHoraAutorizacao ? formatDate(data.dataHoraAutorizacao) : ''}
            />
          </DataList>
        </Container>
        <ReceberTransferenciaNaITEModal
          visible={state.visibleReceberTransferenciaNaITEModal}
          onHide={() => this.setState({ visibleReceberTransferenciaNaITEModal: false })}
          veiculoParaReceber={state.veiculoSelecionado}
          getAll={this.getAll}
        />
      </Page>
    )
  }
}
