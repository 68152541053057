import { postUsuarioDTO } from '../../dtos/cadastro/UsuarioDTO'
import ServiceBase from '../base/ServiceBase'

export default class UsuarioService {
  async get (id) {
    return await ServiceBase.getBase(`usuario/${id}`)
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`usuarios?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`usuarios?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('usuario', postUsuarioDTO(data))
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`usuario/${id}`)
  }

  static async getUsuarioLogado () {
    return await ServiceBase.getBase('usuario/get/logado')
  }

  static async enviarEmailConfirmacao (email) {
    return await ServiceBase.postBase('enviarEmailConfirmacao', { email })
  }

  static async changePassword (data) {
    return await ServiceBase.postBase('usuario/alterarSenha', data)
  }

  static async changePasswordBeforeLogin (data) {
    return await ServiceBase.postBase('alterarSenhaAntesLogin', data)
  }

  static async empresasAcessoUsuarioLogado () {
    return await ServiceBase.getBase('usuario/empresasAcesso/UsuarioLogado')
  }

  static async getUsuarioByEmail (email) {
    return await ServiceBase.getBase(`getUsuarioByEmail/${email}`)
  }
}
