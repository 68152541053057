import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EstoqueRenaveService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`veiculos/estoqueZeroKm?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async getByChassi (chassi) {
    return await ServiceBase.getBase(`veiculos/estoqueZeroKm/${chassi}`, ComunicaRenave)
  }

  static async getByPlaca (placa) {
    return await ServiceBase.getBase(`veiculos/estoqueUsados/${placa}`, ComunicaRenave)
  }

  static async sincronizarEstoqueRenave () {
    return await ServiceBase.postBase('veiculos/estoqueZeroKm/sincronizar', ComunicaRenave)
  }

  static async confirmarSaidaTodosMontadora () {
    return await ServiceBase.postBase('veiculos/estoqueZeroKm/confirmarSaidaTodos', ComunicaRenave)
  }
}
