import { server } from '../../helpers/server'

export default class ServiceBase {
  static async getBase (request, config = null) {
    try {
      let response

      if (config)
        response = await server().get(request, config)
      else
        response = await server().get(request)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async postBase (url, data, config = null, loading = true) {
    try {
      let response

      if (config)
        response = await server(loading).post(url, data, config)
      else
        response = await server(loading).post(url, data)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async deleteBase (request, config = null) {
    try {
      let response

      if (config)
        response = await server().delete(request, config)
      else
        response = await server().delete(request)

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }
}

export const ComunicaRenave = { headers: { ComunicaRenave: true } }
