import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EntradaMontadoraService {
  async getAll () {
    return await ServiceBase.getBase('montadora/getPendentesEntrada', ComunicaRenave)
  }

  async filter () {
    return await ServiceBase.getBase('montadora/getPendentesEntrada', ComunicaRenave)
  }

  static async confirmarEntrada (listaEntradas) {
    try {
      const response = await ServiceBase.postBase('montadora/confirmarEntrada', { listaEntradas }, ComunicaRenave)
      return response
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async cancelarEntrada (chassi) {
    return await ServiceBase.postBase(`montadora/cancelarEntrada/${chassi}`, ComunicaRenave)
  }

  static async criarVeiculoMock (sufixoChassi, veiculoAcabado) {
    return await ServiceBase.postBase('montadora/criarVeiculoMock', { sufixoChassi, veiculoAcabado }, ComunicaRenave)
  }
}
