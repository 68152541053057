import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import { getEstoqueRenaveDTO } from '../../dtos/renave/EstoqueRenaveDTO'
import { formatDateTime } from '../../helpers/formaters'
import EstoqueRenaveService from '../../services/suporte/EstoqueRenaveService'

export default class EstoqueRenave extends List {
  constructor (props) {
    super(
      props,
      getEstoqueRenaveDTO,
      EstoqueRenaveService,
      '',
      ''
    )

    this.state = {
      ...this.state
    }
  }

  sincronizarEstoqueRenave = async () => {
    try {
      await EstoqueRenaveService.sincronizarEstoqueRenave()
      showSuccessMessage('Estoque do renave sincronizado com o sistema com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao sincronizar o estoque renave com o estoque do sistema!')
    }
  }

   confirmarSaidaTodos = async () => {
     try {
       await EstoqueRenaveService.confirmarSaidaTodosMontadora()
       showSuccessMessage('Confirmação de saída executada com sucesso!')
     } catch (error) {
       showErrorMessage(error.message || 'Houve um erro ao confirmar a saída!')
     }
   }

   render () {
     const { rows, totalRecords, list, selected } = this.state

     return (
       <Page>
         <div className="flex justify-content-between align-items-center page-header">
           <h4>Estoque renave</h4>
         </div>
         <div className="table-options-container">
           <div className="table-options">
             <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
               <span className="option-label">Todos</span>
               <div className="option-quantity">
                 {totalRecords}
               </div>
             </div>
           </div>
           <div className="table-preferences">
             <Button
               label="Sincronizar estoque renave com o do sistema"
               onClick={() => this.sincronizarEstoqueRenave()}
               className="mb-3 mr-3"
             />
             {/* <Button
               label="Confirmar saída de todo o estoque que tem nota fiscal"
               onClick={() => this.confirmarSaidaTodos()}
               className="mb-3 mr-3"
             /> */}
           </div>
         </div>
         <Container>
           <DataList
             className="selection-datalist"
             onSelect={this.onSelect}
             data={list}
             selected={selected}
             rows={rows}
             totalRecords={totalRecords}
             responsive
           >
             <Column
               header="ID estoque"
               field="id"
               style={{ width: '8%', maxWidth: '8%' }}
               sortable
             />
             <Column
               header="Chassi"
               field="chassi"
               style={{ width: '10%', maxWidth: '10%' }}
               sortable
             />
             <Column
               header="Data/Hora entrada estoque"
               field="entradaEstoque.dataHoraEnvioNotaFiscalEntrada"
               body={data => data.entradaEstoque.dataHoraEnvioNotaFiscalEntrada ? formatDateTime(data.entradaEstoque.dataHoraEnvioNotaFiscalEntrada) : ''}
               style={{ width: '15%', maxWidth: '15%' }}
               sortable
             />
             <Column
               header="Estado"
               field="estado"
               style={{ width: '5%', maxWidth: '5%' }}
               sortable
             />
           </DataList>
           <div className="table-footer">
             <div className="grid col-12 justify-content-end"></div>
           </div>
         </Container>
       </Page>
     )
   }
}
