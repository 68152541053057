import moment from 'moment-timezone'
import { showSuccessMessage } from '../components/utils/Message'
import { getStore } from '../redux/store'
import ReportService from '../services/report/ReporteService'

export default class CommonHelper {
  static isDesktop () {
    return window.innerWidth > 1024
  }

  static isBiggerResolution () {
    return window.innerWidth > 1500
  }

  static async renderizarHTML (rota, data) {
    let htmlContent = await ReportService.getHTML(rota, data)
    htmlContent += `
    <script>
      window.onload = function() { window.print();}
    </script>`

    const blob = new Blob([htmlContent], { type: 'text/html' })

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.style.display = 'none' // Oculta o link
    document.body.appendChild(link)

    link.click()

    URL.revokeObjectURL(url)
  }

  static currencyToInteger (value) {
    if (!value) return 0

    return parseInt(String(value * 100).split('.')[0])
  }

  static integerToCurrency (value) {
    if (!value) return null

    return Number(value) / 100
  }

  static dateToAmerican (date) {
    if (!date) return null

    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  static dateTimeToAmerican (date) {
    if (!date) return null

    return moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
  }

  static dateToBrazilian (date) {
    if (!date) return null

    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
  }

  static todayToAmerican () {
    return moment().format('YYYY-MM-DD')
  }

  static todayToBrazilian () {
    return moment().format('DD/MM/YYYY')
  }

  static currentTime () {
    return moment().format('HH:mm:ss')
  }

  static nextMonthToAmerican () {
    return moment().add(1, 'M').format('YYYY-MM-DD')
  }

  static nextMonthToBrazilian () {
    return moment().add(1, 'M').format('DD/MM/YYYY')
  }

  static onlyNumbers (value) {
    if (value) {
      const anyNonDigitRegExp = /[^0-9]/g
      return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ''
  }

  static clearDigitsSpecialChars (value) {
    if (value) {
      const anyNonDigitRegExp = /[^a-zA-Z0-9]+/g
      return value.toString().replace(anyNonDigitRegExp, '')
    }

    return ''
  }

  static removeBlankSpaces (value) {
    if (value) {
      return value.replace(/\s\s+/g, ' ').trimEnd()
    }

    return ''
  }

  static formatToPlaca (value) {
    if (value) {
      return value.replace(/(\w{3})?(\w{4})/, '$1-$2')
    }

    return ''
  }

  static formatDocument (value) {
    if (!value) return ''

    if (value.length === 14) {
      return this.formatCnpj(value)
    }

    return this.formatCpf(value)
  }

  static formatCpf (value) {
    let rawValue = this.onlyNumbers(value)

    let newValue = rawValue.substr(0, 3)

    if (rawValue.length > 3) {
      newValue += '.' + rawValue.substr(3, 3)
    }

    if (rawValue.length > 6) {
      newValue += '.' + rawValue.substr(6, 3)
    }

    if (rawValue.length > 9) {
      newValue += '-' + rawValue.substr(9, 2)
    }

    return newValue
  }

  static formatCnpj (value) {
    let rawValue = this.onlyNumbers(value)

    let newValue = rawValue.substr(0, 2)

    if (rawValue.length > 2) {
      newValue += '.' + rawValue.substr(2, 3)
    }

    if (rawValue.length > 5) {
      newValue += '.' + rawValue.substr(5, 3)
    }

    if (rawValue.length > 8) {
      newValue += '/' + rawValue.substr(8, 4)
    }

    if (rawValue.length > 12) {
      newValue += '-' + rawValue.substr(12, 2)
    }

    return newValue
  }

    static validateDocument = (value) => {
      value = this.onlyNumbers(value)

      if (!value) return ''

      if (value.length === 14) {
        return this.validateCnpj(value)
      }

      return this.validateCpf(value)
    }

    static validateCnpj = (strCNPJ) => {
      if (strCNPJ === '') return false

      // Elimina CNPJs inválidos conhecidos
      if (strCNPJ.length !== 14 ||
            strCNPJ === '00000000000000' ||
            strCNPJ === '11111111111111' ||
            strCNPJ === '22222222222222' ||
            strCNPJ === '33333333333333' ||
            strCNPJ === '44444444444444' ||
            strCNPJ === '55555555555555' ||
            strCNPJ === '66666666666666' ||
            strCNPJ === '77777777777777' ||
            strCNPJ === '88888888888888' ||
            strCNPJ === '99999999999999')
        return false

      // Valida dígitos verificadores
      let tamanho = strCNPJ.length - 2
      let numeros = strCNPJ.substring(0, tamanho)
      let digitos = strCNPJ.substring(tamanho)
      let soma = 0
      let pos = tamanho - 7

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2)
          pos = 9
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

      if (resultado !== parseInt(digitos.charAt(0))) return false

      tamanho = tamanho + 1
      numeros = strCNPJ.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2)
          pos = 9
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

      if (resultado !== parseInt(digitos.charAt(1))) return false

      return true
    }

    static validateCpf = (strCPF) => {
      if (strCPF === '') return false

      // Elimina CPFs invalidos conhecidos
      if (strCPF.length !== 11 ||
            strCPF === '00000000000' ||
            strCPF === '11111111111' ||
            strCPF === '22222222222' ||
            strCPF === '33333333333' ||
            strCPF === '44444444444' ||
            strCPF === '55555555555' ||
            strCPF === '66666666666' ||
            strCPF === '77777777777' ||
            strCPF === '88888888888' ||
            strCPF === '99999999999')
        return false

      // Valida 1º digito
      let add = 0
      let rev = 0
      for (let i = 0; i < 9; i++) {
        add += parseInt(strCPF.charAt(i)) * (10 - i)
      }
      rev = 11 - (add % 11)
      if (rev === 10 || rev === 11) rev = 0
      if (rev !== parseInt(strCPF.charAt(9))) return false

      // Valida 2º digito
      add = 0
      for (let i = 0; i < 10; i++) {
        add += parseInt(strCPF.charAt(i)) * (11 - i)
      }
      rev = 11 - (add % 11)
      if (rev === 10 || rev === 11) rev = 0
      if (rev !== parseInt(strCPF.charAt(10))) return false

      return true
    }

    static ordenarPorChave = (lista, chave) => {
      lista.sort(function (a, b) {
        let nomeA = a[chave].toUpperCase() // Ignora maiusculas e min
        let nomeB = b[chave].toUpperCase() // Ignora maiusculas e min
        if (nomeA < nomeB) {
          return -1
        }
        if (nomeA > nomeB) {
          return 1
        }

        // nomes devem ser iguais
        return 0
      })
    }

    static camelCaseToRedableString = (stringCamelCase) => {
      let dictionary = {
        atpv: 'ATPV',
        cao: 'ção',
        coes: 'ções',
        cod: 'cód',
        crv: 'CRV',
        dao: 'dão',
        detran: 'DETRAN',
        deb: 'déb',
        diagnostico: 'diagnóstico',
        dpvat: 'DPVAT',
        ipva: 'IPVA',
        nao: ' não ',
        num: 'núm',
        pgfn: 'PGFN',
        pendencia: 'pendência',
        provisorio: 'provisório',
        transferencia: 'transferência',
        referencia: 'referência',
        sao: 'são',
        veiculo: 'veículo',
        uf: 'UF'
      }

      let regExp = /[A-Z]/g
      let upperCaseList = stringCamelCase.match(regExp)

      if (!upperCaseList) {
        for (const key in dictionary) {
          if (Object.hasOwnProperty.call(dictionary, key)) {
            stringCamelCase = stringCamelCase.replace(key, dictionary[key])
          }
        }
        return stringCamelCase.charAt(0).toUpperCase() + stringCamelCase.slice(1)
      }

      let string = stringCamelCase
      upperCaseList.forEach(letter => {
        string = string.replace(letter, ` ${letter.toLowerCase()}`)
      })

      for (const key in dictionary) {
        if (Object.hasOwnProperty.call(dictionary, key)) {
          string = string.replace(key, dictionary[key])
        }
      }

      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    static jsonToTreeTable = (json, numberKey = null) => {
      let nextNumberKey = 0
      let data = []
      for (const jsonKey in json) {
        if (Object.hasOwnProperty.call(json, jsonKey)) {
          let key = numberKey ? numberKey.concat('-', nextNumberKey) : nextNumberKey.toString()
          let name = isNaN(parseInt(jsonKey)) ? this.camelCaseToRedableString(jsonKey) : 'Item '.concat(parseInt(jsonKey) + 1)

          if (typeof (json[jsonKey]) === 'object' && json[jsonKey] !== null) {
            if (Array.isArray(json[jsonKey]) && json[jsonKey].length < 1) {
              data.push({
                key: key,
                data: { name: name, value: 'Sem Informação' }
              })
            } else {
              data.push({
                key: key,
                data: { name: name },
                children: this.jsonToTreeTable(json[jsonKey], key)
              })
            }
            nextNumberKey++
          } else {
            let value

            switch (json[jsonKey]) {
            case null:
              value = 'Sem Informação'
              break
            default:
              value = json[jsonKey].toString()
              break
            }

            data.push({
              key: key,
              data: {
                name: name,
                value: value
              }
            })
            nextNumberKey++
          }
        }
      }
      return data
    }

    static downloadFile = (dataType, data, filename) => {
      let element = document.createElement('a')
      element.setAttribute('href', dataType + data)
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()
    }

    static isUsuarioAdmin = () => {
      if (!getStore().usuarioLogado.email) { return false }
      return getStore().usuarioLogado.email === 'suporte@sances.com.br'
    }

    static isEmpresaDespachante = () => {
      return getStore().empresaLogada.isdesp_emp
    }

    static isUsuarioDespachante = () => {
      return getStore().usuarioLogado.isdesp_usu
    }

    static arrayEquals (primeiroArray, segundoArray) {
      return Array.isArray(primeiroArray) &&
        Array.isArray(segundoArray) &&
        primeiroArray.length === segundoArray.length &&
        primeiroArray.every((propriedade, index) => propriedade === segundoArray[index])
    }

    static getEntregaMaisRecente (entregas) {
      let entregaMaisRecente = entregas[0]

      for (const entrega of entregas) {
        const dataEntregaMaisRecente = new Date(entregaMaisRecente.realizacaoEntrega?.dataHoraRegistro || '')
        const dataEntrega = new Date(entrega.realizacaoEntrega?.dataHoraRegistro || '')

        if (dataEntrega > dataEntregaMaisRecente) {
          entregaMaisRecente = entrega
        }
      }

      return entregaMaisRecente
    }

    static calcularDiferencaEmDias (data) {
      // Obtém a data atual
      const dataAtual = new Date()
      // Remove a informação de horas, minutos, segundos e milissegundos
      const dataSemHoraAtual = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate())
      // Remove a informação de horas, minutos, segundos e milissegundos da data fornecida
      const dataSemHoraFornecida = new Date(data.getFullYear(), data.getMonth(), data.getDate())
      // Calcula a diferença em milissegundos
      const diferencaEmMilissegundos = dataSemHoraAtual.getTime() - dataSemHoraFornecida.getTime()
      // Converte a diferença para dias
      const diferencaEmDias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

      return diferencaEmDias
    }

    static copyToClipboard (text) {
      navigator.clipboard.writeText(text).then(() => {
        showSuccessMessage('Conteúdo copiado para a área de transferência!')
      }).catch(err => {
        console.error('Erro ao copiar o texto: ', err)
      })
    }
}
