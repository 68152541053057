import { server } from '../../../helpers/server'
import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class EntradaZeroKmService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`entradaZeroKm/retornaListaEntrada?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`entradaZeroKm/retornaListaEntrada?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async confirmarEntrada (listaEntradas) {
    try {
      const itensList = listaEntradas.map((entrada) => ({ codigo: entrada.codigo }))
      const response = await server().post('entradaZeroKm/confirmarEntrada', { itensList }, ComunicaRenave)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async rejeitarEntrada (data) {
    return await ServiceBase.postBase('entradaZeroKm/rejeitarEntrada', data, ComunicaRenave)
  }

  static async cancelarEntradaZeroKm (data) {
    return await ServiceBase.postBase('entradaZeroKm/cancelarEntrada', data, ComunicaRenave)
  }

  static async cancelarRejeicaoZeroKm (chassi) {
    return await ServiceBase.postBase('entradaZeroKm/cancelarRejeicao', { chassi }, ComunicaRenave)
  }

  static async selecionarNota (codigo) {
    try {
      await server(false).put('entradaZeroKm/selecionarNota', { codigo })
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async criarVeiculoMock (sufixoChassi, veiculoAcabado) {
    return await ServiceBase.postBase('entradaZeroKm/criarVeiculoMock', { sufixoChassi, veiculoAcabado }, ComunicaRenave)
  }
}
