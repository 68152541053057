import React, { Component } from 'react'
import { InputNumber } from 'primereact/inputnumber'

class NumberInput extends Component {
  render () {
    return (
      <span>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        {<InputNumber {...this.props} value={this.props.value || ''} useGrouping={this.props.useGrouping || false} />}
      </span>
    )
  }
}

export default NumberInput
