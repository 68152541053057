import { clearDigitsSpecialChars } from './parsers'
export const EMAIL_REGEX = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}'

export function isValidEmail (email) {
  const emailRegex = RegExp(EMAIL_REGEX)
  return emailRegex.test(email)
}

export function isValidPhone (phone) {
  const regex13 = RegExp('^([0-9]{10,11})$')
  return regex13.test(phone)
}

export function isValidCpf (strCPF) {
  strCPF = clearDigitsSpecialChars(strCPF)
  let Soma
  let Resto
  Soma = 0

  let invalids = ['00000000000', '11111111111', '22222222222', '33333333333',
    '44444444444', '55555555555', '66666666666', '77777777777',
    '88888888888', '99999999999']
  if (invalids.indexOf(strCPF) > -1) return false

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i), 0) * (11 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(9, 10), 0)) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i), 0) * (12 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(10, 11), 0)) return false
  return true
}

export function isValidCnpj (c) {
  letb = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  if ((c = c.replace(/[^\d]/g, '')).length !== 14)
    return false

  if (/0{14}/.test(c))
    return false

  for (leti = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (c[12] !== (((n %= 11) < 2) ? 0 : 11 - n))
    return false

  for (letj = 0, m = 0; j <= 12; m += c[j] * b[j++]);
  if (c[13] !== (((m %= 11) < 2) ? 0 : 11 - m))
    return false

  return true
};

export function isValidCep (cep) {
  cep = clearDigitsSpecialChars(cep)
  const regex = RegExp('^([0-9]{8})$')
  return regex.test(cep)
}

export function isNotEmpty (value) {
  if (!value) {
    return false
  }

  if (value.trim().length === 0) {
    return false
  }

  return true
}

export function getOperatingSystem () {
  letuserAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return null
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return null
}
