import { Button } from 'primereact/button'
import React, { Component } from 'react'
import SelectInput from '../../../components/inputs/SelectInput'
import TextInput from '../../../components/inputs/TextInput'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { showErrorMessage, showWarnMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDate, formatPlaca, formatToCurrency } from '../../../helpers/formaters'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import AptidaoUsadosService from '../../../services/veiculosUsados/AptidaoUsadosService'
import './_aptidaoUsados.scss'

class AptidaoUsados extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      tiposCrv: [],
      filter: {},
      tipoCrv: {},
      placa: null,
      numeroCrv: null,
      renavam: null,
      aptidao: null
    }
  }

  async componentDidMount () {
    await this.getTiposCRV()
  }

  async getTiposCRV () {
    try {
      const tiposCrv = await VeiculoService.getTiposCrv()
      this.setState({ tiposCrv })
      const tipoCrv = tiposCrv[1]
      this.setState({ tipoCrv })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar os tipos de CRV!')
    }
  }

  async getAptidaoVeiculo () {
    try {
      const state = this.state

      const { numeroCrv, placa, renavam, tipoCrv } = state

      if (!placa) {
        showWarnMessage('Por favor informe a placa do veículo!')
        return
      }

      if (!numeroCrv) {
        showWarnMessage('Por favor informe o número CRV do veículo!')
        return
      }

      if (!renavam) {
        showWarnMessage('Por favor informe o renavam do veículo!')
        return
      }

      const aptidao = await AptidaoUsadosService.getAptidao(numeroCrv, placa, renavam, tipoCrv.descri_tcr)

      this.setState({ aptidao })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar a aptidão do veículo')
    }
  }

  simOuNao (boolean) {
    return boolean ? 'Sim' : 'Não'
  }

  async imprimirAptidao () {
    try {
      const state = this.state
      const { aptidao } = state
      CommonHelper.renderizarHTML('aptidao-usados', aptidao)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir a aptidão do veículo!')
    }
  }

  render () {
    const state = this.state

    let veiculo, diagnostico, informacoesDebitos

    const { aptidao } = state
    if (aptidao) {
      veiculo = aptidao.veiculo
      diagnostico = aptidao.diagnostico
      informacoesDebitos = aptidao.informacoesDebitos
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Aptidão</h4>
        </div>
        <Container className="aptidao-container">
          <div className="formgrid grid">
            <div className="field col-12 md:col-3">
              <TextInput
                label="Número CRV"
                placeholder="Número do CRV"
                name="numeroCrv"
                mask="999999999999"
                value={state.numeroCrv}
                onChange={(e) => this.setState({ numeroCrv: e.target.value })}
                className="inputfield w-full"
              />
            </div>
            <div className="field col-12 md:col-3">
              <TextInput
                label="Placa"
                placeholder="Placa do veículo"
                name="placa"
                mask="aaa-9*99"
                value={state.placa}
                onChange={(e) => this.setState({ placa: e.target.value })}
                className="inputfield w-full"
              />
            </div>
            <div className="field col-12 md:col-3">
              <TextInput
                label="Renavam"
                placeholder="Renavam do veículo"
                name="renavam"
                mask="99999999999"
                value={state.renavam}
                onChange={(e) => this.setState({ renavam: e.target.value })}
                className="inputfield w-full"
              />
            </div>
            <div className="field col-12 md:col-3">
              <SelectInput
                value={state.tipoCrv}
                label="Tipo CRV"
                name="tipoCrv"
                className="inputfield w-full"
                placeholder="- Selecione -"
                optionLabel="descri_tcr"
                dataKey="codigo_tcr"
                options={state.tiposCrv}
                onChange={(e) => this.setState({ tipoCrv: e.target.value })}
                noFloatLabel
              />
            </div>
            <div className="field col-12 flex justify-content-end">
              { aptidao &&
              <Button
                className="mr-2"
                label="Imprimir aptidão"
                icon="pi pi-print"
                onClick={async () => await this.imprimirAptidao()}
              />
              }
              <Button
                className="mr-2"
                label="Consultar"
                icon="pi pi-search"
                onClick={async () => await this.getAptidaoVeiculo()}
              />
            </div>
            <div className="form-divider"></div>
            {aptidao && (
              <div className="field col-12">
                <div>
                  <h1>Informações do veículo</h1>
                  <div className="mt-2">
                    <label>Chassi: </label><span>{veiculo?.chassi}</span>
                  </div>
                  <div className="mt-1">
                    <label>Placa: </label><span>{formatPlaca(veiculo?.placa)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Renavam: </label><span>{veiculo?.renavam}</span>
                  </div>
                  <div className="mt-1">
                    <label>Estado: </label><span>{veiculo?.ufJurisdicao}</span>
                  </div>
                  <h1 className="mt-2">Diagnóstico</h1>
                  <div className="mt-2">
                    <label>Apto?: </label><span>{this.simOuNao(diagnostico.veiculoApto)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Restrição impeditiva no DETRAN?: </label><span>{this.simOuNao(diagnostico.veiculoComRestricaoImpeditivaNoDetran)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Tem alarme?: </label><span>{this.simOuNao(diagnostico.veiculoComAlarme)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Veículo baixado?: </label><span>{this.simOuNao(diagnostico.veiculoBaixado)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Existe roubo ou furto?: </label><span>{this.simOuNao(diagnostico.veiculoComRouboFurto)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Pendência de emissão de CRV?: </label><span>{this.simOuNao(diagnostico.veiculoComPendenciaDeEmissaoCrv)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Restrição judicial?: </label><span>{this.simOuNao(diagnostico.veiculoComRestricaoJudicial)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Restrição na receita federal?: </label><span>{this.simOuNao(diagnostico.veiculoComRestricaoNaReceitaFederal)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Restrição PGFN?: </label><span>{this.simOuNao(diagnostico.veiculoComRestricaoPgfn)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Veículo com recall?: </label><span>{this.simOuNao(diagnostico.veiculoComRecall)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Tem autorização de transferência para estabelecimento diferente do estabelecimento consultante?: </label>
                    <span>
                      {this.simOuNao(diagnostico.veiculoTemAutorizacaoTransferenciaParaEstabelecimentoDiferenteDoEstabelecimentoConsultante)}
                    </span>
                  </div>
                  <div className="mt-1">
                    <label>Tem comunicação de venda para comprador diferente do estabelecimento consultante?: </label>
                    <span>
                      {this.simOuNao(diagnostico.veiculoTemComunicacaoDeVendaParaCompradorDiferenteDoEstabelecimentoConsultante)}
                    </span>
                  </div>
                  <div className="mt-1">
                    <label>Licenciamento provisório?: </label><span>{this.simOuNao(diagnostico.veiculoComLicenciamentoProvisorio)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Veículo sem ATPV-e?: </label><span>{this.simOuNao(diagnostico.veiculoSemAtpv)}</span>
                  </div>
                  <div className="mt-1">
                    <label>Tem intenção de venda para comprador diferente do estabelecimento consultante?: </label>
                    <span>
                      {this.simOuNao(diagnostico.veiculoTemIntencaoDeVendaParaCompradorDiferenteDoEstabelecimentoConsultante)}
                    </span>
                  </div>
                  {diagnostico.motivosParaNaoAptidao?.length > 0 && (
                    <>
                      <h1 className="mt-2">Motivos para não aptidão</h1>
                      {diagnostico.motivosParaNaoAptidao.map((motivoParaNaoAptidao, idx) => {
                        return (
                          <div className={idx === 0 ? 'mt-2' : 'mt-1'} key={idx}>
                            <span>{motivoParaNaoAptidao}</span>
                          </div>
                        )
                      })}
                    </>
                  )}
                  {informacoesDebitos && (
                    <>
                      <h1 className="mt-2 mb-1">Informações dos débitos</h1>
                      <div className="mt-1">
                        <label>Códigos dos bancos: </label><span>{informacoesDebitos.codigosBancos.join(', ')}</span>
                      </div>
                      <div className="mt-1">
                        <label>Valor débito DPVAT: </label><span>{formatToCurrency(informacoesDebitos.valorDebitoDpvat)}</span>
                      </div>
                      <div className="mt-1">
                        <label>Valor débito IPVA: </label><span>{formatToCurrency(informacoesDebitos.valorDebitoIpva)}</span>
                      </div>
                      <div className="mt-1">
                        <label>Valor débito licenciamento: </label><span>{formatToCurrency(informacoesDebitos.valorDebitoLicenciamento)}</span>
                      </div>
                      <div className="mt-1">
                        <label>Valor débito multas: </label><span>{formatToCurrency(informacoesDebitos.valorDebitoMultas)}</span>
                      </div>
                      <div className="mt-1 mb-2">
                        <label>Valor taxas e débitos diversos: </label><span>{formatToCurrency(informacoesDebitos.valorTaxasEDebitosDiversos)}</span>
                      </div>
                      {!informacoesDebitos.boletos.map((boleto, idx) => {
                        return (
                          <div key={idx}>
                            <h2>Boleto</h2>
                            <div className="mt-1">
                              <label>Data vencimento: </label><span>{formatDate(boleto.dataVencimentoBoleto)}</span>
                            </div>
                            <div className="mt-1">
                              <label>Código de barras: </label><span>{boleto.numeroCodigoBarras}</span>
                            </div>
                            <div className="mt-1">
                              <label>Valor: </label><span>{formatToCurrency(boleto.valorBoleto)}</span>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </Page>
    )
  }
}

export default AptidaoUsados
