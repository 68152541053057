import React, { useEffect, useState } from 'react'
import PanelContainer from '../../../../components/layout/PanelContainer'
import EstoqueRenaveService from '../../../../services/suporte/EstoqueRenaveService'
import SolicitacaoTransferenciaFilial from './SolicitacaoTransferenciaFilial'

function TransferenciaUsadoFilial (props) {
  const [veiculo, setVeiculo] = useState(null)

  useEffect(async () => {
    const placaVeiculoSelecionado = props.match.params.placa

    setVeiculo(await EstoqueRenaveService.getByPlaca(placaVeiculoSelecionado))
  }, [])

  return (
    <>
      <h4 className="mt-3 mb-0">Transferência entre filiais</h4>
      <PanelContainer toggeable={false} header="SOLICITAÇÃO DE TRANSFERÊNCIA">
        <SolicitacaoTransferenciaFilial
          veiculo={veiculo}
        />
      </PanelContainer>
    </>
  )
}

export default TransferenciaUsadoFilial
