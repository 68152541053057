import React from 'react'
import './_dropdownButton.scss'
import { Divider } from 'primereact/divider'

const DropdownItem = (props) => {
  return (
    <>
      {!props.first && (
        <Divider/>
      )}
      <div onClick={() => props.onClick()} className="dropdown-item" id="dropdown">
        <a className="menu-item" id="dropdown">
          {props.label}
        </a>
      </div>
    </>
  )
}

export default DropdownItem
