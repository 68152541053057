import { postEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import { server } from '../../helpers/server'
import ServiceBase from '../base/ServiceBase'

export default class EmpresaService {
  async get (id) {
    return await ServiceBase.getBase(`empresa/${id}`)
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`empresas?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`empresas?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('empresa', postEmpresaDTO(data))
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`empresa/${id}`)
  }

  static async empresasAcessoByEmpresaLogada () {
    return await ServiceBase.getBase('empresa/empresasAcesso/empresaLogada')
  }

  async getEmpresasAcesso (data) {
    return await ServiceBase.postBase('empresasAcesso', data)
  }

  static async getEmpresaLogada () {
    const res = await server().get('empresa/get/logada')
    return res.data
  }

  async uploadCertificate (data) {
    return await ServiceBase.postBase('/certificado/salvarCertificado', data)
  }

  static async verificaCertificadoComSerpro (tipoValidamento) {
    return await ServiceBase.getBase(`certificado/verificaCertificadoSerpro/${tipoValidamento}`)
  }

  static async trocarEmpresa (codigo_emp) {
    return (await ServiceBase.postBase(`empresa/trocarEmpresa/${codigo_emp}`)).credentials
  }

  static async updateConfiguracaoModulos (configuracaoModulos) {
    return await ServiceBase.postBase('/empresa/updateConfiguracaoModulos', { configuracaoModulos })
  }

  static async getEmpresasByEmail (email) {
    return await ServiceBase.getBase(`empresasByEmail/${email}`)
  }

  static async getEmpresasByCnpj (cnpjEmp) {
    return await ServiceBase.getBase(`getEmpresasByCnpj/${cnpjEmp}`)
  }
}
