import { applyMiddleware } from 'redux'
import { createStore } from 'redux'
import { server } from '../helpers/server'
import reducer from './reducer'
import axiosMiddleware from 'redux-axios-middleware'

export const store = createStore(reducer, applyMiddleware(axiosMiddleware(server())))

export const dispatch = (action) => {
  store.dispatch(action)
}

export const getStore = () => {
  return store.getState()
}
