import { SET_CERTIFICADO_VALIDO_ITE, SET_CERTIFICADO_VALIDO_MONTADORA, SET_CERTIFICADO_VALIDO_VENDA_VEICULOS, SET_EMPRESA_LOGADA, SET_MENSAGEM_GLOBAL, SET_USUARIO_LOGADO, START_LOADING, START_RENAVE, STOP_LOADING, STOP_RENAVE } from './types'

export const setUsuarioLogado = (payload) => {
  return { type: SET_USUARIO_LOGADO, payload }
}

export const setEmpresaLogada = (payload) => {
  return { type: SET_EMPRESA_LOGADA, payload }
}

export const setMensagemGlobal = (payload) => {
  return { type: SET_MENSAGEM_GLOBAL, payload }
}

export const setCertificadoValidoVendaVeiculos = (payload) => {
  return { type: SET_CERTIFICADO_VALIDO_VENDA_VEICULOS, payload }
}

export const setCertificadoValidoIte = (payload) => {
  return { type: SET_CERTIFICADO_VALIDO_ITE, payload }
}

export const setCertificadoValidoMontadora = (payload) => {
  return { type: SET_CERTIFICADO_VALIDO_MONTADORA, payload }
}

export const startLoading = () => {
  return { type: START_LOADING }
}

export const stopLoading = () => {
  return { type: STOP_LOADING }
}

export const startRenave = () => {
  return { type: START_RENAVE }
}

export const stopRenave = () => {
  return { type: STOP_RENAVE }
}
