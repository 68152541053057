import React, { useEffect } from 'react'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { getVeiculoDTO } from '../../../dtos/cadastro/VeiculoDTO'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import DadosPrincipaisVei from './DadosPrincipaisVei'
import { VeiculoValidator } from './VeiculoValidator'

function VeiculoModal ({ hide, visible, onRegister, primaryKey, isVeiculoNovo, edicaoHabilitada }) {
  async function handleSubmit () {
    try {
      const errors = await form.validateForm(form.values)

      if (Object.keys(errors).length === 0) {
        const veiculo = await VeiculoService.save(form.values)
        if (onRegister) {
          await onRegister(veiculo)
        }
        hide()
      }
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  const form = baseForm({
    initialValues: getVeiculoDTO(),
    onSubmit: handleSubmit,
    validationSchema: VeiculoValidator
  })

  useEffect(async () => {
    if (primaryKey) {
      const veiculoService = new VeiculoService()
      try {
        const veiculo = await veiculoService.get(primaryKey)
        form.setValues(getVeiculoDTO(veiculo))
      } catch (e) {
        showErrorMessage(e.message || 'Erro ao buscar dados do veículo')
      }
    } else {
      form.setValues(getVeiculoDTO())
    }
  }, [visible, primaryKey])

  return (
    <Modal
      onHide={hide}
      header={primaryKey ? 'Veículo - ' + primaryKey : 'Cadastro de veículo'}
      visible={visible}
      width="70"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      modal
    >
      <form onSubmit={form.handleSubmit}>
        <DadosPrincipaisVei edicaoHabilitada={edicaoHabilitada} form={form} isVeiculoNovo={isVeiculoNovo} />
      </form>
    </Modal>
  )
}

export default VeiculoModal
