import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox'

class CheckBoxInput extends Component {
  render () {
    return (
      <span className={`checkbox-container ${this.props.className}`}>
        <Checkbox {...this.props} />
        <label className="p-checkbox-label pl-2" htmlFor={this.props.name}>{this.props.label}</label>
      </span>
    )
  }
}

export default CheckBoxInput
