import ServiceBase from '../base/ServiceBase'

export default class CreditosDetranService {
  static async adicionarCreditos (valorCredito) {
    await ServiceBase.postBase(`creditos/adicionar/${valorCredito}`)
  }

  static async gerarPixCobranca (valorCredito) {
    return await ServiceBase.postBase('financeiro/gerarPIX', { valor: valorCredito, solicitacao: 'Recarga de saldo para taxa do DETRAN' })
  }
}
