import { Checkbox } from 'primereact/checkbox'
import React, { useEffect } from 'react'
import SelectInput from '../../components/inputs/SelectInput'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import FieldErrorMessage from '../../components/utils/FieldErrorMessage'

const DadosPrincipaisMen = ({ form }) => {
  const tiposMensagem = [
    {
      tipoMen: 'A',
      label: 'Alerta'
    },
    {
      tipoMen: 'I',
      label: 'Informação'
    }
  ]

  const handleChangeTipoMensagem = (e) => {
    const tipoMensagem = e.target.value
    // console.log(tipoMensagem)
    form.setFieldValue('tipoMen', tipoMensagem.tipoMen)
    form.setFieldValue('tipoMensagem', tipoMensagem)
  }

  useEffect(() => {
    const tipoMensagem = tiposMensagem.filter((tipo) => tipo.tipoMen === form.values.tipoMen).shift()
    form.setFieldValue('tipoMensagem', tipoMensagem)
  }, [])

  return (
    <div className="formgrid grid">
      <div className="field col-12">
        <TextAreaInput
          label="Conteúdo da mensagem"
          name="mensagemMen"
          value={form.values.mensagemMen}
          onChange={form.handleChange}
          className="inputfield w-full"
        />
        <FieldErrorMessage message={'Caso você queira colocar um link utilize esse formato: "%\\url textoQueOUsuarioVaiClicar%"'} />
      </div>
      <div className="field col-4">
        <SelectInput
          value={form.values.tipoMensagem}
          label="Tipo da mensagem"
          className="inputfield w-full"
          placeholder="- Selecione -"
          optionLabel="label"
          dataKey="tipoMen"
          options={tiposMensagem}
          onChange={handleChangeTipoMensagem}
          noFloatLabel
        />
      </div>
      <div className="field checkbox-container col-8">
        <Checkbox
          name="ativoMen"
          className="mt-2"
          value={form.values.ativoMen}
          onChange={form.handleChange}
          checked={form.values.ativoMen}
        />
        <label className="p-checkbox-label mb-0 pl-2 mt-2">Ativa</label>
      </div>
    </div>
  )
}

export default DadosPrincipaisMen
