import { server } from '../../helpers/server'

export default class MunicipioService {
  static async getTodas () {
    const res = await server().get('municipio/todos')
    return res.data
  }

  static async getPorId (cidadeCodigo) {
    try {
      const res = await server(false).get(`municipio/${cidadeCodigo}`)
      return res.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async getPorEstado (estadoSigla) {
    try {
      const res = await server(false).get(`municipio/byEstado/${estadoSigla}`)
      return res.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }
}
