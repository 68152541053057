import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class TransferenciaMontadoraService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`montadora/autorizacoes-transferencia-vindo-ITE?page=${page}&perPage=${perPage}&montadora=true`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`montadora/autorizacoes-transferencia-vindo-ITE?${query}&page=${page}&perPage=${perPage}&montadora=true`, ComunicaRenave)
  }

  static async autorizarTransferenciaITE (cnpjIteDestino, idEstoqueOrigem, chaveNotaFiscalProdutoSaida, tipoBeneficioTributario, leasing) {
    return await ServiceBase.postBase('montadora/autorizar-transferir-para-ITE', {
      cnpjIteDestino,
      idEstoqueOrigem,
      chaveNotaFiscalProdutoSaida,
      tipoBeneficioTributario,
      leasing
    }, ComunicaRenave)
  }

  static async cancelarAutorizacaoTransferenciaITE (idAutorizacaoITE, chassi) {
    return await ServiceBase.postBase('montadora/cancelar-autorizacao-para-ITE', { idAutorizacaoITE, chassi }, ComunicaRenave)
  }

  static async receberTransferenciaVindoITE (chaveRemessa, chaveProduto, chaveServico, idAutorizacao, valorProduto, dataTransferencia) {
    const receberTransferenciaVindoITERequest = {
      chaveRemessa,
      chaveProduto,
      chaveServico,
      idAutorizacao,
      valorProduto,
      dataTransferencia
    }

    return await ServiceBase.postBase('montadora/receber-veiculo-vindo-ITE', receberTransferenciaVindoITERequest, ComunicaRenave)
  }
}
