import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class ImpressoesMontadoraService {
  static async getTermoSaida (idEstoque) {
    return await ServiceBase.getBase(`montadora/get-termo-saida/${idEstoque}`, ComunicaRenave)
  }

  static async getATPVE (chassi) {
    return await ServiceBase.getBase(`montadora/get-atpve/${chassi}`, ComunicaRenave)
  }
}
