export const getVeiculoEntradaDTO = (data = {}) => {
  return {
    chassi: data.chassi_vei || null,
    marcaModelo: data.descri_vei || null,
    anoFab: data.anofab_vei || null,
    anoMod: data.anomod_vei || null,
    corVei: data.cor_Vei || null,
    dataEntrada: data.datent_vei ? new Date(data.datent_vei) : null
  }
}
