import ServiceBase from '../base/ServiceBase'

export default class LoteAtpvService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`atpv/getListaLoteAtpv?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`atpv/getListaLoteAtpv?${query}&page=${page}&perPage=${perPage}`)
  }

  static async processarLoteAtpvNovo (listaChassis, isMontadora) {
    return await ServiceBase.postBase('atpv/processarLoteAtpvNovo', { listaChassis, isMontadora })
  }

  static async getQuantidadeLotesAtpvNovo () {
    return await ServiceBase.getBase('atpv/getQuantidadeLotesAtpvNovo')
  }

  static async downloadLoteApve (arquivo) {
    return await ServiceBase.postBase('atpv/downloadLoteAtpv', { arquivo })
  }
}
