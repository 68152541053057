import Modal from '../../../components/utils/Modal'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../components/inputs/SelectInput'
import SearchInput from '../../../components/inputs/SearchInput'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import PessoaService from '../../../services/cadastro/PessoaService'
import { formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../helpers/formaters'
import PessoaModal from '../../../forms/cadastro/pessoa/PessoaModal'
import IteService from '../../../services/ITE/IteService'
import TextInput from '../../../components/inputs/TextInput'
import { Button } from 'primereact/button'
import FiltroModal from '../../../components/utils/FiltroModal'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import { getVeiculoDTO } from '../../../dtos/cadastro/VeiculoDTO'
import { FileUpload } from 'primereact/fileupload'
import XmlConverter from 'x2js'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'

function EntradaEstoqueIteModal ({ visible, onHide, onFilter }) {
  const [cliente, setCliente] = useState({})
  const [sugestaoClientes, setSugestaoClientes] = useState([])
  const [clientesMontadoras, setClientesMontadoras] = useState(null)
  const [clienteMontadora, setClienteMontadora] = useState({})
  const [chassi, setChassi] = useState('')
  const [valor, setValor] = useState(0)
  const [chaveRemessa, setChaveRemessa] = useState('')

  const [visibleVeiculoFiltroModal, setVisibleVeiculoFiltroModal] = useState(false)

  function hideModal () {
    setCliente({})
    setSugestaoClientes([])
    setClientesMontadoras(null)
    setClienteMontadora({})
    setChassi('')
    setValor(0)
    setChaveRemessa('')
    onHide()
  }

  useEffect(async () => {
    setClienteMontadora({})
    setClientesMontadoras(null)

    if (chassi.length === 17) {
      setClientesMontadoras(await IteService.consultaClientesMontadoras(chassi))
    }
  }, [chassi])

  const sugerirClientes = async () => {
    try {
      const pessoaService = new PessoaService()

      const clientes = (await pessoaService.filter(`descricaoFiltro=${cliente.nomraz_pes}`)).data

      if (clientes.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoClientes(clientes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  function handleChangeCliente (event) {
    setCliente({ nomraz_pes: event.target.value })
  }

  function handleOnRegisterCliente (clienteRegistrado) {
    setCliente(clienteRegistrado)
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValor(formatCurrencyToDecimal(novoValor))
  }

  async function handleUploadChaveXml (event) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', (event) => readFile(event))

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    setChaveRemessa(chaveNFe)
  }

  async function confirmarEntrada () {
    try {
      if (!chassi || chassi.length !== 17) {
        showErrorMessage('Por favor informe um chassi válido!')
        return
      }

      if (!clienteMontadora?.codigoClientemontadora) {
        showErrorMessage('Por favor informe um cliente/montadora válido!')
        return
      }

      if (!cliente?.codigo_pes) {
        showErrorMessage('Por favor informe um cliente válido!')
        return
      }

      if (valor <= 0) {
        showErrorMessage('Por favor informe um valor válido!')
        return
      }

      await IteService.confirmarEntrada(chassi, cliente, valor, clienteMontadora.codigoClientemontadora, chaveRemessa)

      await onFilter()
      hideModal()
      showSuccessMessage('Veículo confirmado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar confirmar a entrada do veículo!')
    }
  }

  return (
    <Modal
      header="Entrada de veículo em ITE"
      visible={visible}
      onHide={() => hideModal()}
      width={60}
      footer={
        <Button
          label="Confirmar entrada"
          icon="pi pi-check"
          onClick={async () => await confirmarEntrada()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-6">
          <label className="label required">Chassi</label>
          <div className="p-inputgroup">
            <TextInput
              value={chassi}
              onChange={(e) => setChassi(e.target.value)}
              className="inputfield w-full"
              placeholder="Chassi do veículo"
            />
            <Button
              type="button"
              icon="pi pi-search"
              onClick={() => setVisibleVeiculoFiltroModal(true)}
            />
          </div>
          <FiltroModal
            title="Pesquisa de veículos"
            visible={visibleVeiculoFiltroModal}
            hide={() => setVisibleVeiculoFiltroModal(false)}
            service={VeiculoService}
            model={getVeiculoDTO}
            primaryKeyName="codigo_vei"
            columns={[
              { campo: 'chassi_vei', nome: 'Chassi', width: '30%' },
              { campo: 'descri_vei', nome: 'Descrição', width: '70%' }
            ]}
            onSelect={(e) => setChassi(e.value.chassi_vei)}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="label required">Cliente/Montadora</label>
          <SelectInput
            value={clienteMontadora}
            className="inputfield w-full"
            placeholder="- Selecione -"
            optionLabel="descricaoClientemontadora"
            dataKey="codigoClientemontadora"
            options={clientesMontadoras}
            onChange={(e) => setClienteMontadora(e.target.value)}
            disabled={!Boolean(clientesMontadoras)}
            noFloatLabel
          />
        </div>
        <div className="field col-6">
          <SearchInput
            //AutoComplete
            label="Cliente"
            placeholder="Cliente da ITE"
            field="nomraz_pes"
            value={cliente?.nomraz_pes && cliente}
            suggestions={sugestaoClientes}
            completeMethod={sugerirClientes}
            onChange={handleChangeCliente}
            onSelect={(e) => setCliente(e.value)}
            className="inputfield w-full"
            //RegisterModal
            edicaoHabilitada={true}
            ComponenteCadastro={PessoaModal}
            onRegister={handleOnRegisterCliente}
            primaryKeyName="codigo_pes"
            selected={cliente}
            //FiltroModal
            filtroTitle="Pesquisa de pessoas"
            service={PessoaService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="label required">Valor</label>
          <TextInput
            placeholder="Valor do veículo"
            className="inputfield w-full"
            onChange={(e) => handleChangeValor(e)}
            value={formatToCurrency(valor)}
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Retorno de remessa - Opcional)"
            className="inputfield w-full"
            value={chaveRemessa}
            onChange={(e) => setChaveRemessa(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota de remessa"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EntradaEstoqueIteModal
