import React, { Component } from 'react'
import EmpresaService from '../../services/cadastro/EmpresaService'
import { Button } from 'primereact/button'
import TrocarEmpresaModal from '../../views/login/TrocarEmpresaModal'
import UsuarioService from '../../services/cadastro/UsuarioService'
import { setCredentials } from '../../helpers/auth'
import { ProgressSpinner } from 'primereact/progressspinner'
import { showErrorMessage } from '../utils/Message'
import { dispatch, getStore } from '../../redux/store'
import { setEmpresaLogada } from '../../redux/actions'
import ConfigService from '../../services/config/ConfigService'
import CommonHelper from '../../helpers/CommonHelper'

export class AppProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      empresa: null,
      visibleTrocarEmpresaModal: false,
      selectedCompany: {},
      empresaLogada: {}
    }
  }

  getEmpresasAcesso = async () => {
    try {
      const empresasAcessoUsuario = await UsuarioService.empresasAcessoUsuarioLogado()
      const companies = empresasAcessoUsuario.filter(company => company.codigo_emp !== getStore().empresaLogada.codigo_emp)
      this.setState({ companies })
    } catch (e) {
      console.error(e)
    }
  }

  componentDidMount () {
    this.getEmpresasAcesso()
  }

  async handleTrocarEmpresa () {
    try {
      if (this.state.selectedCompany.codigo_emp) {
        await ConfigService.createConfigEmpAndConfigGru(this.state.selectedCompany.codigo_emp)
        const credentials = await EmpresaService.trocarEmpresa(this.state.selectedCompany.codigo_emp)
        setCredentials(credentials)
        this.setState({ visibleTrocarEmpresaModal: false })
        dispatch(setEmpresaLogada({}))
        this.props.history.push('/home')
        window.location.reload()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao tentar trocar de empresa!')
    }
  }

  render () {
    const empresaLogada = getStore().empresaLogada

    return (
      <>
        {!empresaLogada && (
          <div className="flex justify-content-center">
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
          </div>
        )}
        {empresaLogada && (
          <div className="layout-profile">
            <div className="layout-profile-link">
              <div className="p-grid">
                <div className="p-col-12 company mb-3">{empresaLogada.nomfan_emp} {empresaLogada.ativo_emp ? '' : '(INATIVA)'}</div>
                <div className="p-col-12">{empresaLogada.end_emp + (empresaLogada.numend_emp ? (`, ${empresaLogada.numend_emp}`) : '')}</div>
                <div className="p-col-12">{empresaLogada.descri_mun + `, ${empresaLogada.descri_uf}-${empresaLogada.sigla_uf}`}</div>
              </div>
              <div>
                <Button
                  className="p-button-secondary mt-3"
                  label={CommonHelper.isEmpresaDespachante() ? 'Acessar lojista' : 'Trocar empresa'}
                  icon="pi pi-sort-alt"
                  onClick={() => this.setState({ visibleTrocarEmpresaModal: true })}
                />
              </div>
            </div>
            <TrocarEmpresaModal
              visible={this.state.visibleTrocarEmpresaModal}
              handleSelectCompany={(company) => this.setState({ selectedCompany: company })}
              onHide={() => this.setState({ visibleTrocarEmpresaModal: false })}
              selectedCompany={this.state.selectedCompany}
              onConfirm={() => this.handleTrocarEmpresa()}
            />
          </div>
        )}
      </>
    )
  }
}
