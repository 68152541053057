import ServiceBase from '../base/ServiceBase'

export default class EmpresasAndPessoasJuridicasService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`empresasAndPessoasJuridicas?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`empresasAndPessoasJuridicas?${query}&page=${page}&perPage=${perPage}`)
  }
}
