export const getVeiculoSaidaDTO = (data = {}) => {
  return {
    chassi: data.chassi_vei || null,
    marcaModelo: data.descri_vei || null,
    anoFab: data.anofab_vei || null,
    anoMod: data.anomod_vei || null,
    corVei: data.cor_Vei || null,
    nomeCliente: data.nomraz_pes || null,
    dataSaida: data.datsai_vei ? new Date(data.datsai_vei) : null
  }
}
