import CryptoJS from 'crypto-js'

const passphrase = 'U5egpWOMiy'

export default class AES {
  static encryptWithAES = (text) => {
    return CryptoJS.AES.encrypt(text, passphrase).toString()
  };

  static decryptWithAES = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase)
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
  };
}
