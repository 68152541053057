import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class TermoDeSaidaService {
  static async getTermoDeSaidaVeiculoInacabado (idEstoque) {
    return await ServiceBase.postBase('termoSaida/download', { idEstoque }, ComunicaRenave)
  }

  static async enviarTermoDeSaidaPorEmail (chassi, idEstoque, isMontadora) {
    return await ServiceBase.postBase('termoSaida/enviarPorEmail', { chassi, idEstoque, isMontadora }, ComunicaRenave)
  }
}
