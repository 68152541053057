import { showErrorMessage } from '../../components/utils/Message'
import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class IteService {
  static async consultaClientesMontadoras (chassi) {
    return await ServiceBase.getBase(`ite/consulta-clientes-montadoras?chassi=${chassi}`, ComunicaRenave)
  }

  static async autorizarTransferencia (cnpjEstabelecimento, idEstoqueOrigem, valorProduto, paraCancelamentoDeTransferencia) {
    return await ServiceBase.postBase('ite/autorizar-transferencia', {
      cnpjEstabelecimento,
      idEstoqueOrigem,
      valorProduto,
      paraCancelamentoDeTransferencia
    }, ComunicaRenave)
  }

  static async cancelarAutorizacaoTransferencia (idAutorizacaoEstabelecimento, chassi) {
    return await ServiceBase.postBase('ite/cancelar-autorizacao-transferencia', { idAutorizacaoEstabelecimento, chassi }, ComunicaRenave)
  }

  static async confirmarEntrada (chassi, cliente, valor, codigoClienteMontadora, chaveRemessa) {
    const renaveRequest = {
      chassi,
      cliente,
      valor,
      codigoClienteMontadora,
      chaveRemessa
    }

    return await ServiceBase.postBase('ite/confirmar-entrada', renaveRequest, ComunicaRenave)
  }

  static async confirmarSaida (veiculo, comprador, valor, dataVenda, chaveProduto, chaveServico) {
    const renaveRequest = {
      veiculo,
      comprador,
      valor,
      dataVenda,
      chaveProduto,
      chaveServico
    }

    return await ServiceBase.postBase('ite/confirmar-saida', renaveRequest, ComunicaRenave)
  }

  static async cancelarSaida (idEstoque) {
    return await ServiceBase.postBase(`ite/cancelar-saida/${idEstoque}`, ComunicaRenave)
  }

  static async cancelarEntrada (idEstoque) {
    return await ServiceBase.postBase(`ite/cancelar-entrada/${idEstoque}`, ComunicaRenave)
  }

  static async getTermoEntrada (idEstoque) {
    return await ServiceBase.getBase(`ite/get-termo-entrada/${idEstoque}`, ComunicaRenave)
  }

  static async getATPVE (chassi) {
    return await ServiceBase.getBase(`ite/get-atpve/${chassi}`, ComunicaRenave)
  }

  static async acabarVeiculo (chassi) {
    try {
      return await ServiceBase.getBase(`ite/acabar/${chassi}`, ComunicaRenave)
    } catch (error) {
      showErrorMessage(error.message)
    }
  }
}
