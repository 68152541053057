import { Button } from 'primereact/button'
import React from 'react'
import Modal from '../../../components/utils/Modal'

const ConfirmacaoOptionsModal = ({ visible, onHide, openTrocarNotaModal, openConfirmacaoErroModal }) => {
  return (
    <Modal
      header="Informações sobre o veículo."
      visible={visible}
      onHide={onHide}
      width={45}
      footer={
        <div className="field col-12 flex justify-content-center">
          <Button
            className="mr-1"
            label="Trocar nota"
            onClick={() => {
              onHide()
              openTrocarNotaModal()
            }}
          />
          <Button
            className="ml-1"
            label="Ver erro"
            onClick={() => {
              onHide()
              openConfirmacaoErroModal()
            }}
          />
        </div>
      }
    >
      <div className="field col-12 flex justify-content-center">
        <span>Ocorreu um erro ao tentar confirmar e existe mais de uma nota para esse veículo.</span>
      </div>
    </Modal>
  )
}

export default ConfirmacaoOptionsModal
