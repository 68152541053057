import { postVeiculoDTO } from '../../dtos/cadastro/VeiculoDTO'
import ServiceBase from '../base/ServiceBase'

export default class VeiculoService {
  async get (id) {
    return await ServiceBase.getBase(`veiculo/${id}`)
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`veiculos?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`veiculos?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data, loading = true) {
    return await ServiceBase.postBase('veiculo', postVeiculoDTO(data), null, loading)
  }

  async delete (codigo_vei) {
    return await ServiceBase.deleteBase(`veiculo/${codigo_vei}`)
  }

  static async getTiposCrv () {
    try {
      return await ServiceBase.getBase('veiculo/tiposcrv/todos')
    } catch (error) {}
  }
}
