import React from 'react'
import { Crud } from '../../../classes/Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import PessoaForm from '../../../forms/cadastro/pessoa/PessoaForm'
import PessoaService from '../../../services/cadastro/PessoaService'

export class Pessoa extends Crud {
  constructor (props) {
    super(props, getPessoaDTO, PessoaService, 'pessoas')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) &&
            <PessoaForm
              model={model}
              isNew={isNew}
              onDelete={this.onDelete}
            />
          }
        </Container>
      </Page>
    )
  }
}
