import axios from 'axios'
import { startLoading, stopLoading } from '../redux/actions'
import { dispatch } from '../redux/store'

const URL = 'https://viacep.com.br/ws/'

export function viacep () {
  let connection = axios.create({
    baseURL: URL,
    responseType: 'json'
  })

  connection.interceptors.request.use(function (config) {
    // Do something before request is sent
    dispatch(startLoading())
    return config
  }, function (error) {
    // Do something with request error
    dispatch(startLoading())
    return Promise.reject(error)
  })

  connection.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    dispatch(stopLoading())
    return response
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    dispatch(stopLoading())
    return Promise.reject(error)
  })

  return connection
}
