import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class SolicitacaoEntradaService {
  static async solicitacaoEntradaEstoque (dataCompra, valorCompra, veiculo, proprietario) {
    return await ServiceBase.postBase('solicitacao-entrada-estoque/', { dataCompra, valorCompra, veiculo, proprietario }, ComunicaRenave)
  }

  static async solicitacaoEntradaEstoqueVeiculoProprio (dataEntradaEstoque, veiculo) {
    return await ServiceBase.postBase('solicitacao-entrada-estoque/veiculo-proprio', { dataEntradaEstoque, veiculo }, ComunicaRenave)
  }

  static async cancelarEntrada (veiculo) {
    return await ServiceBase.postBase('solicitacao-entrada-estoque/cancelamento', { veiculo }, ComunicaRenave)
  }
}
