import { server } from '../../helpers/server'

export default class EstadosService {
  static async getTodos () {
    const res = await server(true).get('estado/todos')
    return res.data
  }

  static async getPorId (estadoId) {
    try {
      const res = await server(false).get(`estado/${estadoId}`)
      return res.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async getPorSigla (estadoSigla) {
    try {
      const res = await server(false).get(`estado/sigla/${estadoSigla}`)
      return res.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }
}
