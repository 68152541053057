export const getLoteArquivoAtpvDTO = (data = {}) => {
  return {
    chassi: data.chassi || null,
    arquivo: data.arquivo || null,
    pronto: data.pronto || null,
    uuid: data.uuid || null,
    nome_usu: data.nome_usu || null,
    datahorageracao: data.datahorageracao ? new Date(data.datahorageracao) : null
  }
}
