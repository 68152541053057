import React from 'react'
import { Crud } from '../../../classes/Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getLoteXmlDTO } from '../../../dtos/xml/LoteXmlDTO'
import LoteXmlService from '../../../services/zeroKm/xml/LoteXmlService'
import XmlImportList from './XmlImportList'

export default class LoteXml extends Crud {
  constructor (props) {
    super(props, getLoteXmlDTO, LoteXmlService, 'importar-xmls')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <XmlImportList
              loteXml={model}
            />
          )}
        </Container>
      </Page>
    )
  }
}
