import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class TransferenciaITEService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`autorizacoes-transferencia-vindo-ITE?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`autorizacoes-transferencia-vindo-ITE?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async autorizarTransferenciaITE (cnpjITE, idEstoque, valorProduto, leasing) {
    return await ServiceBase.postBase('transferenciaZeroKm/autorizarTransferirITE', { cnpjITE, idEstoque, valorProduto, leasing }, ComunicaRenave)
  }

  static async receberTransferenciaVindoITE (chaveRemessa, chaveProduto, chaveServico, idAutorizacao, valorProduto) {
    const receberTransferenciaVindoITERequest = {
      chaveRemessa,
      chaveProduto,
      chaveServico,
      idAutorizacao,
      valorProduto
    }

    return await ServiceBase.postBase('transferenciaZeroKm/receberTransferenciaVindoITE', receberTransferenciaVindoITERequest, ComunicaRenave)
  }

  static async cancelarAutorizacaoTransferenciaITE (idAutorizacaoITE, chassi) {
    return await ServiceBase.postBase('transferenciaZeroKm/cancelarAutorizacaoTransferenciaITE', { idAutorizacaoITE, chassi }, ComunicaRenave)
  }
}
