import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EstoqueUsadosService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`estoque-usados?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`estoque-usados?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async criarVeiculoMock (sufixoPlacaMock, cpfProprietarioMock, nomeProprietarioMock, veiculoRouboFurtoMock, veiculoBaixadoMock, ufJurisdicaoMock) {
    return await ServiceBase.postBase('estoque-usados/criarVeiculoMock', { sufixoPlacaMock, cpfProprietarioMock, nomeProprietarioMock, veiculoRouboFurtoMock, veiculoBaixadoMock, ufJurisdicaoMock }, ComunicaRenave)
  }
}
