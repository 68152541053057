import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EntregasConcessionariaService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`vendaDireta/entregas/concessionaria?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`vendaDireta/entregas/concessionaria?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async entregarVeiculo (idEntrega) {
    return await ServiceBase.postBase(`vendaDireta/entregar/${idEntrega}`, ComunicaRenave)
  }

  static async cancelarEntrega (idEntrega) {
    return await ServiceBase.postBase(`vendaDireta/cancelar-entrega/${idEntrega}`, ComunicaRenave)
  }
}
