import * as Yup from 'yup'

export const UsuarioValidator = Yup.object().shape({
  nomeUsu: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cpfUsu: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emailUsu: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  telcomUsu: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!')
})
