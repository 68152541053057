import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import React, { useState, useEffect } from 'react'
import SearchInput from '../../../../components/inputs/SearchInput'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { getPessoaDTO } from '../../../../dtos/cadastro/PessoaDTO'
import { formatCnpj } from '../../../../helpers/formaters'
import EmpresasAndPessoasJuridicasService from '../../../../services/cadastro/EmpresasAndPessoasJuridicasService'
import XmlConverter from 'x2js'
import TransferenciaMontadoraService from '../../../../services/montadora/TransferenciaMontadoraService'
import { Checkbox } from 'primereact/checkbox'
import SaidaMontadoraService from '../../../../services/montadora/SaidaMontadoraService'

const TransferirIteNaMontadoraModal = ({ visible, onHide, veiculoParaTransferir, getAll }) => {
  const [ITE, setITE] = useState(null)
  const [sugestaoITE, setSugestaoITE] = useState([])
  const [chaveNotaFiscal, setChaveNotaFiscal] = useState('')
  const [beneficioFiscal, setBeneficioFiscal] = useState({ descri_bfi: 'Não possui', codigo_bfi: 'NAO_TEM' })
  const [leasing, setLeasing] = useState(false)
  const [beneficiosFiscais, setBeneficiosFiscais] = useState([])

  useEffect(() => {
    getAllBeneficioFiscal()
  }, [visible])

  async function getAllBeneficioFiscal () {
    let benefs = await SaidaMontadoraService.getAllBeneficioFiscal()
    setBeneficiosFiscais(benefs)
  }
  const sugerirITEs = async () => {
    try {
      const empresasAndPessoasJuridicasService = new EmpresasAndPessoasJuridicasService()

      const ITEs = (await empresasAndPessoasJuridicasService.filter(`descricaoFiltro=${ITE.nome}`)).data

      setSugestaoITE(ITEs)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma ITE!')
    }
  }

  const handleTransferirVeiculo = async () => {
    try {
      if (!ITE?.cnpj) {
        showErrorMessage('Por favor selecionar uma ITE válida')
        return
      }

      await TransferenciaMontadoraService.autorizarTransferenciaITE(ITE.cnpj, veiculoParaTransferir.id, chaveNotaFiscal, beneficioFiscal.codigo_bfi, leasing)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  function handleChangeITE (event) {
    setITE({ nome: event.target.value })
  }

  function hideModal () {
    setITE(null)
    setBeneficioFiscal({ descri_bfi: 'Não possui', codigo_bfi: 'NAO_TEM' })
    setChaveNotaFiscal('')
    onHide()
  }

  async function handleUploadChaveXml (event) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', (event) => readFile(event))

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    setChaveNotaFiscal(chaveNFe)
  }

  function handleChangeLeasing (value) {
    setLeasing(!value)
  }

  return (
    <Modal
      header="Autorizar transferência para ITE"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleTransferirVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <SearchInput
            //AutoComplete
            label="ITE"
            field="nome"
            value={ITE?.nome && ITE}
            suggestions={sugestaoITE}
            completeMethod={sugerirITEs}
            onChange={handleChangeITE}
            onSelect={(e) => setITE(e.value)}
            className="inputfield w-full"
            //FiltroModal
            filtroTitle="Pesquisa de pessoa jurídica e empresas"
            service={EmpresasAndPessoasJuridicasService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nome', nome: 'Nome' },
              { campo: 'cnpj', nome: 'CNPJ', format: formatCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-9">
          <label className="label required">Benefício fiscal</label>
          <SelectInput
            value={beneficioFiscal}
            className="inputfield w-full"
            placeholder="- Selecione -"
            optionLabel="descri_bfi"
            dataKey="codigo_bfi"
            options={beneficiosFiscais}
            onChange={(e) => setBeneficioFiscal(e.target.value)}
            noFloatLabel
          />
        </div>
        <div className="field checkbox-container col-3">
          <Checkbox
            name="leasing"
            value={leasing}
            onChange={(e) => handleChangeLeasing(e.target.value)}
            checked={leasing}
            disabled={false}
          />
          <label className="p-checkbox-label mb-0 pl-2">Possui Leasing?</label>
        </div>
        <div className="field col-12 md:col-9">
          <TextInput
            label="Chave da nota fiscal"
            className="inputfield w-full"
            value={chaveNotaFiscal}
            onChange={(e) => setChaveNotaFiscal(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-3 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota fiscal"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default TransferirIteNaMontadoraModal
