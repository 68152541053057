import { server } from '../../../helpers/server'
import ServiceBase from '../../base/ServiceBase'

export default class HistoricoMovimentacaoService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`historicoMovimentacao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`historicoMovimentacao?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getOperacoes () {
    const response = await server().get('historicoMovimentacao/operacoes')
    return response.data
  }
}
