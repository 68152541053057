export const getXmlImportDTO = (data = {}) => {
  return {
    codigo: data.codigo,
    numeronf: data.numeronf,
    chassi: data.chassi,
    cnpjemitente: data.cnpjemitente,
    nome: data.nome,
    cpfcnpj: data.cpfcnpj,
    uf: data.uf,
    tiponf: data.tiponf,
    erroxml: data.erroxml
  }
}
