import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import Container from '../../../components/layout/Container'
import { baseForm } from '../../../components/utils/BaseForm'
import Confirm from '../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import { setUsuarioLogado } from '../../../redux/actions'
import { dispatch, getStore } from '../../../redux/store'
import UsuarioService from '../../../services/cadastro/UsuarioService'
import DadosPrincipaisUsu from './DadosPrincipaisUsu'
import { UsuarioValidator } from './UsuarioValidator'

const UsuarioForm = ({ isNew, model, onDelete }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (isNovo) {
      form.setFieldValue('ativoUsu', true)
      setEdicaoHabilitada(true)
    }
  }, [])

  const handleSubmit = async (data) => {
    try {
      const usuario = await UsuarioService.save(data)
      const usuarioLogado = getStore().usuarioLogado

      if (usuarioLogado.codigo_usu === usuario.codigo_usu) {
        dispatch(setUsuarioLogado(usuario))
      }

      history.push(`/usuario/${usuario.codigo_usu}`)
      form.setFieldValue('codigoUsu', usuario.codigo_usu)

      setIsNovo(false)
      showSuccessMessage('Registro salvo com sucesso')
      setEdicaoHabilitada(false)
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro')
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const errors = await form.validateForm(form.values)

    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const deleteForm = async () => {
    onDelete(form.values.codigoUsu)
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: UsuarioValidator,
    onSubmit: handleSubmit
  })

  return (
    <Container col="12">
      <form onSubmit={submitForm}>
        <div className="flex justify-content-between form-header">
          <h1>Usuário</h1>
          {!isNovo && (
            <div className="flex justify-content-between">
              <Button
                label="Excluir"
                icon="pi pi-times"
                type="button"
                onClick={() => setVisibleConfirm(true)}
                className="p-button-danger mr-2"
              />
              <Button
                type="button"
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              />
            </div>
          )}
        </div>
        <div className="grid form-body">
          <AccordionContainer>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisUsu
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end form-footer">
          {edicaoHabilitada && (
            <Button
              type="submit"
              label="Salvar"
              className="p-button"
            />
          )}
        </div>
      </form>
      <Confirm
        visible={visibleConfirm}
        onConfirm={() => deleteForm()}
        onCancel={() => setVisibleConfirm(false)}
        title="Confirmação"
        description="Deseja realmente excluir este registro?"
      />
    </Container>
  )
}

export default UsuarioForm
