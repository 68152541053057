import ServiceBase from '../base/ServiceBase'

export default class DespachanteService {
  static async convidarLojista (emailLojista, nomeLojista) {
    return await ServiceBase.postBase('despachante/convidarLojista', { emailLojista, nomeLojista })
  }

  static async reconvidarLojista (codigoConvite) {
    return await ServiceBase.postBase('despachante/reconvidarLojista', { codigoConvite })
  }
}
