import React from 'react'
import { Crud } from '../../../classes/Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getVeiculoDTO } from '../../../dtos/cadastro/VeiculoDTO'
import VeiculoForm from '../../../forms/cadastro/veiculo/VeiculoForm'
import VeiculoService from '../../../services/cadastro/VeiculoService'

export class Veiculo extends Crud {
  constructor (props) {
    super(props, getVeiculoDTO, VeiculoService, 'veiculos')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) &&
            <VeiculoForm
              model={model}
              isNew={isNew}
              onDelete={this.onDelete}
            />
          }
        </Container>
      </Page>
    )
  }
}
