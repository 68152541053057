import Modal from '../../../components/utils/Modal'
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/inputs/SearchInput'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import PessoaService from '../../../services/cadastro/PessoaService'
import { formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../helpers/formaters'
import PessoaModal from '../../../forms/cadastro/pessoa/PessoaModal'
import IteService from '../../../services/ITE/IteService'
import TextInput from '../../../components/inputs/TextInput'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import XmlConverter from 'x2js'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import DateInput from '../../../components/inputs/DateInput'

function SaidaEstoqueIteModal ({ visible, onHide, onFilter, veiculo }) {
  const [comprador, setComprador] = useState({})
  const [sugestaoCompradores, setSugestaoCompradores] = useState([])
  const [valor, setValor] = useState(0)
  const [dataVenda, setDataVenda] = useState(new Date())
  const [chaveProduto, setChaveProduto] = useState('')
  const [chaveServico, setChaveServico] = useState('')

  function hideModal () {
    setComprador({})
    setSugestaoCompradores([])
    setValor(0)
    setChaveProduto('')
    setChaveServico('')
    onHide()
  }

  useEffect(() => {
    if (veiculo.valorVeiculo) {
      setValor(veiculo.valorVeiculo)
    }
  }, [veiculo])

  async function sugerirCompradores () {
    try {
      const pessoaService = new PessoaService()

      const clientes = (await pessoaService.filter(`descricaoFiltro=${comprador.nomraz_pes}`)).data

      if (clientes.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoCompradores(clientes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  function handleChangeComprador (event) {
    setComprador({ nomraz_pes: event.target.value })
  }

  function handleOnRegisterComprador (clienteRegistrado) {
    setComprador(clienteRegistrado)
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValor(formatCurrencyToDecimal(novoValor))
  }

  async function handleUploadChaveXml (event, tipoChave) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', (event) => readFile(event, tipoChave))

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event, tipoChave) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    switch (tipoChave) {
    case 'chaveProduto':
      setChaveProduto(chaveNFe)
      break
    case 'chaveServico':
      setChaveServico(chaveNFe)
      break
    }
  }

  async function confirmarSaida () {
    try {
      if (!dataVenda) {
        showErrorMessage('Por favor informe uma data de venda válida!')
        return
      }

      if (!comprador?.codigo_pes) {
        showErrorMessage('Por favor informe um comprador válido!')
        return
      }

      if (valor <= 0) {
        showErrorMessage('Por favor informe um valor válido!')
        return
      }

      await IteService.confirmarSaida(veiculo, comprador, valor, dataVenda, chaveProduto, chaveServico)

      await onFilter()
      hideModal()
      showSuccessMessage('Veículo confirmado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar confirmar a saída do veículo!')
    }
  }

  return (
    <Modal
      header="Saída de veículo em ITE"
      visible={visible}
      onHide={() => hideModal()}
      width={60}
      footer={
        <Button
          label="Confirmar saída"
          icon="pi pi-check"
          onClick={async () => await confirmarSaida()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-6">
          <SearchInput
            //AutoComplete
            label="Comprador"
            placeholder="Comprador da ITE"
            field="nomraz_pes"
            value={comprador?.nomraz_pes && comprador}
            suggestions={sugestaoCompradores}
            completeMethod={sugerirCompradores}
            onChange={handleChangeComprador}
            onSelect={(e) => setComprador(e.value)}
            className="inputfield w-full"
            //RegisterModal
            edicaoHabilitada={true}
            ComponenteCadastro={PessoaModal}
            onRegister={handleOnRegisterComprador}
            primaryKeyName="codigo_pes"
            selected={comprador}
            //FiltroModal
            filtroTitle="Pesquisa de pessoas"
            service={PessoaService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-3">
          <label className="label required">Valor</label>
          <TextInput
            placeholder="Valor do veículo"
            value={formatToCurrency(valor)}
            onChange={(e) => handleChangeValor(e)}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-3">
          <label className="label required">Data da venda</label>
          <DateInput
            placeholder="Data de saída"
            value={dataVenda}
            onChange={(e) => setDataVenda(e.value)}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Produto)"
            className="inputfield w-full"
            value={chaveProduto}
            onChange={(e) => setChaveProduto(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota do produto"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event, 'chaveProduto')}
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Serviço)"
            className="inputfield w-full"
            value={chaveServico}
            onChange={(e) => setChaveServico(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota de serviço"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event, 'chaveServico')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SaidaEstoqueIteModal
