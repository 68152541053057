import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class ImpressoesUsadosService {
  static async getTermoEntrada (idEstoque) {
    return await ServiceBase.getBase(`impressoes-usados/termo-entrada/${idEstoque}`, ComunicaRenave)
  }

  static async getTermoSaida (idEstoque) {
    return await ServiceBase.getBase(`impressoes-usados/termo-saida/${idEstoque}`, ComunicaRenave)
  }

  static async getCrlv (placa, renavam) {
    return await ServiceBase.getBase(`impressoes-usados/crlv/${placa}/${renavam}`, ComunicaRenave)
  }

  static async getUltimoCodSegurancaCRLVe (placa, renavam) {
    return await ServiceBase.getBase(`impressoes-usados/crlve/${placa}/${renavam}/pdf-codigo-seguranca-crv`, ComunicaRenave)
  }
}
