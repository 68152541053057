import { Button } from 'primereact/button'
import React from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { formatToCurrency } from '../../../../helpers/formaters'
import VeiculosTransferenciaService from '../../../../services/zeroKm/veiculos/VeiculosTransferenciaService'

const TransferirFilialModal = ({ visible, onHide, veiculoParaTransferir, getAll }) => {
  const handleTransferirVeiculo = async () => {
    try {
      await VeiculosTransferenciaService.transferirVeiculoParaFilial(veiculoParaTransferir.codigo_imp)
      onHide()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  return (
    <Modal
      header={`Transferir Veículo: ${veiculoParaTransferir.chassi_vei}`}
      width={55}
      onHide={onHide}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleTransferirVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-8">
          <TextInput
            label="Filial"
            placeholder="Filial"
            className="inputfield w-full"
            value={veiculoParaTransferir.nomdes_imp}
            disabled={true}
          />
        </div>
        <div className="field col-4">
          <TextInput
            label="CNPJ"
            placeholder="CNPJ"
            className="inputfield w-full"
            mask="99.999.999/9999-99"
            value={veiculoParaTransferir.cpfcnpj_imp}
            disabled={true}
          />
        </div>
        <div className="field col-8">
          <TextInput
            label="Chave de acesso"
            placeholder="Chave de acesso"
            className="inputfield w-full"
            value={veiculoParaTransferir.chave_imp}
            disabled={true}
          />
        </div>
        <div className="field col-4">
          <TextInput
            label="Valor"
            placeholder="Valor"
            className="inputfield w-full"
            value={formatToCurrency(veiculoParaTransferir.valor_imp || 0)}
            disabled={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default TransferirFilialModal
