import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox'

class CheckBoxTermoAceite extends Component {
  render () {
    return (
      <span className={`checkbox-container ${this.props.className}`}>
        <Checkbox {...this.props} />
        <label className="p-checkbox-label pl-2" htmlFor={this.props.name}>{<>
              Ao vincular com o despachante, você concorda com o{' '}
          <a
            href="https://www.renaveauto.com.br/termo-de-aceite-lojista-despachante"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: 'blue' }}
          >
                TERMO DE ADESÃO A PLATAFORMA RENAVEAUTO, AUTORIZAÇÃO E ANUÊNCIA DO LOJISTA AO DESPACHANTE
          </a>.
        </>}</label>
      </span>
    )
  }
}

export default CheckBoxTermoAceite
