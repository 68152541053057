import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import EntradaZeroKmService from '../../../../services/zeroKm/veiculos/EntradaZeroKmService'

const CancelarRejeicaoVeiculoModal = ({ visible, onHide, getAll }) => {
  const [chassi, setChassi] = useState('')

  const hide = () => {
    setChassi('')
    onHide()
  }

  const handleCancelarRejeicao = async () => {
    try {
      await EntradaZeroKmService.cancelarRejeicaoZeroKm(chassi)

      hide()
      getAll()
      showSuccessMessage('Rejeição cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao tentar cancelar a rejeição de entrada')
    }
  }

  return (
    <Modal
      visible={visible}
      onHide={hide}
      header="Cancelar rejeição de entrada"
      width={35}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleCancelarRejeicao()}
        />
      }
    >
      <div className="field col-12">
        <TextInput
          label="Chassi"
          placeholder="Chassi"
          className="inputfield w-full"
          value={chassi}
          onChange={(e) => setChassi(e.target.value)}
        />
      </div>
    </Modal>
  )
}

export default CancelarRejeicaoVeiculoModal
