import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { SplitButton } from 'primereact/splitbutton'
import { Tooltip } from 'primereact/tooltip'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AES from '../../helpers/AES'
import { logout, setCredentials } from '../../helpers/auth'
import CommonHelper from '../../helpers/CommonHelper'
import { formatToCurrency } from '../../helpers/formaters'
import { setCertificadoValidoIte, setCertificadoValidoMontadora, setCertificadoValidoVendaVeiculos, setEmpresaLogada } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import EmpresaService from '../../services/cadastro/EmpresaService'
import UsuarioService from '../../services/cadastro/UsuarioService'
import InserirCreditoDetranModal from '../../views/taxadetran/InserirCreditoDetran'
import InputFile from '../inputs/InputFile'
import TextInput from '../inputs/TextInput'
import ChangePasswordModal from '../utils/ChangePasswordModal'
import { showErrorMessage, showSuccessMessage } from '../utils/Message'
import Modal from '../utils/Modal'
export class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null
  }

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      busca: '',
      redirect: false,
      selectedCity: null,
      selectedFile: null,
      certificatePassword: '',
      visibleChangePassword: false,
      visibleUploadCertificate: false,
      visibleVerificaCertificadoModal: false,
      visibleInserirCredito: false,
      certificadoInvalido: false,
      required: false,
      totalCredito: 0

    }

    this.empresaService = new EmpresaService()
    this.usuarioService = new UsuarioService()
  }

  componentDidMount () {
    this.updateTaxaDetran()
  }

  logout = () => {
    logout()
    window.location.href = '/login'
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  UploadCertificate = async () => {
    let { selectedFile, certificatePassword } = this.state
    try {
      if (selectedFile === null) throw Object({ message: 'Adicione o certificado digital!' })
      if (certificatePassword === '') throw Object({ message: 'O campo senha do certificado deve ser preenchido!' })

      const data = new FormData()
      data.append('certificado', selectedFile)
      data.append('senha', AES.encryptWithAES(certificatePassword))

      await this.empresaService.uploadCertificate(data)
      await EmpresaService.getEmpresaLogada().then(empresa => {
        dispatch(setEmpresaLogada(empresa))
      })

      const renaveZeroKm = getStore().empresaLogada.rennov_emp
      const renaveUsados = getStore().empresaLogada.renusa_emp
      const renaveIte = getStore().empresaLogada.renite_emp
      const renaveMontadora = getStore().empresaLogada.renmon_emp

      if (renaveUsados || renaveZeroKm) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('vendaVeiculos')
          dispatch(setCertificadoValidoVendaVeiculos(true))
        } catch (error) {
          dispatch(setCertificadoValidoVendaVeiculos(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      if (renaveIte) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('ite')
          dispatch(setCertificadoValidoIte(true))
        } catch (error) {
          dispatch(setCertificadoValidoIte(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      if (renaveMontadora) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('montadora')
          dispatch(setCertificadoValidoMontadora(true))
        } catch (error) {
          dispatch(setCertificadoValidoMontadora(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      this.onCloseDialog()
      this.setState({ visibleVerificaCertificadoModal: true })
      showSuccessMessage('Certificado importado com sucesso!')
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro. Tente novamente mais tarde.')
    }
    this.setState({ required: true })
  }

  onCloseDialog = async () => {
    this.setState({
      visibleChangePassword: false,
      visibleUploadCertificate: false,
      visibleVerificaCertificadoModal: false,
      visibleInserirCredito: false,
      selectedFile: null,
      certificateName: 'Selecione um arquivo',
      certificatePassword: '',
      certificadoInvalido: false,
      required: false
    })
  }

  handleOnHide = async () => {
    this.onCloseDialog()
    try {
      const empresa = await EmpresaService.getEmpresaLogada()
      dispatch(setEmpresaLogada(empresa))
      this.updateTaxaDetran()
    } catch (error) {
      console.error('Erro ao obter empresa logada:', error)
    }
  };

  async handleTrocarEmpresa () {
    try {
      const codEmp = getStore().usuarioLogado.codemp_usu

      if (codEmp) {
        const credentials = await EmpresaService.trocarEmpresa(codEmp)
        setCredentials(credentials)
        dispatch(setEmpresaLogada({}))

        this.props.history.push('/home')
        window.location.reload()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao tentar trocar de empresa!')
    }
  }

  getActions () {
    const empresa = getStore().empresaLogada

    const actions = [{
      label: 'Trocar Senha',
      icon: 'pi pi-fw pi-unlock',
      command: () => {
        this.setState({ visibleChangePassword: true })
      }
    }]

    if (!CommonHelper.isUsuarioDespachante()) {
      actions.push({
        label: 'Certificado Digital',
        icon: 'pi pi-fw pi-key',
        command: () => {
          this.setState({ visibleUploadCertificate: true })
        }
      })
    }

    if (empresa.possuiCertificado) {
      actions.push({
        label: 'Validar Credenciamento',
        icon: 'pi pi-fw pi-key',
        command: () => {
          this.setState({ visibleVerificaCertificadoModal: true })
        }
      })
    }

    actions.push({
      label: (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div>Central de treinamentos</div><div><Badge severity="success" style={{ position: 'relative' }} value="Novo" /></div></div>),
      icon: 'pi pi-fw pi-book',
      command: () => {
        window.open('https://materiais.sances.com.br/renaveauto-tutoriais', '_blank')
      }
    })

    actions.push({
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        this.logout()
      }
    })

    return actions
  }

  async validarCertificado (tipoValidamento) {
    try {
      await EmpresaService.verificaCertificadoComSerpro(tipoValidamento)
      switch (tipoValidamento) {
      case 'vendaVeiculos':
        dispatch(setCertificadoValidoVendaVeiculos(true))
        break
      case 'ite':
        dispatch(setCertificadoValidoIte(true))
        break
      case 'montadora':
        dispatch(setCertificadoValidoMontadora(true))
        break
      }
      showSuccessMessage('Validação efetuada com sucesso!')
      this.onCloseDialog()
    } catch (error) {
      if (!error.message.includes('por favor importar o certificado da empresa')) {
        this.setState({ certificadoInvalido: true })
      }
      switch (tipoValidamento) {
      case 'vendaVeiculos':
        dispatch(setCertificadoValidoVendaVeiculos(false))
        break
      case 'ite':
        dispatch(setCertificadoValidoIte(false))
        break
      case 'montadora':
        dispatch(setCertificadoValidoMontadora(false))
        break
      }
      showErrorMessage(error.message || 'Falha de comunicação com a SERPRO!')
    }
  }

  async updateTaxaDetran () {
    try {
      const empresa = await EmpresaService.getEmpresaLogada()
      this.setState({ totalCredito: empresa.totcredtaxdetran_emp })
      dispatch(setEmpresaLogada(empresa))
    } catch (error) {
      console.error('Erro ao atualizar taxaDetran:', error)
    }
  }

  render () {
    const { certificateName, certificatePassword, required, totalCredito } = this.state
    const usuario = getStore().usuarioLogado
    const usuarioSances = usuario.email === 'suporte@sances.com.br'
    const renaveZeroKm = getStore().empresaLogada.rennov_emp
    const renaveUsados = getStore().empresaLogada.renusa_emp
    const renaveIte = getStore().empresaLogada.renite_emp
    const renaveMontadora = getStore().empresaLogada.renmon_emp
    // const totalCredito = getStore().empresaLogada.totcredtaxdetran_emp
    const obrigaTaxa = getStore().empresaLogada.cobratransf_uf
    const codigoDespachante = getStore().empresaLogada.coddesp_emp
    const mostrarTaxa = (CommonHelper.isEmpresaDespachante() || (obrigaTaxa && codigoDespachante === null))

    return (
      <>
        <div className="layout-topbar clearfix">
          <div className={`layout-topbar-menu ${mostrarTaxa ? 'mostrar-credito' : 'justify-content-between'}`}>
            <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
              <span className="pi pi-bars" />
            </button>
            {mostrarTaxa &&
              <div className="flex align-items-center w-full">
                <Tooltip target=".custom-target-icon" style={{ marginTop: 5, fontSize: 10 }} />
                <p className='textCredito'>{formatToCurrency(totalCredito)}</p>
                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                  data-pr-tooltip="Total de créditos relativo a taxa de transferência do DETRAN."
                  data-pr-position="bottom"
                  style={{ marginLeft: '5px', fontSize: '15px', cursor: 'pointer' }}>
                </i>
                {usuarioSances && mostrarTaxa &&
                   <div>
                     <Button
                       label=""
                       type="button"
                       className="p-button-secondary"
                       onClick={this.updateTaxaDetran}
                       icon="pi pi-sync"
                     />

                     <Button
                       label="+ Adicionar créditos"
                       className="p-button-secondary"
                       onClick={() => this.setState({ visibleInserirCredito: true })}
                     />

                   </div>
                }
              </div>
            }
            {(CommonHelper.isUsuarioDespachante() && !CommonHelper.isEmpresaDespachante()) ? (
              <div className={`flex align-items-center justify-content-end ${mostrarTaxa && 'button-despachante'} w-full`} >
                <Button
                  label="Área do despachante"
                  className="mr-6 p-button-secondary"
                  onClick={() => this.handleTrocarEmpresa()}
                />
              </div>
            ) : <></>
            }
            <span className="small-user-menu" >
              <SplitButton label={' '} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
            <span className="big-user-menu" style={{ display: 'flex' }}>
              <SplitButton label={usuario.nome_usu} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
          </div>
        </div>
        <InserirCreditoDetranModal
          visible={this.state.visibleInserirCredito}
          onHide={this.handleOnHide}
        />
        <ChangePasswordModal
          visible={this.state.visibleChangePassword}
          usuarioLogado={usuario}
          hide={this.onCloseDialog}
          reloadUsuario={this.getUsuarioLogado}
        />
        <Modal
          header="Certificado Digital"
          focusOnShow={false}
          visible={this.state.visibleUploadCertificate}
          width={25}
          onHide={this.onCloseDialog}
          footer={
            <Button
              label="Enviar"
              icon="pi pi-check"
              onClick={this.UploadCertificate}
              autoFocus={true}
            />
          }
        >
          <div className="p-grid p-fluid">
            <div className="field col-12">
              <div>
                <label>Extensão pfx ou p12</label>
                <InputFile
                  uploadHandler={(event) => this.setState({ selectedFile: event.files[0], certificateName: event.files[0].name })}
                  label={{ label: certificateName ? certificateName : 'Selecione o arquivo' }}
                />
              </div>
            </div>
            <div className="field col-12">
              <div>
                <TextInput
                  type="password"
                  label="Senha do certificado"
                  name="certificatePassword"
                  required={required}
                  id="certificatePassword"
                  value={certificatePassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          header="Validação do credenciamento SERPRO"
          visible={this.state.visibleVerificaCertificadoModal}
          focusOnShow={false}
          onHide={this.onCloseDialog}
          width={35}
        >
          {(renaveUsados || renaveZeroKm) ? (
            <Button
              label="Validar credenciamento - Venda de veículos"
              onClick={async () => await this.validarCertificado('vendaVeiculos')}
              className="inputfield w-full"
            />
          ) : <></>}
          {renaveIte ? (
            <Button
              label="Validar credenciamento - ITE"
              onClick={async () => await this.validarCertificado('ite')}
              className="inputfield w-full mt-2"
            />
          ) : <></>}
          {renaveMontadora ? (
            <Button
              label="Validar credenciamento - Montadora"
              onClick={async () => await this.validarCertificado('montadora')}
              className="inputfield w-full mt-2"
            />
          ) : <></>}
          {this.state.certificadoInvalido && (
            <>
              <div className="flex justify-content-center mt-4">
                <div className="red bold mb-4">Falha de comunicação com a SERPRO</div>
              </div>
              <div className="ml-6">
                <div>
                  <label className="bold">Verifique </label><label>se:</label>
                </div>
                <div className="ml-3">
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">credenciamento </label>
                    <label>foi </label>
                    <label className="bold">submetido no Credencia.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>A </label>
                    <label className="bold">contratação do E-commerce </label>
                    <label>foi </label>
                    <label className="bold">finalizada.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>A </label>
                    <label className="bold">habilitação do certificado digital </label>
                    <label>foi </label>
                    <label className="bold">finalizada.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">Certificado </label>
                    <label>digital </label>
                    <label className="bold">importado no Credencia </label>
                    <label>é o </label>
                    <label className="bold">mesmo importado no RenaveAuto.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">Certificado </label>
                    <label>está </label>
                    <label className="bold">vencido.</label>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Para mais informações acesse o </span>
                  <span
                    className="underline cursor-pointer bold"
                    onClick={() => window.open('https://sances.com.br/blog/credenciamento-renave/')}
                  >
                    Manual do Credencia.
                  </span>
                </div>
              </div>
            </>
          )}
        </Modal>
      </>
    )
  }
}
