import * as Yup from 'yup'

export const EmpresaValidator = Yup.object().shape({
  nomrazEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  nomfanEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cnpjEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emailEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cepEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  endEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  bairroEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  codmunEmp: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  codufEmp: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  nomresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emaresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  telresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!')
})
