import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { getEstoqueRenaveDTO } from '../../../dtos/renave/EstoqueRenaveDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatCnpj, formatDescricaoVeiculo } from '../../../helpers/formaters'
import EntregasMontadoraService from '../../../services/montadora/EntregasMontadoraService'

class EntregaVeiculosMontadora extends List {
  constructor (props) {
    super(
      props,
      getEstoqueRenaveDTO,
      EntregasMontadoraService,
      '',
      '',
      'pendentes'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'id',
        component: (
          <Column
            style={{ width: '10%' }}
            header="Id Estoque"
            field="id"
            sortable
            headerClassName="id"
          />
        )
      },
      {
        key: 'chassi',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Veiculo"
            body={data => formatDescricaoVeiculo(data.chassi, data.descricaoMarcaModelo)}
            field="chassi"
            sortable
            headerClassName="chassi"
          />
        )
      },
      {
        key: 'entradaEstoque.dataHora',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Vendedor"
            body={data => (data.nomeVendedora ? (data.nomeVendedora + ' - ') : '') + formatCnpj(data.cnpjEstabelecimentoVendedor)}
            field="entradaEstoque.dataHora"
            sortable
            headerClassName="entradaEstoque.dataHora"
          />
        )
      },
      {
        key: 'cnpjEstabelecimentoEntregador',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Entregador"
            body={data => (data.nomeEntregadora ? (data.nomeEntregadora + ' - ') : '') + formatCnpj(data.cnpjEstabelecimentoEntregador)}
            field="cnpjEstabelecimentoEntregador"
            sortable
            headerClassName="cnpjEstabelecimentoEntregador"
          />
        )
      },
      {
        key: 'realizacaoEntrega.dataHoraRegistro',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Entrega"
            body={data => CommonHelper.dateToBrazilian(data.realizacaoEntrega?.dataHoraRegistro)}
            field="realizacaoEntrega.dataHoraRegistro"
            sortable
            headerClassName="realizacaoEntrega.dataHoraRegistro"
          />
        )
      },
      {
        key: 'cancelamentoEntrega.dataHoraRegistro',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Cancelamento"
            body={data => CommonHelper.dateToBrazilian(data.cancelamentoEntrega?.dataHoraRegistro)}
            field="cancelamentoEntrega.dataHoraRegistro"
            sortable
            headerClassName="cancelamentoEntrega.dataHoraRegistro"
          />
        )
      }
    ])
  }

  render () {
    const state = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'pendentes':
        filter.situacaoVeiculos = 'pendentes'
        break
      case 'realizados':
        filter.situacaoVeiculos = 'realizados'
        break
      case 'cancelados':
        filter.situacaoVeiculos = 'cancelados'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Entregas de veículos na montadora</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'pendentes' }, 'filter-option')}
              onClick={() => onSelectOption('pendentes')}>
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">
                {state.quantidades.quantidadePendentes}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'realizados' }, 'filter-option')}
              onClick={() => onSelectOption('realizados')}>
              <span className="option-label">Realizados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeRealizados}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'cancelados' }, 'filter-option')}
              onClick={() => onSelectOption('cancelados')}>
              <span className="option-label">Cancelados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeCancelados}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Procurar entregas"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

export default EntregaVeiculosMontadora
