import moment from 'moment-timezone'
import { clearDigitsSpecialChars } from './parsers'

export function formatUpperCase (value) {
  return value.toUpperCase()
}

export function formatLowerCase (value) {
  return value.toLowerCase()
}

export function formatCpfOrCnpj (value) {
  let rawValue = clearDigitsSpecialChars(value)

  if (rawValue.length > 11) {
    return formatCnpj(rawValue)
  }

  return formatCpf(rawValue)
}

export function formatCpf (value) {
  let rawValue = clearDigitsSpecialChars(value)

  if (!rawValue) {
    return ''
  }

  let newValue = rawValue.substr(0, 3)

  if (rawValue.length > 3) {
    newValue += '.' + rawValue.substr(3, 3)
  }

  if (rawValue.length > 6) {
    newValue += '.' + rawValue.substr(6, 3)
  }

  if (rawValue.length > 9) {
    newValue += '-' + rawValue.substr(9, 2)
  }

  return newValue
}

export function formatCnpj (value) {
  let rawValue = clearDigitsSpecialChars(value)

  if (!rawValue) {
    return ''
  }

  let newValue = rawValue.substr(0, 2)

  if (rawValue.length > 2) {
    newValue += '.' + rawValue.substr(2, 3)
  }

  if (rawValue.length > 5) {
    newValue += '.' + rawValue.substr(5, 3)
  }

  if (rawValue.length > 8) {
    newValue += '/' + rawValue.substr(8, 4)
  }

  if (rawValue.length > 12) {
    newValue += '-' + rawValue.substr(12, 2)
  }

  return newValue
}

export function formatDocument (value) {
  if (!value) return ''

  if (value.length < 15) {
    return formatCpf(value)
  } else {
    return formatCnpj(value)
  }
}

export function firstChar (value) {
  if (value)
    return value[0]

  return ''
}

export function formatPhone (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let front = 4
  if (rawValue.length > 10) {
    front = 5
  }

  let newValue = ''
  if (rawValue.length > 0) {
    newValue += '(' + rawValue.substr(0, 2)
  }

  if (rawValue.length > 2) {
    newValue += ') ' + rawValue.substr(2, front)
  }

  if (rawValue.length >= 7) {
    newValue += '-' + rawValue.substr(front + 2, 4)
  }

  return newValue
}

export function formatCep (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let newValue = rawValue.substr(0, 2)

  if (rawValue.length > 2) {
    newValue += '.' + rawValue.substr(2, 3)
  }

  if (rawValue.length > 5) {
    newValue += '-' + rawValue.substr(5, 3)
  }

  return newValue
}

export function formatToCurrency (value) {
  const number = Number(value || 0)

  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function formatCurrencyToDecimal (value) {
  const rawValue = clearDigitsSpecialChars(value)
  let number = Number(rawValue) * 0.01
  return number.toFixed(2)
}

export function formatDecimal (value) {
  return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

export function formatDecimalToInteger (value) {
  return Number((value).toString().split('.').join(''))
}

export function formatArea (value, suffix) {
  if (!suffix) {
    suffix = ''
  }

  const rawValue = formatDecimal(value)
  return rawValue + suffix
}

export function formatDate (date) {
  return moment(date).format('DD/MM/YYYY')
}

export function formatDateTime (date, seconds = false) {
  return moment(date).tz('America/Sao_Paulo').format(`DD/MM/YYYY - HH:mm${seconds ? ':ss' : ''}`)
}

export function formatDateTimeSQL (date, seconds = true) {
  return moment(date).tz('America/Sao_Paulo').format(`YYYY-MM-DD HH:mm${seconds ? ':ss' : ''}`)
}

export function formatTimestampToDate (timestamp) {
  let date = new Date(timestamp * 1000)
  return date.toLocaleDateString()
}

export function formatDescricaoVeiculo (chassiOuPlaca, marcaModelo) {
  if (chassiOuPlaca && marcaModelo?.trim() && (chassiOuPlaca !== marcaModelo)) {
    return `${chassiOuPlaca} - ${marcaModelo}`
  }

  return chassiOuPlaca
}

export function formatPlaca (value) {
  if (value?.replace) {
    return value.replace(/([A-Z]{3})([0-9][0-9A-Z][0-9]{2})/, '$1-$2')
  } else {
    return
  }
}
