import { Button } from 'primereact/button'
import React from 'react'
// import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import EntradaZeroKmService from '../../../../services/zeroKm/veiculos/EntradaZeroKmService'

const RejeitarVeiculoModal = ({ visible, onHide, chassi, getAll }) => {
  // const [motivoCancelamento, setMotivoCancelamento] = useState('')

  const hide = () => {
    // setMotivoCancelamento('')
    onHide()
  }

  const handleRejeitarVeiculo = async () => {
    // if (motivoCancelamento === '') {
    //   showErrorMessage('Para rejeitar um veículo é necessário um motivo!')
    // } else {
    try {
      await EntradaZeroKmService.rejeitarEntrada({
        chassi
      })

      hide()

      await getAll()

      showSuccessMessage('Veículo rejeitado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao rejeitar veículo!')
    }
    // }
  }

  return (
    <Modal
      header={'Rejeitar veículo ' + chassi}
      visible={visible}
      onHide={hide}
      width={40}
      footer={
        <div className="col-12 grid justify-content-end">
          <Button
            label="Confirmar"
            className="p-button-danger"
            onClick={() => handleRejeitarVeiculo()}
          />
        </div>
      }
    >
      Confirma rejeitar a entrada do veículo selecionado?
      {/* <div className="field col-12">
        <TextInput
          label="Motivo"
          value={motivoCancelamento}
          onChange={(e) => setMotivoCancelamento(e.target.value)}
          className="inputfield w-full"
        />
      </div> */}
    </Modal>
  )
}

export default RejeitarVeiculoModal
