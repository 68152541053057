export const getMensagemGlobalDTO = (data = {}) => {
  return {
    codigoMen: data.codigo_men || null,
    mensagemMen: data.mensagem_men || null,
    tipoMen: data.tipo_men || null,
    ativoMen: data.ativo_men === 1 ? true : false
  }
}

export const postMensagemGlobalDTO = (data = {}) => {
  return {
    codigo_men: data.codigoMen || 1,
    mensagem_men: data.mensagemMen || '',
    tipo_men: data.tipoMen || 'I',
    ativo_men: data.ativoMen ? 1 : 0
  }
}
