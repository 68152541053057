export const getRegistroDTO = (data = {}) => {
  return {
    empresaNomefantasia: data.empresaNomefantasia || null,
    cnpjEmp: data.cnpjEmp || null,
    usuarioNome: data.usuarioNome || null,
    usuarioTelefone: data.usuarioTelefone || null,
    usuarioEmail: data.usuarioEmail || null,
    custoTransacaoNovos: data.custoTransacaoNovos || 0,
    custoTransacaoUsados: data.custoTransacaoUsados || 0,
    isDespachante: false,
    perUsaDesp: true,
    perNovDesp: true,
    renaveZeroKm: true,
    renaveUsados: false,
    renaveITE: false,
    renaveMontadora: false,
    despachante: data.despachante || null,
    isConviteLojista: false,
    nomresfinEmp: null,
    emaresfinEmp: null,
    telresfinEmp: null,
    termoaceEmp: false,
    usuaceterEmp: null,
    dahaceterEmp: null
  }
}

export const postRegistroDTO = (data = {}) => {
  return {
    nomfan_emp: data.empresaNomefantasia || null,
    cnpj_emp: data.cnpjEmp || null,
    nome_usu: data.usuarioNome || null,
    telcom_usu: data.usuarioTelefone || null,
    email: data.usuarioEmail || null,
    custoTransacaoNovos: data.custoTransacaoNovos || 0,
    custoTransacaoUsados: data.custoTransacaoUsados || 0,
    renusa_emp: data.renaveUsados,
    rennov_emp: data.renaveZeroKm,
    renite_emp: data.renaveITE,
    renmon_emp: data.renaveMontadora,
    isConviteLojista: data.isConviteLojista,
    perusadesp_emp: data.perUsaDesp,
    pernovdesp_emp: data.perNovDesp,
    isdesp_emp: data.isDespachante,
    coddesp_emp: data.despachante?.codigo_emp || null,
    codigoEmpresas: data.codigoEmpresas,
    codigoConvite: data.codigoConvite,
    nomresfin_emp: data.nomresfinEmp || null,
    emaresfin_emp: data.emaresfinEmp || null,
    telresfin_emp: data.telresfinEmp || null,
    termoace_emp: data.termoaceEmp,
    usuaceter_emp: data.usuaceterEmp || null,
    dahaceter_emp: data.dahaceterEmp || null
  }
}
