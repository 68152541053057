import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../../classes/List'
import CheckBoxInput from '../../../components/inputs/CheckBoxInput'
import TextInput from '../../../components/inputs/TextInput'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getEntradaZeroKmDTO } from '../../../dtos/zeroKm/EntradaZeroKmDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDateTime } from '../../../helpers/formaters'
import EntradaMontadoraService from '../../../services/montadora/EntradaMontadoraService'
import ConfirmacaoErroModal from '../../zeroKm/confirmacoes/ConfirmacaoErroModal'
import ConfirmarEntradaMontadoraManualmenteModal from './ConfirmarEntradaMontadoraManualmenteModal'

class ConfirmarEntradaMontadora extends List {
  constructor (props) {
    super(
      props,
      getEntradaZeroKmDTO,
      EntradaMontadoraService,
      '',
      '',
      ''
    )

    this.state = {
      ...this.state,
      selectedRows: [],
      rowsSelecionadasParaConfirmar: [],
      visibleConfirmarEntradaMontadoraManualmenteModal: false,
      visibleCriarVeiculoMockModal: false,
      sufixoChassi: '',
      chassi: '',
      veiculoAcabado: true,
      veiculoMockAcabado: true,
      interval: null
    }
  }

  async componentDidMount () {
    await this.getAll()

    this.addEventListeners()

    const interval = setInterval(async () => {
      await this.getAll()
    }, 300000)

    this.setState({ interval })
  }

  rowClass = (data) => {
    return {
      'row-processando': data.status_processandoapi
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  async handleConfirmarEntrada () {
    if (this.state.rowsSelecionadasParaConfirmar.length > 0) {
      // console.log('lista: ', this.state.rowsSelecionadasParaConfirmar)
      try {
        await EntradaMontadoraService.confirmarEntrada(this.state.rowsSelecionadasParaConfirmar)
        await this.getAll()
        this.setState({ rowsSelecionadasParaConfirmar: [], selectedRows: [] })
        showSuccessMessage('Entrada confirmada com sucesso!')
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar confirmar a entrada do veículo!')
      }
    }
  }

  // async confirmarEntradaDeTodosOsVeiculos () {
  //   dispatch(startRenave())

  //   const logResultadoVeiculos = []

  //   for (const veiculoParaConfirmar of this.state.list) {
  //     try {
  //       await EntradaMontadoraService.confirmarEntrada(veiculoParaConfirmar.chassi, veiculoParaConfirmar.veiculoAcabado)
  //       logResultadoVeiculos.push(`Chassi ${veiculoParaConfirmar.chassi}: Cadastrado com sucesso!`)
  //     } catch (error) {
  //       const erroMensagem = error.message.replace(`Chassi: ${veiculoParaConfirmar.chassi}`, '')
  //       logResultadoVeiculos.push(`Chassi ${veiculoParaConfirmar.chassi}: ${erroMensagem || 'Ocorreu um erro inesperado e não foi possível confirmar a entrada!'}`)
  //     }
  //   }

  //   await this.getAll()
  //   this.setState({ logResultadoVeiculos, visibleLogResultadoVeiculosModal: true })
  //   dispatch(stopRenave())
  // }

  handleSelectionChange = (e) => {
    const quantidadeSelecionaveis = this.state.list.filter(data => !data.status_processandoapi).length
    let selected = e.value.filter(data => !data.status_processandoapi)

    if (selected.length === this.state.selectedRows.length) {
      selected = []
    }

    if (selected.length === 0) {
      e.value = []
    }

    if (selected.length === quantidadeSelecionaveis) {
      e.value = this.state.list
    }

    this.setState({ selectedRows: e.value, rowsSelecionadasParaConfirmar: selected })
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Confirmação de entrada</h4>
          <div className="page-header-buttons">
            {CommonHelper.isUsuarioAdmin() && (
              <Button
                label="Criar veículo mock"
                onClick={() => this.setState({ visibleCriarVeiculoMockModal: true })}
                className="mr-2"
              />
            )}
            <Button
              label="Confirmar entrada manualmente"
              onClick={() => this.setState({ visibleConfirmarEntradaMontadoraManualmenteModal: true })}
              className="mr-2"
            />
            {/* <Button
              label="Confirmar entrada de todos"
              onClick={async () => await this.confirmarEntradaDeTodosOsVeiculos()}
              className="mr-2"
            /> */}
            <Button
              icon="pi pi-refresh"
              onClick={() => this.getAll()}
              className="mr-5"
            />
          </div>
        </div>
        <Container>
          <DataList
            className="selection-datalist"
            data={this.state.list}
            selected={this.state.selectedRows}
            onSelectionChange={e => this.handleSelectionChange(e)}
            dataKey="chassi"
            responsiveLayout="scroll"
            responsive={true}
            rowClassName={this.rowClass}
            selectionMode="checkbox"
          >
            <Column
              style={{ width: '5%' }}
              selectionMode="multiple"
            />
            <Column
              header="Chassi"
              field="chassi"
              body={data => data.chassi}
              style={{ width: '25%', maxWidth: '25%' }}
              sortable
              headerClassName="chassi"
            />
            <Column
              header="Inacabado?"
              field="veiculoAcabado"
              body={data => <ResponsiveColumn
                column="veiculoAcabado"
                value={data.veiculoAcabado ? 'Acabado' : 'Inacabado'}
                className={data.veiculoAcabado ? 'colored-label blue' : 'colored-label orange'}
              />}
              style={{ width: '25%', maxWidth: '25%' }}
              sortable
              headerClassName="veiculoAcabado"
            />
            <Column
              header="Data/Hora Cadastro BIN"
              field="dataHoraCadastro"
              body={data => data.dataHoraCadastro ? formatDateTime(data.dataHoraCadastro) : ''}
              style={{ width: '25%', maxWidth: '25%' }}
              sortable
              headerClassName="dataHoraCadastro"
            />
            <Column
              header="Info"
              field="info"
              body={data => {
                if (data.status_processandoapi) {
                  return (
                    <i className="pi pi-spin pi-spinner info-selection"/>
                  )
                }

                if (data.erromensagem) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleConfirmacaoErroModal: true })}
                    />
                  )
                }
              }}
              style={{ width: '10%', maxWidth: '7%' }}
            />
            {/* <Column
              header="Ações"
              body={(data) =>
                <Button
                  label="Confirmar entrada"
                  onClick={async () => await this.handleConfirmarEntrada(data.chassi, data.veiculoAcabado)}
                />
              }
              style={{ width: '25%', maxWidth: '25%' }}
            /> */}
          </DataList>
          <div className="table-footer">
            <div className="grid col-12 justify-content-end">
              <Button
                label="Confirmar entradas selecionadas"
                className="mr-2"
                onClick={() => this.handleConfirmarEntrada()}
              />
            </div>
          </div>
        </Container>
        <ConfirmacaoErroModal
          visible={this.state.visibleConfirmacaoErroModal}
          onHide={() => this.setState({ visibleConfirmacaoErroModal: false })}
          mensagem={this.state.veiculoSelecionado?.erromensagem || this.state.veiculoSelecionado?.errotitulo}
        />
        <ConfirmarEntradaMontadoraManualmenteModal
          visible={this.state.visibleConfirmarEntradaMontadoraManualmenteModal}
          onHide={() => this.setState({ visibleConfirmarEntradaMontadoraManualmenteModal: false })}
        />
        <Modal
          header="Criar veículo mock"
          width={45}
          onHide={() => this.setState({ visibleCriarVeiculoMockModal: false })}
          visible={state.visibleCriarVeiculoMockModal}
          footer={
            <Button
              label="Confirmar"
              onClick={async () => {
                await EntradaMontadoraService.criarVeiculoMock(state.sufixoChassi, state.veiculoMockAcabado)
                this.setState({ visibleCriarVeiculoMockModal: false, sufixoChassi: '', veiculoMockAcabado: true })
                await this.getAll()
              }}
            />
          }
        >
          <div className="p-grid p-fluid">
            <div className='field col-12'>
              <TextInput
                label="Sufixo chassi (últimos 4 digitos do chassi)"
                value={state.sufixoChassi}
                onChange={(e) => this.setState({ sufixoChassi: e.target.value })}
              />
            </div>
            <div className="field col-12">
              <CheckBoxInput
                label="Veículo acabado?"
                value={state.veiculoMockAcabado}
                checked={state.veiculoMockAcabado}
                onChange={() => this.setState({ veiculoMockAcabado: !state.veiculoMockAcabado })}
              />
            </div>
          </div>
        </Modal>
      </Page>
    )
  }
}

export default ConfirmarEntradaMontadora
