import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import Confirm from '../../../components/utils/Confirm'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getVeiculoEntradaDTO } from '../../../dtos/renave/VeiculoEntradaDTO'
import { formatDate, formatDescricaoVeiculo, formatToCurrency } from '../../../helpers/formaters'
import IteService from '../../../services/ITE/IteService'
import VeiculoConfirmadoITEService from '../../../services/ITE/VeiculoConfirmadoITEService'
import TransferenciaNaITEModal from './TransferenciaNaITEModal'

export class TransferenciaNaITE extends List {
  constructor (props) {
    super(
      props,
      getVeiculoEntradaDTO,
      VeiculoConfirmadoITEService,
      '',
      '',
      'estoqueRenave'
    )

    this.state = {
      ...this.state,
      veiculoSelecionado: {},
      visibleTransferenciaNaITEModal: false
    }
  }

  handleCancelarAutorizacao = async () => {
    try {
      await IteService.cancelarAutorizacaoTransferencia(this.state.veiculoSelecionado.idAutorizacaoEstabelecimento, this.state.veiculoSelecionado.chassi)

      this.setState({ visibleConfirm: false })

      await this.getAll()

      showSuccessMessage('Cancelamento de autorização de transferência concluído!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar autorização de transferência!')
    }
  }

  render () {
    const state = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          {data.idAutorizacaoEstabelecimento && (
            <Button
              label="Cancelar Autorização"
              className="expansion-button p-button-danger"
              onClick={() => this.setState({ veiculoSelecionado: data, visibleConfirm: true })}
            />
          )}
          {!data.idAutorizacaoEstabelecimento && (
            <Button
              label="Autorizar"
              className="expansion-button"
              onClick={() => this.setState({ veiculoSelecionado: data, visibleTransferenciaNaITEModal: true })}
            />
          )}
        </div>
      )
    }

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'estoqueRenave':
        filter.apenasAutorizadosEstabelecimento = null
        break
      case 'autorizadosEstabelecimento':
        filter.apenasAutorizadosEstabelecimento = true
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Transferir veículo</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'estoqueRenave' }, 'filter-option')}
              onClick={() => onSelectOption('estoqueRenave')}>
              <span className="option-label">Estoque Renave</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeTotal}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'autorizadosEstabelecimento' }, 'filter-option')}
              onClick={() => onSelectOption('autorizadosEstabelecimento')}>
              <span className="option-label">Autorizados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeAutorizadosEstabelecimento}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column
              style={{ width: '45%' }}
              header="Veículo"
              body={data => formatDescricaoVeiculo(data.chassi, data.marcaModelo)}
            />
            <Column
              style={{ width: '10%' }}
              header="Acabado"
              body={data => <ResponsiveColumn
                column="veiculoAcabado"
                value={data.veiculoAcabado === 0 ? 'Inacabado' : 'Acabado'}
                className={data.veiculoAcabado === 0 ? 'colored-label orange' : 'colored-label blue'}
              />}
              sortable
            />
            <Column
              style={{ width: '10%' }}
              header="Fab/Mod"
              body={(data) => data.anoFab ? (data.anoFab + '/' + data.anoMod) : ''}
            />
            <Column
              style={{ width: '10%' }}
              header="Valor"
              body={(data) => data.valorVeiculo ? formatToCurrency(data.valorVeiculo) : ''}
            />
            <Column
              style={{ width: '25%' }}
              header="Autorizado Para"
              body={(data) => data.nomeEstabelecimento || 'Não realizada autorização'}
            />
            <Column
              style={{ width: '10%' }}
              header="Autorizado Em"
              body={(data) => data.dataAutorizacaoEstabelecimento ? formatDate(data.dataAutorizacaoEstabelecimento) : ''}
            />
          </DataList>
        </Container>
        <TransferenciaNaITEModal
          visible={state.visibleTransferenciaNaITEModal}
          onHide={() => this.setState({ visibleTransferenciaNaITEModal: false })}
          veiculoParaTransferir={state.veiculoSelecionado}
          getAll={this.getAll}
        />
        <Confirm
          title="Cancelar autorização"
          visible={this.state.visibleConfirm}
          onCancel={() => this.setState({ visibleConfirm: false })}
          onConfirm={() => this.handleCancelarAutorizacao()}
          description="Confirma cancelar a autorização?"
          cancelLabel="Não"
        />
      </Page>
    )
  }
}
