export const CLEAR_STORE = 'app/sances/CLEAR_STORE'
export const START_LOADING = 'app/sances/START_LOADING'
export const STOP_LOADING = 'app/sances/STOP_LOADING'
export const START_RENAVE = 'app/sances/START_RENAVE'
export const STOP_RENAVE = 'app/sances/STOP_RENAVE'
export const SET_USUARIO_LOGADO = 'app/sances/SET_USUARIO_LOGADO'
export const SET_EMPRESA_LOGADA = 'app/sances/SET_EMPRESA_LOGADA'
export const SET_MENSAGEM_GLOBAL = 'app/sances/SET_MENSAGEM_GLOBAL'
export const SET_CERTIFICADO_VALIDO_VENDA_VEICULOS = 'app/sances/SET_CERTIFICADO_VALIDO_VENDA_VEICULOS'
export const SET_CERTIFICADO_VALIDO_ITE = 'app/sances/SET_CERTIFICADO_VALIDO_ITE'
export const SET_CERTIFICADO_VALIDO_MONTADORA = 'app/sances/SET_CERTIFICADO_VALIDO_MONTADORA'
