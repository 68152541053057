import React, { useState } from 'react'
import PanelContainer from '../../../components/layout/PanelContainer'
import { TabMenu } from 'primereact/tabmenu'
import SolicitacaoDeEntrada from './SolicitacaoDeEntrada'
import AssinarAtpve from './AssinarAtpve'

function EntradaVeiculoUsado (props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [veiculo, setVeiculo] = useState(null)

  function isEntradaVeiculoProprio () {
    return props.match.params.tipoEntrada === 'veiculo-proprio'
  }

  function headerTemplate () {
    const model = [{ label: 'SOLICITAÇÃO DE ENTRADA' }]

    if (!isEntradaVeiculoProprio()) {
      model.push({ label: 'ATPV-E ASSINADO' })
    }
    // teste
    return <TabMenu
      model={model}
      activeIndex={activeIndex}
      onTabChange={() => console.log('')}
    />
  }

  return (
    <>
      <h4 className="mt-3 mb-0">
        {isEntradaVeiculoProprio() ? 'Entrada de veículo próprio' : 'Compra de veículo'}
      </h4>
      <PanelContainer toggeable={false} headerTemplate={headerTemplate()}>
        {activeIndex === 0 && (
          <SolicitacaoDeEntrada
            tipoEntrada={props.match.params.tipoEntrada}
            setActiveIndex={setActiveIndex}
            veiculo={veiculo}
            setVeiculo={setVeiculo}
          />
        )}
        {activeIndex === 1 && (
          <AssinarAtpve
            veiculo={veiculo}
          />
        )}
      </PanelContainer>
    </>
  )
}

export default EntradaVeiculoUsado
