import ServiceBase from '../../base/ServiceBase'

export default class HistoricoSaldoDetranService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`historicoSaldoDetran?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`historicoSaldoDetran?${query}&page=${page}&perPage=${perPage}`)
  }
}
