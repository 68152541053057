import React, { useState } from 'react'
import Modal from '../../../../components/utils/Modal'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import TextInput from '../../../../components/inputs/TextInput'
import { formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import AutorizacaoTransferenciaService from '../../../../services/veiculosUsados/AutorizacaoTransferenciaService'

function CancelarAutorizacaoTransfrenciaModal ({ visible, onHide, placaVeiculo, cnpjEstabelecimentoDestino, handleRowExpansion }) {
  const [valorVenda, setValorVenda] = useState(0)

  function hideModal () {
    setValorVenda(0)
    onHide()
  }

  async function cancelarAutorizacaoTransferencia () {
    try {
      if (valorVenda < 0.1) {
        showWarnMessage('Valor de venda deve ser superior a 1 centavo!')
        return
      }

      await AutorizacaoTransferenciaService.cancelarAutorizacaoTransferencia(valorVenda, placaVeiculo, cnpjEstabelecimentoDestino)

      hideModal()
      await handleRowExpansion({})

      showSuccessMessage('Autorização de transferência cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a autorização de transferência!')
    }
  }

  return (
    <Modal
      visible={visible}
      onHide={hideModal}
      header="Cancelar autorização de transferência"
      footer={
        <Button
          label="Enviar solicitação de cancelamento"
          onClick={() => cancelarAutorizacaoTransferencia()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-6">
          <TextInput
            label="Valor de venda"
            placeholder="Valor de venda do veículo"
            value={formatToCurrency(valorVenda)}
            onChange={(e) => setValorVenda(formatCurrencyToDecimal(e.target.value))}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default CancelarAutorizacaoTransfrenciaModal
