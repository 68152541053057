import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class AutorizacaoTransferenciaService {
  static async autorizarTransferencia (valorVenda, placaVeiculo, cnpjEstabelecimentoDestino) {
    return await ServiceBase.postBase('autorizacoes-transferencias/', { placaVeiculo, valorVenda, cnpjEstabelecimentoDestino }, ComunicaRenave)
  }

  static async cancelarAutorizacaoTransferencia (valorVenda, placaVeiculo, cnpjEstabelecimentoDestino) {
    return await ServiceBase.postBase('autorizacoes-transferencias/cancelamento', { placaVeiculo, valorVenda, cnpjEstabelecimentoDestino }, ComunicaRenave)
  }

  static async getAutorizacaoVeiculo (placa) {
    return await ServiceBase.getBase(`autorizacoes-transferencias/${placa}`, ComunicaRenave)
  }

  static async receberTransferenciaEstabelecimento (
    dataTransferenciaEstoque,
    veiculo,
    cnpjEstabelecimentoOrigemTransferencia
  ) {
    const requestBody = {
      veiculo,
      dataTransferenciaEstoque,
      cnpjEstabelecimentoOrigemTransferencia
    }

    return await ServiceBase.postBase('autorizacoes-transferencias/recebimento', requestBody, ComunicaRenave)
  }
}
