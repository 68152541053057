import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class AtpvService {
  static async getAtpvUsado (placa, renavam) {
    return await ServiceBase.postBase('atpv/downloadAtpvUsado', { placa, renavam }, ComunicaRenave)
  }

  static async getAtpvNovo (chassi) {
    return await ServiceBase.postBase('atpv/downloadAtpvNovo', { chassi }, ComunicaRenave)
  }

  static async atualizarAtpv (pdfAtpvBase64) {
    return await ServiceBase.postBase('atualizar-atpve', { pdfAtpvBase64 })
  }

  static async enviarAtpvEmail (chassi, isMontadora) {
    return await ServiceBase.postBase('atpv/enviarAtpvEmail', { chassi, isMontadora })
  }

  static async enviarAssinatura (idEstoque, pdfAtpvBase64, p7sBase64, tipoCrv) {
    return await ServiceBase.postBase('atpv/enviarAssinatura', { idEstoque, pdfAtpvBase64, p7sBase64, tipoCrv })
  }
}
