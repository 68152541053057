import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class VeiculosTransferenciaService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`transferenciaZeroKm?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`transferenciaZeroKm?${query}&page=${page}&perPage=${perPage}`)
  }

  static async transferirVeiculoParaFilial (codigoItemChassi) {
    return await ServiceBase.postBase('transferenciaZeroKm/transferirParaFilial', { codigoItemChassi }, ComunicaRenave)
  }

  static async autorizarTransferenciaParaEstabelecimento (cnpjEstabelecimento, idEstoque, valorVenda, cancelamentoDeTransferencia) {
    return await ServiceBase.postBase('transferenciaZeroKm/autorizarTransferenciaParaEstabelecimento', {
      cnpjEstabelecimento,
      idEstoque,
      valorVenda,
      cancelamentoDeTransferencia
    }, ComunicaRenave)
  }

  static async cancelarAutorizacaoTransferenciaParaEstabelecimento (idAutorizacaoEstabelecimento) {
    return await ServiceBase.postBase('transferenciaZeroKm/cancelarAutorizacaoTransferenciaParaEstabelecimento', { idAutorizacaoEstabelecimento }, ComunicaRenave)
  }
}
