import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import Modal from '../utils/Modal'
import { Button } from 'primereact/button'
import { ImWarning } from 'react-icons/im'

class AppSubmenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: null
    }
  }

  onMenuItemClick (event, item, index) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault()
      return true
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item })
    }

    if (index === this.state.activeIndex)
      this.setState({ activeIndex: null })
    else
      this.setState({ activeIndex: index })

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item
      })
    }
  }

  renderLinkContent (item) {
    let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>
    let subItem = item.subItem ? ' ml-5' : ''

    return (
      <>
        <i className={item.icon + subItem}>
          {item.reactIcon && (
            <item.reactIcon />
          )}
          {item.iconJSX && (
            <>
              {item.iconJSX}
            </>
          )}
        </i>
        <span className="menuitem-label">{item.label}</span>
        {submenuIcon}
        {badge}
      </>
    )
  }

  renderLink (item, i) {
    let content = this.renderLinkContent(item)
    if (item.to) {
      if (
        (item.renaveVendaVeiculos && !this.props.certificadoValidoVendaVeiculos) ||
        (item.renaveIte && !this.props.certificadoValidoIte) ||
        (item.renaveMontadora && !this.props.certificadoValidoMontadora)
      ) {
        return (
          <a onClick={(e) => this.props.openCertificadoModal()}>
            {content}
          </a>
        )
      } else {
        return (
          <NavLink activeClassName="active-route" to={item.to} onClick={(e) => this.onMenuItemClick(e, item, i)} exact target={item.target}>
            {content}
          </NavLink>
        )
      }
    } else {
      return (
        <a href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}>
          {content}
        </a>
      )
    }
  }

  render () {
    let items = this.props.items && this.props.items.map((item, i) => {
      let active = this.state.activeIndex === i
      let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active && !item.to })

      return (
        <>
          <li className={styleClass} key={i}>
            {item.items && this.props.root === true && <div className='arrow'></div>}
            {this.renderLink(item, i)}
            <AppSubmenu
              items={item.items}
              onMenuItemClick={this.props.onMenuItemClick}
              openCertificadoModal={this.props.openCertificadoModal}
              certificadoValidoVendaVeiculos={this.props.certificadoValidoVendaVeiculos}
              certificadoValidoIte={this.props.certificadoValidoIte}
              certificadoValidoMontadora={this.props.certificadoValidoMontadora}
            />
          </li>
        </>
      )
    })

    return items ? <ul className={this.props.className}>{items}</ul> : null
  }
}

export class AppMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleCertificadoModal: false
    }

    this.openCertificadoModal = this.openCertificadoModal.bind(this)
  }

  openCertificadoModal () {
    this.setState({ visibleCertificadoModal: true })
  }

  render () {
    return (
      <div className="layout-menu-container">
        <AppSubmenu
          items={this.props.model}
          className="layout-menu"
          onMenuItemClick={this.props.onMenuItemClick}
          root={true}
          openCertificadoModal={this.openCertificadoModal}
          certificadoValidoVendaVeiculos={this.props.certificadoValidoVendaVeiculos}
          certificadoValidoIte={this.props.certificadoValidoIte}
          certificadoValidoMontadora={this.props.certificadoValidoMontadora}
        />
        <Modal
          header="Certificado digital não importado ou vencido"
          visible={this.state.visibleCertificadoModal}
          focusOnShow={false}
          closeOnEscape={false}
          closable={false}
          width={40}
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => this.setState({ visibleCertificadoModal: false })}
              autoFocus={true} />
          }
        >
          <div className="formgrid grid">
            <div className="field col-2 warning-certificado-container">
              <ImWarning className="warning-certificado"></ImWarning>
            </div>
            <div className="field col-10">
            Para atualizar o certificado, acesse o menu de usuário e clique na opção "<strong>Certificado Digital</strong>"
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
