import React from 'react'
import CommonHelper from '../../helpers/CommonHelper'
import ConviteLojistaList from '../conviteLojista/ConviteLojistaList'

const Home = () => {
  return CommonHelper.isEmpresaDespachante() ? (
    <ConviteLojistaList/>
  ) : (
    <></>
  )
}

export default Home
