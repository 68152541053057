import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../../classes/List'
import DropdownMenu from '../../../components/inputs/DropdownButton/DropdownMenu'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getSaidaMontadoraDTO } from '../../../dtos/montadora/SaidaMontadoraDTO'
import { formatDate, formatDescricaoVeiculo, formatToCurrency } from '../../../helpers/formaters'
import SaidaMontadoraService from '../../../services/montadora/SaidaMontadoraService'
import ConfirmacaoErroModal from '../../zeroKm/confirmacoes/ConfirmacaoErroModal'
import ConfirmacaoOptionsModal from '../../zeroKm/confirmacoes/ConfirmacaoOptionsModal'
import TrocarVeiculoModal from '../../zeroKm/confirmacoes/TrocarVeiculoModal'
import SaidaMontadoraModal from './SaidaMontadoraModal'

class ConfirmarSaidaMontadora extends List {
  constructor (props) {
    super(
      props,
      getSaidaMontadoraDTO,
      SaidaMontadoraService
    )

    this.state = {
      ...this.state,
      selectedRows: [],
      rowsSelecionadasParaConfirmar: [],
      visibleSaidaEstoqueMontadoraModal: false,
      visibleTrocarVeiculoModal: false,
      visibleOptionModal: false,
      visibleConfirmacaoErroModal: false,
      veiculoSelecionado: {},
      idEstoque: 0,
      interval: null,
      itensAcoes: []
    }
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()

    const interval = setInterval(() => {
      this.getAll()
    }, 3000000)

    const itensAcoes = [
      {
        label: 'Confirmar saída',
        command: (data) => {
          this.setState({ rowsSelecionadasParaConfirmar: [{ ...data }], visibleSaidaEstoqueMontadoraModal: true, isManual: true })
        }
      }
    ]

    this.setState({ interval, itensAcoes })
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  rowClass = (data) => {
    return {
      'row-sem-xml-saida': !data.numeronf,
      'row-processando': data.processandoapi
    }
  }

  handleConfirmarSaida = async (isManual) => {
    if (this.state.rowsSelecionadasParaConfirmar.length > 0) {
      try {
        await this.setState({ visibleSaidaEstoqueMontadoraModal: true, isManual: false })
      } catch (error) {
        showErrorMessage(error.message || 'Houve um erro ao confirmar a(s) saída(s)')
      }
    }
  }

  handleSelectionChange = (e) => {
    const quantidadeSelecionaveis = this.state.list.filter(data => data.numeronf && !data.processandoapi).length
    let selected = e.value.filter(data => data.numeronf && !data.processandoapi)
    // let selected = e.value
    if (selected.length === this.state.selectedRows.length) {
      selected = []
    }

    if (selected.length === 0) {
      e.value = []
    }

    if (selected.length === quantidadeSelecionaveis) {
      e.value = this.state.list
    }

    this.setState({ selectedRows: e.value, rowsSelecionadasParaConfirmar: selected })
  }

  render () {
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Confirmação de saída</h4>
          <div className="page-header-buttons">
            <Button
              icon="pi pi-refresh"
              onClick={() => this.onFilter()}
              className="mr-5"
            />
          </div>
        </div>
        <Container>
          <DataList
            className="selection-datalist"
            data={this.state.list}
            selected={this.state.selectedRows}
            onSelectionChange={e => this.handleSelectionChange(e)}
            dataKey="chassi"
            responsiveLayout="scroll"
            responsive={true}
            rowClassName={this.rowClass}
            selectionMode="checkbox"
          >
            <Column
              style={{ width: '5%' }}
              selectionMode="multiple"
            />
            <Column
              header="NF"
              field="numeronf"
              style={{ width: '8%', maxWidth: '8%' }}
              sortable
              headerClassName="numeronf"
            />
            <Column
              header="Série"
              field="serie"
              style={{ width: '5%', maxWidth: '5%' }}
              sortable
              headerClassName="serie"
            />
            <Column
              header="Veículo"
              field="chassi"
              body={data => {
                const marcaModelo = data.marcamodelo || data.marcaModelo
                return formatDescricaoVeiculo(data.chassi, marcaModelo)
              }}
              style={{ width: '25%', maxWidth: '25%' }}
              sortable
              headerClassName="chassi"
            />
            <Column
              header="Inacabado?"
              field="acabado_vei"
              body={data => <ResponsiveColumn
                column="acabado_vei"
                value={data.acabado_vei === 0 || data.inacabado ? 'Inacabado' : 'Acabado'}
                className={data.acabado_vei === 0 || data.inacabado ? 'colored-label orange' : 'colored-label blue'}
              />}
              style={{ width: '7%', maxWidth: '7%' }}
              sortable
              headerClassName="acabado_vei"
            />
            <Column
              header="Cliente"
              field="nome"
              body={data => <ResponsiveColumn
                column="nome"
                value={data.nome ? data.nome : 'Estoque renave'}
                className={!data.nome ? 'colored-label green' : ''}
              />}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="nome"
            />
            <Column
              header="Data Emissão"
              field="dataemissao"
              body={data => data.dataemissao ? formatDate(data.dataemissao) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="dataemissao"
            />
            <Column
              header="Valor Total"
              field="valorTotal"
              body={data => data.valorentrada ? formatToCurrency(data.valorentrada) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="valorTotal"
            />
            <Column
              header="Info"
              field="info"
              body={data => {
                if (data.processandoapi) {
                  return (
                    <i className="pi pi-spin pi-spinner info-selection"/>
                  )
                }

                if ((data.outrasNotas && data.outrasNotas.length > 0) && (data.erromensagem || data.errodetalhe)) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleOptionModal: true })}
                    />
                  )
                }

                if (data.outrasNotas && data.outrasNotas.length > 0) {
                  return (
                    <i
                      className="pi pi-info-circle info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleTrocarVeiculoModal: true })}
                    />
                  )
                }

                if (data.erromensagem || data.errodetalhe) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleConfirmacaoErroModal: true })}
                    />
                  )
                }
              }}
              style={{ width: '7%', maxWidth: '7%' }}
            />
            <Column
              header="Ações"
              body={(data) => {
                if (!data.codigo) {
                  return <DropdownMenu
                    items={this.state.itensAcoes}
                    icon="pi pi-ellipsis-v"
                    data={data}
                    onlyIcon
                  />
                }
              }}
              style={{ width: '25%', maxWidth: '25%' }}
            />
          </DataList>
          <div className="table-footer">
            <div className="grid col-12 justify-content-end">
              <Button
                label="Confirmar saídas selecionadas"
                className="mr-2"
                onClick={() => this.handleConfirmarSaida(false)}
              />
            </div>
          </div>
        </Container>
        <ConfirmacaoErroModal
          visible={this.state.visibleConfirmacaoErroModal}
          onHide={() => this.setState({ visibleConfirmacaoErroModal: false })}
          mensagem={this.state.veiculoSelecionado.erromensagem || this.state.veiculoSelecionado.errodetalhe}
        />
        <ConfirmacaoOptionsModal
          visible={this.state.visibleOptionModal}
          onHide={() => this.setState({ visibleOptionModal: false })}
          openTrocarNotaModal={() => this.setState({ visibleTrocarVeiculoModal: true })}
          openConfirmacaoErroModal={() => this.setState({ visibleConfirmacaoErroModal: true })}
        />
        <TrocarVeiculoModal
          visible={this.state.visibleTrocarVeiculoModal}
          onHide={() => this.setState({ visibleTrocarVeiculoModal: false })}
          veiculo={this.state.veiculoSelecionado}
          getAll={this.getAll}
        />
        <SaidaMontadoraModal
          visible={this.state.visibleSaidaEstoqueMontadoraModal}
          onHide={() => this.setState({ visibleSaidaEstoqueMontadoraModal: false, rowsSelecionadasParaConfirmar: [], selectedRows: [] })}
          veiculos={this.state.rowsSelecionadasParaConfirmar}
          getAll={this.getAll}
          isManual={this.state.isManual}
        />
      </Page>
    )
  }
}

export default ConfirmarSaidaMontadora
