import React, { useEffect } from 'react'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import PessoaService from '../../../services/cadastro/PessoaService'
import DadosPrincipaisPes from './DadosPrincipaisPes'
import { PessoaValidator } from './PessoaValidator'

function PessoaModal ({ hide, visible, onRegister, primaryKey, edicaoHabilitada }) {
  async function handleSubmit () {
    try {
      const errors = await form.validateForm(form.values)

      if (Object.keys(errors).length === 0) {
        const pessoa = await PessoaService.save(form.values)
        await onRegister(pessoa)
        hide()
      }
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  const form = baseForm({
    initialValues: getPessoaDTO(),
    onSubmit: handleSubmit,
    validationSchema: PessoaValidator
  })

  useEffect(async () => {
    if (primaryKey) {
      const pessoaService = new PessoaService()
      try {
        const pessoa = await pessoaService.get(primaryKey)
        form.setValues(getPessoaDTO(pessoa))
      } catch (e) {
        showErrorMessage(e.message || 'Erro ao buscar os dados do registro')
      }
    } else {
      form.setValues(getPessoaDTO())
    }
  }, [visible, primaryKey])

  return (
    <Modal
      onHide={hide}
      header={primaryKey ? 'Pessoa - ' + primaryKey : 'Cadastro de Pessoa'}
      visible={visible}
      width="70"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      modal
    >
      <form onSubmit={form.handleSubmit}>
        <DadosPrincipaisPes edicaoHabilitada={edicaoHabilitada} form={form} />
      </form>
    </Modal>
  )
}

export default PessoaModal
