export const getLoteXmlDTO = (data = {}) => {
  return {
    idLote: data.codigo,
    codigoEmp: data.codigoempresa,
    codigoUsu: data.codigousuario,
    dataHoraImportacao: data.datahoraimportacao,
    totalArquivos: data.totalarquivos,
    totalEntradas: data.totalentradas,
    totalSaidas: data.totalsaidas,
    totalDuplicados: data.totalduplicados,
    totalDescartados: data.totaldescartados,
    finalizado: data.finalizado
  }
}
