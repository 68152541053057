import { server } from '../../../helpers/server'
import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class SaidaZeroKmService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`saidaZeroKm/retornaListaSaida?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`saidaZeroKm/retornaListaSaida?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async cancelarSaidaZeroKm (data) {
    return await ServiceBase.postBase('saidaZeroKm/cancelarSaida', data, ComunicaRenave)
  }

  static async confirmarSaida (listaSaidas) {
    try {
      const itensList = listaSaidas.map((saida) => ({ codigo: saida.codigo, leasing: saida.leasing }))
      const response = await server().post('saidaZeroKm/confirmarSaida', { itensList }, ComunicaRenave)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async devolverSaida (data) {
    return await ServiceBase.postBase('saidaZeroKm/devolverSaida', data, ComunicaRenave)
  }
}
