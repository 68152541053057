import React, { useEffect } from 'react'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { getEmpresaDTO } from '../../../dtos/cadastro/EmpresaDTO'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import DadosPrincipaisEmp from './DadosPrincipaisEmp'
import { EmpresaValidator } from './EmpresaValidator'

function EmpresaModal ({ hide, visible, onRegister, primaryKey, onlyVisualizacao, edicaoHabilitada }) {
  async function handleSubmit () {
    try {
      const errors = await form.validateForm(form.values)

      if (Object.keys(errors).length === 0) {
        const empresa = await EmpresaService.save(form.values)
        await onRegister(empresa)
        hide()
      }
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  const form = baseForm({
    initialValues: getEmpresaDTO(),
    onSubmit: handleSubmit,
    validationSchema: EmpresaValidator
  })

  useEffect(async () => {
    if (primaryKey) {
      const empresaService = new EmpresaService()
      try {
        const empresa = await empresaService.get(primaryKey)
        form.setValues(getEmpresaDTO(empresa))
      } catch (e) {
        showErrorMessage(e.message || 'Erro ao buscar os dados do registro')
      }
    } else {
      form.setValues(getEmpresaDTO())
    }
  }, [visible, primaryKey])

  return (
    <Modal
      onHide={hide}
      header={primaryKey ? 'Empresa - ' + primaryKey : 'Cadastro de Empresa'}
      visible={visible}
      width="70"
      btnSalvar={edicaoHabilitada && !onlyVisualizacao ? form.submitForm : undefined}
      modal
    >
      <form onSubmit={form.handleSubmit}>
        <DadosPrincipaisEmp edicaoHabilitada={edicaoHabilitada && !onlyVisualizacao} form={form} />
      </form>
    </Modal>
  )
}

export default EmpresaModal
