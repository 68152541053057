import Modal from '../../../components/utils/Modal'
import React, { useState, useEffect } from 'react'
import { Fieldset } from 'primereact/fieldset'
import SearchInput from '../../../components/inputs/SearchInput'
import { getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import PessoaService from '../../../services/cadastro/PessoaService'
import { formatCpfOrCnpj, formatDate, formatToCurrency } from '../../../helpers/formaters'
import PessoaModal from '../../../forms/cadastro/pessoa/PessoaModal'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import SelectInput from '../../../components/inputs/SelectInput'
import SaidaMontadoraService from '../../../services/montadora/SaidaMontadoraService'
import Confirm from '../../../components/utils/Confirm'
import XmlImportService from '../../../services/zeroKm/xml/XmlImportService'
import { getXmlImportDTO } from '../../../dtos/xml/XmlImportDTO'
import TextInput from '../../../components/inputs/TextInput'
import { Checkbox } from 'primereact/checkbox'
// import { classNames } from 'primereact/utils'

function SaidaMontadoraModal ({ visible, onHide, getAll, veiculos, isManual }) {
  const [xml, setXml] = useState({})
  const [concessionariaVendedora, setConcessionariaVendedora] = useState({})
  const [sugestaoXml, setSugestaoXml] = useState([])
  const [sugestaoConcessionariasVendedoras, setSugestaoConcessionariasVendedoras] = useState([])
  const [concessionariaEntrega, setConcessionariaEntrega] = useState({})
  const [sugestaoConcessionariasEntregas, setSugestaoConcessionariasEntregas] = useState([])
  const [visibleConfirm, setVisibleConfirm] = useState([])
  const [visibleChassiNaoEncontrado, setVisibleChassiNaoEncontrado] = useState([])

  const [beneficioFiscal, setBeneficioFiscal] = useState({ descri_bfi: 'Não possui', codigo_bfi: 'NAO_TEM' })
  const [leasing, setLeasing] = useState(false)
  const [beneficiosFiscais, setBeneficiosFiscais] = useState([])

  function hideModal () {
    setXml({})
    setSugestaoXml([])
    setBeneficioFiscal({ descri_bfi: 'Não possui', codigo_bfi: 'NAO_TEM' })
    setConcessionariaEntrega({})
    setConcessionariaVendedora({})
    setSugestaoConcessionariasEntregas([])
    setSugestaoConcessionariasVendedoras([])
    setLeasing(false)
    onHide()
  }

  useEffect(() => {
    getAllBeneficioFiscal()
    setVisibleConfirm(false)
    setVisibleChassiNaoEncontrado(false)
    if (visible) {
      if (veiculos.length === 1) {
        let veiculo = veiculos[0]
        if (veiculo.beneficiofiscal) {
          setBeneficioFiscal({ codigo_bfi: veiculo.beneficiofiscal })
        }
        if (veiculo.cnpjvendedora) {
          setConcessionariaVendedoraByCnpj(veiculo.cnpjvendedora)
        }
        if (veiculo.cnpjentregadora) {
          setConcessionariaEntregadoraByCnpj(veiculo.cnpjentregadora)
        }
        if (veiculo.codigo) {
          setXmlByCodigo(veiculo.codigo)
        }
        if (veiculo.leasing) {
          setLeasing(veiculo.leasing)
        }
      }
    }
  }, [visible])

  async function getAllBeneficioFiscal () {
    let benefs = await SaidaMontadoraService.getAllBeneficioFiscal()
    setBeneficiosFiscais(benefs)
  }
  async function setXmlByCodigo (codigoxmlimport) {
    const xmlImportService = new XmlImportService()
    const xmlImportItem = await xmlImportService.getXmlItem(codigoxmlimport)
    // console.log(xmlImportItem)
    setXml(xmlImportItem)
  }

  async function setConcessionariaVendedoraByCnpj (cnpj) {
    const pessoaService = new PessoaService()
    const concessionariaVendedora = (await pessoaService.filter(`descricaoFiltro=${cnpj}`)).data
    // console.log('concessionariaVendedora: ', concessionariaVendedora[0])
    setConcessionariaVendedora(concessionariaVendedora[0])
  }

  async function setConcessionariaEntregadoraByCnpj (cnpj) {
    const pessoaService = new PessoaService()
    const concessionariaEntregadora = (await pessoaService.filter(`descricaoFiltro=${cnpj}`)).data
    // console.log('concessionariaEntregadora: ', concessionariaEntregadora[0])
    setConcessionariaEntrega(concessionariaEntregadora[0])
  }

  async function sugerirXml () {
    try {
      // console.log('Entrou aqui no sugerir XML: ', xml)
      const xmlService = new XmlImportService()

      const xmls = (await xmlService.filter(`descricaoFiltro=${xml.numeronf}&filtroErroXml=true&filtroProcessadoApi=true&filtroEmpresaLogada=true&filtroSomenteSaida=true`))

      setSugestaoXml(xmls)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um xml!')
    }
  }

  async function sugerirConcessionariasVendedoras () {
    try {
      const pessoaService = new PessoaService()

      const concessionarias = (await pessoaService.filter(`descricaoFiltro=${concessionariaVendedora.nomraz_pes}&apenasPessoasJuridicas=true`)).data

      if (concessionarias.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoConcessionariasVendedoras(concessionarias)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  async function sugerirConcessionariasEntregas () {
    try {
      const pessoaService = new PessoaService()

      const concessionarias = (await pessoaService.filter(`descricaoFiltro=${concessionariaEntrega.nomraz_pes}&apenasPessoasJuridicas=true`)).data

      if (concessionarias.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoConcessionariasEntregas(concessionarias)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  function handleChangeXml (event) {
    setXml({ numeronf: event.target.value })
  }

  function handleChangeConcessionariaVendedora (event) {
    setConcessionariaVendedora({ nomraz_pes: event.target.value })
  }

  function handleChangeConcessionariaEntrega (event) {
    setConcessionariaEntrega({ nomraz_pes: event.target.value })
  }

  function handleChangeLeasing (value) {
    setLeasing(!value)
  }

  function handleOnRegisterConcessionariaVendedora (pessoaRegistrada) {
    if (pessoaRegistrada.cgccpf_pes.length > 11) {
      setConcessionariaVendedora(pessoaRegistrada)
    } else {
      showWarnMessage('Por favor cadastre uma pessoa jurídica!')
    }
  }

  function handleOnRegisterConcessionariaEntrega (pessoaRegistrada) {
    if (pessoaRegistrada.cgccpf_pes.length > 11) {
      setConcessionariaEntrega(pessoaRegistrada)
    } else {
      showWarnMessage('Por favor cadastre uma pessoa jurídica!')
    }
  }

  async function validarChassi () {
    setVisibleConfirm(false)
    // Se for manual
    if (veiculos.length === 1) {
      if (!xml.codigo) {
        showErrorMessage('Xml não foi selecionado!')
        return
      }
      const arquivoXmlBase64 = await XmlImportService.downloadXml(xml.arquivo)
      let arquivoXml = atob(arquivoXmlBase64)
      arquivoXml = arquivoXml.replace(/[^a-zA-Z0-9]/g, '')
      const temChassi = arquivoXml.indexOf(veiculos[0].chassi) > 0
      if (!temChassi) {
        setVisibleChassiNaoEncontrado(true)
      } else {
        await confirmarSaida()
      }
    } else {
      await confirmarSaida()
    }
  }

  async function confirmarSaida () {
    try {
      setVisibleConfirm(false)
      // console.log('XML: ', xml)

      if (!beneficioFiscal?.codigo_bfi) {
        showErrorMessage('Por favor informe um benefiício fiscal válido!')
        return
      }

      for (let veic of veiculos) {
        veic.beneficiofiscal = beneficioFiscal.codigo_bfi
        veic.cnpjvendedora = concessionariaVendedora.cgccpf_pes
        veic.cnpjentregadora = concessionariaEntrega.cgccpf_pes

        // Indica se o veículo é leasing
        veic.leasing = leasing

        if (veiculos.length === 1) {
          // Se for manual, seta o xml de acordo com o que o usuário escolheu
          veic.codigo = xml.codigo
        }
      }
      // console.log('veiculos: ', veiculos)
      await SaidaMontadoraService.confirmarSaida(veiculos)
      await getAll()

      hideModal()
      showSuccessMessage('A sua solicitação de confirmação de saída foi registrada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar confirmar a saída do veículo!')
    }
  }

  return (
    <Modal
      header= {veiculos.length === 1 ? `Saída de veículo em montadora. Chassi:  ${veiculos[0].chassi}` : 'Saída de montadora em lote'}
      visible={visible}
      onHide={() => hideModal()}
      width={60}
      footer={
        <Button
          label="Confirmar saída"
          icon="pi pi-check"
          onClick={() => setVisibleConfirm(true)}
        />
      }
    >
      <div className="formgrid grid">

        <div className="field col-4">
          <SearchInput
            //AutoComplete
            label="Concessionária vendedora"
            placeholder="Concessionária vendedora"
            field="nomraz_pes"
            value={concessionariaVendedora?.nomraz_pes && concessionariaVendedora}
            suggestions={sugestaoConcessionariasVendedoras}
            completeMethod={sugerirConcessionariasVendedoras}
            onChange={handleChangeConcessionariaVendedora}
            onSelect={(e) => setConcessionariaVendedora(e.value)}
            className="inputfield w-full"
            //RegisterModal
            edicaoHabilitada={true}
            ComponenteCadastro={PessoaModal}
            onRegister={handleOnRegisterConcessionariaVendedora}
            primaryKeyName="codigo_pes"
            selected={concessionariaVendedora}
            //FiltroModal
            filtroTitle="Pesquisa de pessoas jurídicas"
            filtersDefault={{ apenasPessoasJuridicas: true }}
            service={PessoaService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>
        <div className="field col-4">
          <SearchInput
            //AutoComplete
            label="Concessionária da entrega"
            placeholder="Concessionária da entrega"
            field="nomraz_pes"
            value={concessionariaEntrega?.nomraz_pes && concessionariaEntrega}
            suggestions={sugestaoConcessionariasEntregas}
            completeMethod={sugerirConcessionariasEntregas}
            onChange={handleChangeConcessionariaEntrega}
            onSelect={(e) => setConcessionariaEntrega(e.value)}
            className="inputfield w-full"
            //RegisterModal
            edicaoHabilitada={true}
            ComponenteCadastro={PessoaModal}
            onRegister={handleOnRegisterConcessionariaEntrega}
            primaryKeyName="codigo_pes"
            selected={concessionariaEntrega}
            //FiltroModal
            filtroTitle="Pesquisa de pessoas jurídicas"
            filtersDefault={{ apenasPessoasJuridicas: true }}
            service={PessoaService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label className="label required">Benefício fiscal</label>
          <SelectInput
            value={beneficioFiscal}
            className="inputfield w-full"
            placeholder="- Selecione -"
            optionLabel="descri_bfi"
            dataKey="codigo_bfi"
            options={beneficiosFiscais}
            onChange={(e) => setBeneficioFiscal(e.target.value)}
            noFloatLabel
          />
        </div>
        {veiculos.length === 1 && veiculos[0].acabado_vei === 0 && (
          <div className="field checkbox-container col-2">
            <Checkbox
              name="leasing"
              value={leasing}
              onChange={(e) => handleChangeLeasing(e.target.value)}
              checked={leasing}
              disabled={false}
            />
            <label className="p-checkbox-label mb-0 pl-2">Possui Leasing?</label>
          </div>
        )
        }
        {veiculos.length === 1 && (
          <div className="field col-12">
            <SearchInput
            //AutoComplete
              label="Nota fiscal de venda direta"
              placeholder="Número da nota fiscal"
              field="numeronf"
              value={xml && xml?.numeronf}
              suggestions={sugestaoXml}
              completeMethod={sugerirXml}
              onChange={handleChangeXml}
              onSelect={(e) => setXml(e.value)}
              className="inputfield w-full"
              itemTemplate={XmlTemplate}
              //FiltroModal
              filtroTitle="Pesquisa de XML"
              service={XmlImportService}
              model={getXmlImportDTO}
              columns={[
                { campo: 'numeronf', nome: 'Número da nota' },
                { campo: 'dataemissao', nome: 'Data emissão', format: formatDate },
                { campo: 'valorentrada', nome: 'Valor da nota', format: formatToCurrency },
                { campo: 'razaosocialemitente', nome: 'Emitente' },
                { campo: 'nome', nome: 'Destinatário' },
                { campo: 'cpfcnpj', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
              ]}
              filtersDefault= {{ filtroErroXml: true, filtroProcessadoApi: true, filtroEmpresaLogada: true, filtroSomenteSaida: true }}
            />
          </div>
        )}
        {veiculos.length === 1 && (
          <Fieldset legend={<b>Dados da nota fiscal</b>} style={{ marginBottom: '10px', minWidth: '300px' }} className="col-12">
            <div className="formgrid grid">
              {/* Dados dp cliente e da nf */}
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Nota Fiscal"
                  value={xml.numeronota}
                  name="numeronf"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Nota fiscal"
                />
              </div>

              {/* Dados dp cliente e da nf */}
              <div className="field col-12 md:col-1">
                <TextInput
                  label="Serie"
                  value={xml.serie}
                  name="numeronf"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Série"
                />
              </div>
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Data de emissão"
                  value={xml.dataemissao ? formatDate(xml.dataemissao) : ''}
                  name="dataemissao"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Data de emissão"
                />
              </div>
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Valor"
                  value={formatToCurrency(xml.valorentrada)}
                  name="valorentrada"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Valor total da nota"
                />
              </div>
              <div className="field col-12 md:col-5">
                <TextInput
                  label="Chave NF-e"
                  value={xml.chavenfe}
                  name="chavenfe"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Chave NF-e"
                />
              </div>
              <div className="field col-12 md:col-4">
                <TextInput
                  label="Emitente"
                  value={xml.razaosocialemitente}
                  name="razaosocialemitente"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Emitente"
                />
              </div>
              <div className="field col-12 md:col-5">
                <TextInput
                  label="Destinatário"
                  value={xml.nome}
                  name="nomrazPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Destinatário"
                />
              </div>
              <div className="field col-12 md:col-3">
                <TextInput
                  label="CPF/CNPJ"
                  value={xml.cpfcnpj}
                  name="cpfcnpj"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="___.___.___-__"
                  mask={xml.cpfcnpj && xml.cpfcnpj.length === 11 ? '999.999.999-99' : '99.999.999/9999-99'}
                />
              </div>
              <div className="field col-12 md:col-4">
                <TextInput
                  label="Endereço"
                  value={xml.endereco}
                  name="enderecoPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Endereço"
                />
              </div>
              <div className="field col-12 md:col-1">
                <TextInput
                  label="Número"
                  value={xml.numero}
                  name="numeroPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Número"
                />
              </div>
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Complemento"
                  value={xml.complemento}
                  name="complementoPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Complemento"
                />
              </div>
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Bairro"
                  value={xml.bairro}
                  name="bairroPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Bairro"
                />
              </div>
              <div className="field col-12 md:col-2">
                <TextInput
                  label="Município"
                  value={xml.municipio}
                  name="municipioPes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Município"
                />
              </div>
              <div className="field col-12 md:col-1">
                <TextInput
                  label="UF"
                  value={xml.uf}
                  name="cidadePes"
                  className="inputfield w-full"
                  disabled={true}
                  placeholder="Cidade"
                />
              </div>
            </div>
          </Fieldset>
        )}
        <div>
          <span className={'field col-12 text-500 font-italic text-sm '}>
            {veiculos.length === 1 ? 'Para confirmar a saída manualmente, é necessário importar o xml antes e depois escolher ele na lista!' : 'Será informado a mesma concessionária vendedora, entregadora e o mesmo benefício fiscal para os chassis selecionados!'}
          </span>
        </div>
      </div>
      <Confirm
        title="Confirmar saída montadora"
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        onConfirm={async () => await validarChassi() }
        description="Confirma realizar a saída dos chassis selecionados?"
        cancelLabel="Não"
      />
      <Confirm
        title="Chassi não encontrado!"
        visible={visibleChassiNaoEncontrado}
        onCancel={() => setVisibleChassiNaoEncontrado(false)}
        onConfirm={async () => await confirmarSaida()}
        description="Não foi encontrado o chassi na nota fiscal selecionada. Confirma continuar?"
        cancelLabel="Não"
      />
    </Modal>

  )
}

export default SaidaMontadoraModal

export function XmlTemplate (xml) {
  return <span><b>NF: {xml?.numeronf}</b> - Destinatário: {xml?.nome} - {formatCpfOrCnpj(xml?.cpfcnpj)} - Emissão: {formatDate(xml?.dataemissao)} - Valor: {formatToCurrency(xml?.valorentrada)}</span>
}
