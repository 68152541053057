import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import Modal from '../../components/utils/Modal'
import CommonHelper from '../../helpers/CommonHelper'
import { getStore } from '../../redux/store'
import TokenIntegracaoService from '../../services/token-integracao/TokenIntegracaoService'
import './_meuToken.scss'
function MeuToken () {
  const [token, setToken] = useState('')
  const [visibleModalAviso, setVisibleModalAviso] = useState(false)
  const codigoEmpresaLogada = getStore().empresaLogada.codigo_emp

  useEffect(() => {
    getToken()
  }, [])

  const getToken = async () => {
    const { data } = await TokenIntegracaoService.getToken(codigoEmpresaLogada)
    setToken(data)
  }

  const gerarToken = async () => {
    const { data } = await TokenIntegracaoService.saveToken(codigoEmpresaLogada)
    setToken(data)
    setVisibleModalAviso(false)
  }
  return (
    <div className='h-350'>
      <div className="flex mb-3 justify-content-between mt-3 border-bottom-1 border-300 pb-2 h-30">
        <h1>Meu token</h1>
      </div>
      <div className="grid form-body justify-content-center align-items-center h-full pt-6  ">
        <div className=" bg-white p-4 h-full container justify-content-center align-items-center flex">
          <div>

            <TextAreaInput
              label='Meu token de integração'
              value={token}
              className={'w-full textArea carentDisabled'}
              disabled={true}

            />
            <Button
              icon="pi pi-copy"
              type="button"
              label="Copiar token de integração"
              className="edit-button ml-2 mt-2 "
              onClick={() => CommonHelper.copyToClipboard(token)}
            />
          </div>
          <Button
            icon="pi pi-refresh"
            type="button"
            label="Gerar novo token de integração"
            className="edit-button ml-4 "
            onClick={() => setVisibleModalAviso(true)}
          />
        </div>
      </div>
      <Modal
        header="Gerar novo Token de Integração"
        visible={visibleModalAviso}
        focusOnShow={false}
        closeOnEscape={false}
        closable={false}
        width={40}
        footer={
          <>
            <Button
              label="Cancelar"
              className=' p-button-danger'
              icon="pi pi-times"
              onClick={() => setVisibleModalAviso(false)}
              autoFocus={true} />
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => gerarToken()}
              autoFocus={true} />
          </>
        }
      >
        <div className="formgrid grid">
          <div className="field col-10">
          Confirma gerar novo token? Isso pode impactar as integrações existentes.
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MeuToken
