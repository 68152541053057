import { getStore } from '../../redux/store'

export const getPessoaDTO = (data = {}) => {
  return {
    codigoPes: data.codigo_pes || null,
    nomrazPes: data.nomraz_pes || null,
    cgccpfPes: data.cgccpf_pes || null,
    emailPes: data.email_pes || null,
    tipoPes: data.tipo_pes || null,
    codempPes: data.codemp_pes || null,
    cepPes: data.cep_pes || null,
    endPes: data.end_pes || null,
    numendPes: data.numend_pes || null,
    bairroPes: data.bairro_pes || null,
    comendPes: data.comend_pes || null,
    codmunPes: data.codmun_pes || null,
    codufPes: data.coduf_pes || null,
    telcelPes: data.telcel_pes || null,
    telresPes: data.telres_pes || null
  }
}

export const postPessoaDTO = (data = {}) => {
  return {
    codigo_pes: data.codigoPes || null,
    nomraz_pes: data.nomrazPes || null,
    cgccpf_pes: data.cgccpfPes || null,
    email_pes: data.emailPes || null,
    tipo_pes: data.tipoPes || null,
    codemp_pes: data.codempPes || null,
    cep_pes: data.cepPes || null,
    end_pes: data.endPes || null,
    numend_pes: data.numendPes || '',
    bairro_pes: data.bairroPes || null,
    comend_pes: data.comendPes || '',
    codmun_pes: data.codmunPes || null,
    coduf_pes: data.codufPes || null,
    telcel_pes: data.telcelPes || '',
    telres_pes: data.telresPes || ''
  }
}

export const excelToPessoaDTO = (data = {}) => {
  return {
    nomrazPes: data.nome.toString(),
    cgccpfPes: data.cpfOuCnpj.toString(),
    emailPes: data.email.toString(),
    cepPes: data.cep.toString(),
    numendPes: data.numero?.toString() || null,
    comendPes: data.complemento?.toString() || null,
    telcelPes: data.telefoneCelular?.toString() || null,
    telresPes: data.telefoneResidencial?.toString() || null,
    tipoPes: data.cpfOuCnpj.lenght > 11 ? 'J' : 'F',
    codempPes: getStore().empresaLogada.codigo_emp,
    codigoPes: null,
    endPes: null,
    bairroPes: null,
    codmunPes: null,
    codufPes: null
  }
}
