import React from 'react'
import { Crud } from '../../classes/Crud'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import { getMensagemGlobalDTO } from '../../dtos/suporte/MensagemGlobalDTO'
import MensagemGlobalForm from '../../forms/suporte/MensagemGlobalForm'
import CommonHelper from '../../helpers/CommonHelper'
import MensagemGlobalService from '../../services/suporte/MensagemGlobalService'

export default class MensagemGlobal extends Crud {
  constructor (props) {
    super(props, getMensagemGlobalDTO, MensagemGlobalService, '')
  }

  componentDidMount () {
    if (!CommonHelper.isUsuarioAdmin()) {
      this.props.history.push('/home')
    } else {
      super.componentDidMount()
    }
  }

  render () {
    const { model, dataLoaded } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded) && (
            <MensagemGlobalForm
              model={model}
            />
          )}
        </Container>
      </Page>
    )
  }
}
