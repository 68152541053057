import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/utils/DataList'
import { getVeiculoEntradaDTO } from '../../../../dtos/renave/VeiculoEntradaDTO'
import { formatCnpj, formatDate, formatDescricaoVeiculo } from '../../../../helpers/formaters'
import ReceberTransferenciaEstabelecimentoService from '../../../../services/zeroKm/veiculos/ReceberTransferenciaEstabelecimentoService'
import ReceberTransferenciaEstabelecimentoModal from './ReceberTransferenciaEstabelecimentoModal'

export class ReceberTransferenciaEstabelecimento extends List {
  constructor (props) {
    super(
      props,
      getVeiculoEntradaDTO,
      ReceberTransferenciaEstabelecimentoService,
      '',
      '',
      'pendenteRecebimento'
    )

    this.state = {
      ...this.state,
      veiculoSelecionado: {},
      visibleReceberTransferenciaEstabelecimentoModal: false
    }
  }

  render () {
    const state = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          <Button
            label="Receber"
            className="expansion-button"
            onClick={() => this.setState({ veiculoSelecionado: data, visibleReceberTransferenciaEstabelecimentoModal: true })}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Receber transferência de estabelecimento</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'pendenteRecebimento' }, 'filter-option')}>
              <span className="option-label">Pendente de recebimento</span>
              <div className="option-quantity">{state.totalRecords}</div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Pesquisar"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column
              style={{ width: '45%' }}
              header="Veículo"
              body={data => formatDescricaoVeiculo(data.chassi, data.marcaModelo)}
            />
            <Column
              style={{ width: '25%' }}
              header="Enviado de"
              body={(data) => `${formatCnpj(data.cnpjEstabelecimentoAutorizador)} - ${data.nomeEstabelecimentoAutorizador}`}
            />
            <Column
              style={{ width: '10%' }}
              header="Enviado em"
              body={(data) => data.dataHoraAutorizacao ? formatDate(data.dataHoraAutorizacao) : ''}
            />
          </DataList>
        </Container>
        <ReceberTransferenciaEstabelecimentoModal
          visible={state.visibleReceberTransferenciaEstabelecimentoModal}
          onHide={() => this.setState({ visibleReceberTransferenciaEstabelecimentoModal: false })}
          veiculoParaReceber={state.veiculoSelecionado}
          getAll={this.getAll}
        />
      </Page>
    )
  }
}
