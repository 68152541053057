import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class SolicitacaoSaidaService {
  static async solicitacaoSaidaEstoque (dataVenda, valorVenda, veiculo, comprador) {
    return await ServiceBase.postBase('solicitacao-saida-estoque/', { dataVenda, valorVenda, veiculo, comprador }, ComunicaRenave)
  }

  static async cancelarSaida (idEstoque) {
    return await ServiceBase.postBase('solicitacao-saida-estoque/cancelamento', { idEstoque }, ComunicaRenave)
  }
}
