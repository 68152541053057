import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class VeiculoConfirmadoService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`veiculosConfirmados?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`veiculosConfirmados?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }
}
