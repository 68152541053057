import * as Yup from 'yup'

export const VeiculoValidator = Yup.object().shape({
  anofabVei: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  anomodVei: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  dahmedVei: Yup.date()
    .nullable()
    .required('Este campo é obrigatório!'),
  descriVei: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  kmatuVei: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  corVei: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  chassiVei: Yup.string()
    .nullable()
    .test('chassiVei', 'Este campo é obrigatório!', function (value) {
      if (this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    }),
  placaVei: Yup.string()
    .nullable()
    .test('placaVei', 'Este campo é obrigatório!', function (value) {
      if (!this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    }),
  renavaVei: Yup.string()
    .nullable()
    .test('renavaVei', 'Este campo é obrigatório!', function (value) {
      if (!this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    }),
  numcrvVei: Yup.string()
    .nullable()
    .test('numcrvVei', 'Este campo é obrigatório!', function (value) {
      if (!this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    }),
  codsegcrvVei: Yup.string()
    .nullable()
    .test('codsegcrvVei', 'Este campo é obrigatório!', function (value) {
      if (!this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    }),
  tipcrvVei: Yup.string()
    .nullable()
    .test('tipcrvVei', 'Este campo é obrigatório!', function (value) {
      if (!this.parent.isnovoVei) {
        if (value) {
          return true
        }
        return false
      } else {
        return true
      }
    })
})
