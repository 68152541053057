import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import EntradaZeroKmService from '../../../../services/zeroKm/veiculos/EntradaZeroKmService'
import XmlImportService from '../../../../services/zeroKm/xml/XmlImportService'

const ConfirmarEntradaManualmenteModal = ({ visible, onHide }) => {
  const [chassiParaConfirmar, setChassiParaConfirmar] = useState('')

  const hide = () => {
    setChassiParaConfirmar('')
    onHide()
  }

  const confimarEntradaManualmente = async () => {
    const QUANTIDADE_CARACTERES_VALIDOS = 17
    try {
      if (chassiParaConfirmar.length !== QUANTIDADE_CARACTERES_VALIDOS)
        throw Error('Chassi inválido, por favor informe um chassi válido!')

      const xmlImportEntrada = await XmlImportService.getMostRecentXmlEntradaByChassi(chassiParaConfirmar)

      await EntradaZeroKmService.confirmarEntrada([{ codigo: xmlImportEntrada.codigo }])

      hide()
      showSuccessMessage('O chassi esta sendo confirmado!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar confirmar manualmente o chassi: ${chassiParaConfirmar}`)
    }
  }

  return (
    <Modal
      header="Confirmar entrada manualmente"
      width={40}
      visible={visible}
      onHide={hide}
      footer={
        <Button
          label="Confirmar"
          onClick={() => confimarEntradaManualmente()}
        />
      }
    >
      <div className="field col-12 flex justify-content-center">
        <div className="field col-8">
          <TextInput
            label="Chassi para confirmar"
            value={chassiParaConfirmar}
            onChange={(e) => setChassiParaConfirmar(e.target.value)}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmarEntradaManualmenteModal
