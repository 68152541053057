import { postPessoaDTO } from '../../dtos/cadastro/PessoaDTO'
import ServiceBase from '../base/ServiceBase'

export default class PessoaService {
  async get (id) {
    return await ServiceBase.getBase(`pessoa/${id}`)
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`pessoas?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data, loading = true) {
    return await ServiceBase.postBase('pessoa', postPessoaDTO(data), null, loading)
  }

  async delete (codigo_pef) {
    return await ServiceBase.deleteBase(`pessoa/${codigo_pef}`)
  }
}
