import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { TreeSelect } from 'primereact/treeselect'
import React from 'react'
import List from '../../classes/List'
import DateInput from '../../components/inputs/DateInput'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import CommonHelper from '../../helpers/CommonHelper'
import { formatDateTime, formatPlaca, formatToCurrency } from '../../helpers/formaters'
import { getStore } from '../../redux/store'
import UsuarioService from '../../services/cadastro/UsuarioService'
import HistoricoSaldoDetranService from '../../services/zeroKm/historico/HistoricoSaldoDetranService'

class HistoricoTaxaDetran extends List {
  constructor (props) {
    super(
      props,
      '',
      HistoricoSaldoDetranService,
      '',
      '',
      't'
    )

    this.state = {
      ...this.state,
      dataMovimentacaoInicial: null,
      dataMovimentacaoFinal: null,
      operacao: null,
      cuscreCheckBox: false,
      companies: [],
      operacaoOptions: [],
      selectedCompanies: {
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      }
    }

    //#region Criando colunas.

    this.setColumns([
      {
        key: 'placa_mov',
        component: (
          <Column
            style={{ width: '15%' }}
            field="placa_mov"
            header="Descrição do veículo"
            body={data => (data.placa_mov || data.chassi_mov) && data.descri_vei && `${formatPlaca(data.placa_mov) || data.chassi_mov} - ${data.descri_vei}`}
            sortable
            headerClassName="placa_mov" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Empresa"
            body={data => data.nomraz_emp ? data.nomraz_emp : data.nomfan_emp}
            sortable
            headerClassName="nomraz_emp" />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            style={{ width: '15%' }}
            field="nome_usu"
            header="Usuário"
            sortable
            headerClassName="nome_usu" />
        )
      },
      {
        key: 'dathor_hcr',
        component: (
          <Column
            style={{ width: '20%' }}
            field="dathor_hcr"
            header="Data da movimentação"
            body={data => data.dathor_hcr ? formatDateTime(data.dathor_hcr) : ''}
            sortable
            headerClassName="dathor_hcr" />
        )
      },

      {
        key: 'valor_hcr',
        component: (
          <Column
            style={{ width: '10%' }}
            field="valor_hcr"
            header="Valor "
            body={data => data.valor_hcr ? formatToCurrency(data.valor_hcr) : ''}
            sortable
            headerClassName="valor_hcr" />
        )
      },
      {
        key: 'descri_ope',
        component: (
          <Column
            style={{ width: '15%' }}
            field="descri_ope"
            header="Operação"
            body={data => data.descri_ope ? data.descri_ope : 'Recarga'}
            sortable
            headerClassName="descri_ope" />
        )
      }
    ])

    //#endregion
  }

  async componentDidMount () {
    await super.componentDidMount()
    const empresasAcesso = await UsuarioService.empresasAcessoUsuarioLogado()
    const empresasAcessoAtivas = empresasAcesso.filter((empresa) => empresa.ativo_emp)

    //Colocando a empresa logada como primeiro registro do array
    const empresaLogada = empresasAcessoAtivas.filter((empresa) => empresa.codigo_emp === getStore().empresaLogada.codigo_emp).shift()
    empresasAcessoAtivas.splice(empresasAcessoAtivas.indexOf(empresaLogada), 1)
    empresasAcessoAtivas.unshift(empresaLogada)
    const empresas = empresasAcessoAtivas.map((empresa, idx) => {
      return {
        key: `0-${idx}`,
        label: empresa.nomfan_emp,
        data: empresa.codigo_emp
      }
    })

    const companies = [{
      key: 0,
      label: 'Empresas',
      children: [...empresas]
    }]

    this.setState({ companies })
  }

  onFilterClick = () => {
    const { filter, selectedCompanies, companies } = this.state
    filter.dataMovimentacaoInicial = CommonHelper.dateToAmerican(this.state.dataMovimentacaoInicial)
    filter.dataMovimentacaoFinal = CommonHelper.dateToAmerican(this.state.dataMovimentacaoFinal)

    if (this.state.cuscreCheckBox) {
      filter.mostraApenasCuscre = true
      filter.codope = null
    } else {
      filter.codope = this.state.operacao ? this.state.operacao.codigo_ope : null
      filter.mostraApenasCuscre = null
    }

    const idsEmpresas = []

    for (const propertyName of Object.getOwnPropertyNames(selectedCompanies)) {
      if (propertyName.substring(2))
        idsEmpresas.push(companies[0].children[Number(propertyName.substring(2))].data)
    }

    if (idsEmpresas.length === 0)
      idsEmpresas.push('nenhuma')

    filter.idsEmpresas = idsEmpresas
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data do histórico de saldo</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoInicial}
              onChange={(e) => this.setState({ dataMovimentacaoInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoFinal}
              onChange={(e) => this.setState({ dataMovimentacaoFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 md:col-5">
        <label className="label">Empresas</label>
        <TreeSelect
          value={this.state.selectedCompanies}
          options={this.state.companies}
          onChange={(e) => this.setState({ selectedCompanies: e.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione a empresa"
          filter
        />
      </div>

      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Histórico de saldo DETRAN</h4>
          <div className="page-header-buttons">
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Procurar histórico de saldo"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

export default HistoricoTaxaDetran
