import { Button } from 'primereact/button'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Modal from '../../../components/utils/Modal'

const AdicionarVeiculoModal = ({ visible, onHide }) => {
  const history = useHistory()

  return (
    <Modal
      header="Tipo de veículo:"
      visible={visible}
      onHide={onHide}
      width="25"
    >
      <div className="grid">
        <div className="col-6">
          <Button
            label="Usado"
            className="w-full"
            onClick={() => history.push('/veiculo/usado')}
          />
        </div>
        <div className="col-6">
          <Button
            label="Novo"
            className="w-full"
            onClick={() => history.push('/veiculo/novo')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AdicionarVeiculoModal
