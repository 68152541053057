import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { formatCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import XmlConverter from 'x2js'
import { FileUpload } from 'primereact/fileupload'
import ReceberTransferenciaEstabelecimentoService from '../../../../services/zeroKm/veiculos/ReceberTransferenciaEstabelecimentoService'

const ReceberTransferenciaEstabelecimentoModal = ({ visible, onHide, veiculoParaReceber, getAll }) => {
  const [valorVeiculo, setValorVeiculo] = useState(veiculoParaReceber.valorVeiculo || 0)
  const [chaveNota, setChaveNota] = useState('')

  useEffect(() => {
    if (veiculoParaReceber.valorVeiculo) {
      setValorVeiculo(veiculoParaReceber.valorVeiculo)
    }
  }, [veiculoParaReceber])

  const handleReceberVeiculo = async () => {
    try {
      if (valorVeiculo < 0.01) {
        showErrorMessage('Por favor informar um valor válido')
        return
      }

      await ReceberTransferenciaEstabelecimentoService.receberTransferenciaEstabelecimento(chaveNota, veiculoParaReceber.id, valorVeiculo)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValorVeiculo(formatCurrencyToDecimal(novoValor))
  }

  function hideModal () {
    setValorVeiculo(0)
    onHide()
  }

  async function handleUploadChaveXml (event) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', readFile)

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    setChaveNota(chaveNFe)
  }

  return (
    <Modal
      header="Receber transferência de estabelecimento"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleReceberVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-9">
          <TextInput
            label="Estabelecimento"
            placeholder="Estabelecimento"
            className="inputfield w-full"
            value={`${formatCnpj(veiculoParaReceber.cnpjEstabelecimentoAutorizador)} - ${veiculoParaReceber.nomeEstabelecimentoAutorizador}`}
            disabled={true}
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            label="Valor"
            placeholder="Valor do veículo"
            className="inputfield w-full"
            onChange={(e) => handleChangeValor(e)}
            value={formatToCurrency(valorVeiculo)}
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Nota fiscal"
            className="inputfield w-full"
            value={chaveNota}
            onChange={(e) => setChaveNota(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota fiscal"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={handleUploadChaveXml}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ReceberTransferenciaEstabelecimentoModal
