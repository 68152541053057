import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'

class Modal extends Component {
  render () {
    return (
      <Dialog
        className={`modal-vw-${this.props.width || '45'}`}
        resizable={false}
        footer={this.props.footer ||
          <>
          </>
        }
        {...this.props}
      >
        {this.props.children}
        <div className="p-dialog-buttons">
          {this.props.btnExcluir !== undefined && (
            <Button
              label="Excluir"
              icon="pi pi-times"
              type="button"
              onClick={() => this.props.btnExcluir()}
              className="p-button-danger mr-2"
            />
          )}
          {this.props.btnSalvar !== undefined && (
            <Button
              type="button"
              label="Salvar"
              className="p-button"
              onClick={() => this.props.btnSalvar()}
            />
          )}
        </div>
      </Dialog>
    )
  }
}

export default Modal
