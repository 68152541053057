import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EstoqueIteService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`ite/estoque?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`ite/estoque?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }
}
