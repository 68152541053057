import React from 'react'
import { Button } from 'primereact/button'
import List from '../../../../classes/List'
import { getVeiculoEntradaDTO } from '../../../../dtos/renave/VeiculoEntradaDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import DateInput from '../../../../components/inputs/DateInput'
import { Column } from 'primereact/column'
import { formatDate } from '../../../../helpers/formaters'
import Page from '../../../../components/layout/Page'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/utils/DataList'
import { classNames } from 'primereact/utils'
import TransferirFilialModal from './TransferirFilialModal'
import VeiculosTransferenciaService from '../../../../services/zeroKm/veiculos/VeiculosTransferenciaService'

export class TransferenciaFilial extends List {
  constructor (props) {
    super(
      props,
      getVeiculoEntradaDTO,
      VeiculosTransferenciaService,
      '',
      '',
      'estoque'
    )

    this.state = {
      ...this.state,
      veiculoParaTransferir: {},
      visibleTransferirFilialModal: false
    }

    this.setColumns([
      {
        key: 'chassi_vei',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Chassi"
            field="chassi_vei"
            sortable
            headerClassName="chassi_vei"
          />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Marca/Modelo"
            field="descri_vei"
            sortable
            headerClassName="descri_vei"
          />
        )
      },
      {
        key: 'anofab_vei',
        component: (
          <Column
            style={{ width: '10%' }}
            header="Fab/Mod"
            field="anofab_vei"
            body={(data) => data.anofab_vei ? (data.anofab_vei + '/' + data.anomod_vei) : ''}
            sortable
            headerClassName="anofab_vei"
          />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Cor"
            field="cor_vei"
            sortable
            headerClassName="cor_vei"
          />
        )
      },
      {
        key: 'datent_vei',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data entrada"
            field="datent_vei"
            body={(data) => data.datent_vei ? formatDate(data.datent_vei) : ''}
            sortable
            headerClassName="datent_vei"
          />
        )
      }
    ])
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.dataEntradaInicial = CommonHelper.dateToAmerican(this.state.dataEntradaInicial)
    filter.dataEntradaFinal = CommonHelper.dateToAmerican(this.state.dataEntradaFinal)
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data de entrada do veículo</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataEntradaInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataEntradaInicial}
              onChange={(e) => this.setState({ dataEntradaInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataEntradaFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataEntradaFinal}
              onChange={(e) => this.setState({ dataEntradaFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          <Button
            label="Transferir"
            className="expansion-button"
            onClick={() => this.setState({ visibleTransferirFilialModal: true, veiculoParaTransferir: data })}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Transferência entre filiais</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'estoque' }, 'filter-option')}>
              <span className="option-label">Estoque Renave</span>
              <div className="option-quantity">
                {totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            responsive
            showFilter
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <TransferirFilialModal
          visible={this.state.visibleTransferirFilialModal}
          onHide={() => this.setState({ visibleTransferirFilialModal: false })}
          veiculoParaTransferir={this.state.veiculoParaTransferir}
          getAll={this.getAll}
        />
      </Page>
    )
  }
}
