import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { SelectButton } from 'primereact/selectbutton'
import React from 'react'

const ConfigPage = ({
  optionsSelect,
  optionValue,
  optionValueChange,
  empresasAcesso,
  arrayConfigEmps,
  setArrayConfigEmps,
  configEmpPorGrupo,
  configEmpPorGrupoChange,
  saveConfig
}) => {
  return (
    <div className="formgrid grid">
      <div className="field col-12 flex justify-content-center">
        <SelectButton
          value={optionValue}
          options={optionsSelect}
          className="option-select config"
          onChange={(e) => {
            if (e.value) {
              optionValueChange(e.value)
            }
          }}
        />
      </div>
      {optionValue === 'Por Empresa' && (
        <>
          {empresasAcesso.map((empresa, idxEmpresa) => {
            return (
              <div key={idxEmpresa} className="field checkbox-container col-6">
                <Checkbox
                  value={arrayConfigEmps[idxEmpresa].valor_coe ? true : false}
                  checked={arrayConfigEmps[idxEmpresa].valor_coe ? true : false}
                  onChange={(e) => {
                    arrayConfigEmps[idxEmpresa].valor_coe = e.value ? false : true
                    setArrayConfigEmps(arrayConfigEmps)
                  }}
                />
                <label className="p-checkbox-label mb-0 pl-2">{empresa.nomfan_emp}</label>
              </div>
            )
          })}
        </>
      )}
      {optionValue === 'Por Grupo' && (
        <div className="field checkbox-container col-12">
          <Checkbox
            value={configEmpPorGrupo.valor_coe ? true : false}
            checked={configEmpPorGrupo.valor_coe ? true : false}
            onChange={(e) => {
              configEmpPorGrupo.valor_coe = e.value ? false : true
              configEmpPorGrupoChange(configEmpPorGrupo)
            }}
          />
          <label className="p-checkbox-label mb-0 pl-2">Aplicar para todas do grupo.</label>
        </div>
      )}
      <div className="field col-12 flex justify-content-end">
        <Button
          label="Salvar"
          onClick={() => saveConfig()}
        />
      </div>
    </div>
  )
}

export default ConfigPage
