import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import LoginService from '../../services/publicos/LoginService'

const RedefinirSenhaModal = ({ visible, onHide }) => {
  const [email, setEmail] = useState('')

  const handleRedefinirSenha = async () => {
    try {
      await LoginService.redefinirSenha(email)
      hide()
      showSuccessMessage('Email com a sua nova senha enviado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro ao tentar redefinir a senha!')
    }
  }

  const hide = () => {
    setEmail('')
    onHide()
  }

  return (
    <Modal
      header="Redefinir senha"
      width={45}
      onHide={hide}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleRedefinirSenha()}
        />
      }
    >
      <div className="field col-12">
        *Importante* - Você receberá um e-mail com uma nova senha temporária, você poderá trocar essa senha dentro do site.
      </div>
      <div className="field col-12">
        <TextInput
          label="Email"
          placeholder="Email"
          className="inputfield w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </Modal>
  )
}

export default RedefinirSenhaModal
