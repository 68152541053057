export const getSaidaZeroKmDTO = (data = {}) => {
  return {
    numeronf: data.numeronf || null,
    serie: data.serie || null,
    chassi: data.chassi || null,
    marcamodelo: data.marcamodelo || null,
    cor: data.cor || null,
    dataemissao: data.dataemissao || null,
    valorentrada: data.valorentrada || null,
    idEstoque: data.idEstoque || null
  }
}
