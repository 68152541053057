import React from 'react'
import Modal from '../../../components/utils/Modal'

const ConfirmacaoErroModal = ({ visible, onHide, mensagem }) => {
  return (
    <Modal
      header="Erro ao tentar confirmar veículo."
      visible={visible}
      onHide={onHide}
      width={45}
    >
      <div className="field col-12 flex justify-content-center">
        <span className="bold mr-1">•</span><span>{mensagem}</span>
      </div>
    </Modal>
  )
}

export default ConfirmacaoErroModal
