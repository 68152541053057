import React, { useEffect, useState } from 'react'
import { Divider } from 'primereact/divider'
import { ImWarning } from 'react-icons/im'
import { FiInfo } from 'react-icons/fi'
import { showErrorMessage } from '../utils/Message'
import CommonHelper from '../../helpers/CommonHelper'

const Alerta = ({ empresa, usuario, mensagemGlobal }) => {
  const [mensagem, setMensagem] = useState('')

  useEffect(() => {
    if (!mensagemGlobal.mensagem_men) return

    getMensagemParaMostrarNaTela()
  }, [mensagemGlobal.mensagem_men])

  const getMensagemParaMostrarNaTela = () => {
    const partesMensagem = mensagemGlobal.mensagem_men.split('%')

    const mensagem = partesMensagem.map(parteMensagem => {
      if (verificaSeTemUmLink()) {
        try {
          const partesDoLink = parteMensagem.replace('\\', '').split(' ')
          const url = partesDoLink.shift()
          const textoQueOUsuarioVaiClicar = partesDoLink.reduce((parteAnterior, parteAtual) => parteAnterior + ' ' + parteAtual)

          return (
            <a className="ml-1 mr-1 link" onClick={() => window.open(url)}>{textoQueOUsuarioVaiClicar}</a>
          )
        } catch (error) {
          showErrorMessage('Formato de link para a mensagem global inválido!')
        }
      }

      return <>{parteMensagem}</>

      function verificaSeTemUmLink () {
        return parteMensagem.startsWith('\\')
      }
    })

    setMensagem(mensagem)
  }

  const getIconeMsgGlobal = () => {
    switch (mensagemGlobal.tipo_men) {
    case 'A':
      return <ImWarning className="warning-certificado mr-1 mb-1"/>
    case 'I':
      return <FiInfo className="warning-certificado"/>
    default:
      return <></>
    }
  }

  const isCadastroEmpresaOuUsuarioIncompleto = () => {
    return isCadastroEmpresaIncompleto() || isCadastroUsuarioIncompleto()
  }

  const isCadastroEmpresaIncompleto = () => {
    return !empresa.cnpj_emp
  }

  const isCadastroUsuarioIncompleto = () => {
    return !usuario.cpf_usu
  }

  // Se o cadastro está desatualizado a mais de 6 meses
  const isCadastroEmpresaDesatualizado = () => {
    const diferencaEmDias = CommonHelper.calcularDiferencaEmDias(new Date(empresa.dahult_emp ?? empresa.dahins_emp))
    return diferencaEmDias > 180
  }

  const isMensagemAtiva = () => {
    return !!mensagemGlobal.ativo_men
  }

  return ((isCadastroEmpresaOuUsuarioIncompleto() || isMensagemAtiva() || isCadastroEmpresaDesatualizado()) && (
    <div className="topbar-alert">
      {isCadastroEmpresaOuUsuarioIncompleto() && (
        <div className="flex align-items-center">
          <ImWarning className="warning-certificado mr-1 mb-1"/>
          <h3 className="ml-4">COMPLETE SEU CADASTRO -</h3>
          {isCadastroEmpresaIncompleto() && (
            <>
              <strong className="ml-1 mr-1">Clique para alterar a</strong>
              <a className="mr-1 underline" href={`/empresa/${empresa.codigo_emp}`}>empresa</a>
            </>
          )}
          {(isCadastroEmpresaIncompleto() && isCadastroUsuarioIncompleto()) && (
            <strong>-</strong>
          )}
          {isCadastroUsuarioIncompleto() && (
            <>
              <strong className="ml-1 mr-1">Clique para alterar o</strong>
              <a className="mr-1 underline" href={`/usuario/${usuario.codigo_usu}`}>usuário</a>
            </>
          )}
        </div>
      )}
      {(isCadastroEmpresaOuUsuarioIncompleto() && isMensagemAtiva()) && (
        <Divider className="alert-divider"/>
      )}
      {isMensagemAtiva() && (
        <div className="flex align-items-center">
          {getIconeMsgGlobal()}
          <strong className="ml-3">{mensagem}</strong>
        </div>
      )}
      {isCadastroEmpresaDesatualizado() && (
        <>
          <div className="flex align-items-center">
            <ImWarning className="warning-certificado mr-1 mb-2"/>
            <h3 className="ml-4">O CADASTRO DA EMPRESA ESTÁ DESATUALIZADO -</h3>
            <strong className="ml-1 mr-1">Clique para atualizar o</strong>
            <a className="mr-1 underline" href={`/empresa/${empresa.codigo_emp}`}>cadastro</a>
          </div>
        </>
      )}
    </div>
  ))
}

export default Alerta
