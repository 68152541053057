import ServiceBase, { ComunicaRenave } from '../../base/ServiceBase'

export default class ReceberTransferenciaEstabelecimentoService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`transferenciaZeroKm/autorizacoesEstabelecimento?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`transferenciaZeroKm/autorizacoesEstabelecimento?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async receberTransferenciaEstabelecimento (chaveNotaFiscal, idAutorizacao, valorCompra) {
    return await ServiceBase.postBase('transferenciaZeroKm/receberTransferenciaEstabelecimento', { chaveNotaFiscal, idAutorizacao, valorCompra }, ComunicaRenave)
  }
}
