import List from '../../../classes/List'
import React from 'react'
import { Column } from 'primereact/column'
import DataList from '../../../components/utils/DataList'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Button } from 'primereact/button'
import CommonHelper from '../../../helpers/CommonHelper'
import classNames from 'classnames'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import AdicionarVeiculoModal from './AdicionarVeiculoModal'
import { excelToVeiculoDTO, getVeiculoDTO } from '../../../dtos/cadastro/VeiculoDTO'
import XLSX from 'xlsx'
import { showErrorMessage } from '../../../components/utils/Message'
import { dispatch } from '../../../redux/store'
import { startLoading, stopLoading } from '../../../redux/actions'
import { formatPlaca } from '../../../helpers/formaters'
import Modal from '../../../components/utils/Modal'
import { BsQuestionCircle } from 'react-icons/bs'

export default class VeiculoList extends List {
  constructor (props) {
    super(
      props,
      getVeiculoDTO,
      VeiculoService,
      'veiculo',
      'codigo_vei'
    )

    this.state = {
      ...this.state,
      visibleAdicionarVeiculoModal: false,
      visibleLogExcelVeiculosModal: false,
      logExcelVeiculos: []
    }

    this.setColumns([
      {
        key: 'chassi_vei',
        component: (
          <Column
            field="chassi_vei"
            header="Chassi"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="chassi_vei"
          />
        )
      },
      {
        key: 'placa_vei',
        component: (
          <Column
            field="placa_vei"
            header="Placa"
            sortable
            body={(data) => CommonHelper.formatToPlaca(data.placa_vei)}
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="placa_vei"
          />
        )
      },
      {
        key: 'renava_vei',
        component: (
          <Column
            field="renava_vei"
            header="Renavam"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="renava_vei"
          />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            field="descri_vei"
            header="Descrição"
            sortable
            style={{ width: '30%', maxWidth: '30%' }}
            headerClassName="descri_vei"
          />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            field="cor_vei"
            header="Cor"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="cor_vei"
          />
        )
      },
      {
        key: 'anofab_vei',
        component: (
          <Column
            header="Ano"
            field="anofab_vei"
            body={data => data.anofab_vei + '/' + data.anomod_vei}
            style={{ width: '10%', maxWidth: '10%' }}
            sortable
            headerClassName="anofab_vei"
          />
        )
      }
    ])
  }

  handleRowSelect (data) {
    if (data.placa_vei) {
      this.props.history.push(`veiculo/usado/${data.codigo_vei}`)
    } else {
      this.props.history.push(`veiculo/novo/${data.codigo_vei}`)
    }
  }

  async handleUploadExcel (event, openLogExcelVeiculosModal, onFilter) {
    const file = event.target.files[0]
    dispatch(startLoading())
    try {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = async function () {
        const fileBase64 = reader.result.split(',')[1]
        const workBook = XLSX.read(fileBase64)
        const dadosDosVeiculos = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]])

        const nomesPropriedadesDadosVeiculo = Object.getOwnPropertyNames(dadosDosVeiculos[0])
        const nomesPropriedadesNecessarias = [
          '__rowNum__',
          'placa',
          'renavam',
          'kmAtual',
          'anoFabricacao',
          'anoModelo',
          'numeroCrv',
          'codigoSegurancaCrv',
          'tipoCrv',
          'descricao',
          'cor'
        ]

        const logResultadoVeiculos = []

        if (CommonHelper.arrayEquals(nomesPropriedadesDadosVeiculo, nomesPropriedadesNecessarias)) {
          for (const dadosVeiculo of dadosDosVeiculos) {
            const veiculo = excelToVeiculoDTO(dadosVeiculo)

            switch (veiculo.tipoCrv.codigo_tcr) {
            case 'A':
            case 'B':
            case 'D':
            case 'V':
              try {
                await VeiculoService.save(veiculo, false)
                const formattedPlaca = formatPlaca(veiculo.placaVei)
                logResultadoVeiculos.push(`Placa ${formattedPlaca}: Cadastrado com sucesso!`)
              } catch (error) {
                const erroMensagem = error.message.replace(`Placa: ${veiculo.placaVei}`, '')
                logResultadoVeiculos.push(`Placa ${veiculo.placaVei}: ${erroMensagem || 'Ocorreu um erro inesperado!'}`)
              }
              break
            default:
              logResultadoVeiculos.push(`Placa ${veiculo.placaVei}: Tipo do CRV inválido!`)
              break
            }
          }
        } else {
          logResultadoVeiculos.push('Formato do excel inválido!')
        }

        await onFilter()
        openLogExcelVeiculosModal(logResultadoVeiculos)
        dispatch(stopLoading())
      }

      reader.onerror = function (error) {
        console.log(error.message)
        showErrorMessage('Ocorreu um erro inesperado ao tentar ler o arquivo!')
      }

      const fileInput = document.getElementById('file-upload')
      fileInput.value = null
    } catch (error) {
      showErrorMessage('Ocorreu um erro inesperado ao tentar criar os veículos!')
    }
  }

  openLogExcelVeiculosModal (logExcelVeiculos) {
    this.setState({ logExcelVeiculos, visibleLogExcelVeiculosModal: true })
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Veículos</h4>
          <div className="page-header-buttons flex align-items-center">
            <BsQuestionCircle
              className="cursor-pointer icone-info veiculosList mr-2"
              onClick={() =>
                window.open('https://sances.movidesk.com/kb/article/331768/formatacao-do-excel-para-cadastro-de-veiculos-usados')
              }
            />
            <label htmlFor="file-upload" className="button-file-upload mr-2">
              <i className="pi pi-cloud-upload mr-2"></i> Importar excel para veículo usado
            </label>
            <input
              className="xml-inputfile"
              id="file-upload"
              type="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={async (event) => await this.handleUploadExcel(event, this.openLogExcelVeiculosModal.bind(this), this.onFilter.bind(this))}
            />
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={() => this.setState({ visibleAdicionarVeiculoModal: true })}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            onRowSelect={(e) => this.handleRowSelect(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map(column => column.component)}
          </DataList>
        </Container>
        <AdicionarVeiculoModal
          visible={state.visibleAdicionarVeiculoModal}
          onHide={() => this.setState({ visibleAdicionarVeiculoModal: false })}
        />
        <Modal
          visible={state.visibleLogExcelVeiculosModal}
          onHide={() => this.setState({ visibleLogExcelVeiculosModal: false })}
          header="Log do excel enviado"
          width={60}
        >
          {state.logExcelVeiculos.map((log, idx) => {
            return (
              <div key={idx}>
                <span> • {log}</span>
              </div>
            )
          })}
        </Modal>
      </Page>
    )
  }
}
