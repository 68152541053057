import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { formatCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import XmlConverter from 'x2js'
import { FileUpload } from 'primereact/fileupload'
import DateInput from '../../../../components/inputs/DateInput'
import TransferenciaMontadoraService from '../../../../services/montadora/TransferenciaMontadoraService'

const ReceberTransferenciaIteNaMontadoraModal = ({ visible, onHide, veiculoParaReceber, getAll }) => {
  const [valor, setValor] = useState(veiculoParaReceber.valorVeiculo || 0)
  const [chaveRemessa, setChaveRemessa] = useState('')
  const [chaveProduto, setChaveProduto] = useState('')
  const [chaveServico, setChaveServico] = useState('')
  const [dataTransferencia, setDataTransferencia] = useState(new Date())

  useEffect(() => {
    if (veiculoParaReceber.valorVeiculo) {
      setValor(veiculoParaReceber.valorVeiculo)
    }
  }, [veiculoParaReceber])

  const handleReceberVeiculo = async () => {
    try {
      if (valor < 0.01) {
        showErrorMessage('Por favor informar um valor válido')
        return
      }

      await TransferenciaMontadoraService.receberTransferenciaVindoITE(chaveRemessa, chaveProduto, chaveServico, veiculoParaReceber.id, valor, dataTransferencia)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValor(formatCurrencyToDecimal(novoValor))
  }

  function hideModal () {
    setValor(0)
    setDataTransferencia(new Date())
    setChaveProduto('')
    setChaveRemessa('')
    setChaveServico('')
    onHide()
  }

  async function handleUploadChaveXml (event, tipoChave) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', (event) => {
          readFile(event, tipoChave)
        })

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event, tipoChave) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    switch (tipoChave) {
    case 'chaveRemessa':
      setChaveRemessa(chaveNFe)
      break
    case 'chaveProduto':
      setChaveProduto(chaveNFe)
      break
    case 'chaveServico':
      setChaveServico(chaveNFe)
      break
    }
  }

  return (
    <Modal
      header="Receber transferência de ITE"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleReceberVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <TextInput
            label="ITE"
            placeholder="ITE"
            className="inputfield w-full"
            value={`${formatCnpj(veiculoParaReceber.cnpjEstabelecimentoAutorizador)} - ${veiculoParaReceber.nomeEstabelecimentoAutorizador}`}
            disabled={true}
          />
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            label="Valor"
            placeholder="Valor do veículo"
            className="inputfield w-full"
            onChange={(e) => handleChangeValor(e)}
            value={formatToCurrency(valor)}
          />
        </div>
        <div className="field col-12 md:col-6">
          <DateInput
            label="Data da venda"
            placeholder="Data de saída"
            value={dataTransferencia}
            onChange={(e) => setDataTransferencia(e.value)}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Retorno de remessa)"
            className="inputfield w-full"
            value={chaveRemessa}
            onChange={(e) => setChaveRemessa(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota de remessa"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event, 'chaveRemessa')}
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Produto)"
            className="inputfield w-full"
            value={chaveProduto}
            onChange={(e) => setChaveProduto(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota do produto"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event, 'chaveProduto')}
          />
        </div>
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso (Serviço)"
            className="inputfield w-full"
            value={chaveServico}
            onChange={(e) => setChaveServico(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex flex-column justify-content-end">
          <FileUpload
            chooseLabel="XML da nota de serviço"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={(event) => handleUploadChaveXml(event, 'chaveServico')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ReceberTransferenciaIteNaMontadoraModal
