import React, { useEffect, useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import Modal from '../../components/utils/Modal'
import CommonHelper from '../../helpers/CommonHelper'
import { getStore } from '../../redux/store'
import UsuarioService from '../../services/cadastro/UsuarioService'

const TrocarEmpresaModal = ({ visible, handleSelectCompany, onHide, onConfirm, companies, selectedCompany, emailUsuario }) => {
  const [activeCompany, setActiveCompany] = useState(selectedCompany)
  const [modalBody, setModalBody] = useState(<></>)
  const [filtroCompanies, setFiltroCompanies] = useState('')
  const [empresas, setEmpresas] = useState(companies || [])

  const renderCompaniesList = (filtro) => {
    if (!empresas) {
      return
    }

    const empresaLogada = getStore().empresaLogada

    const modalBody = empresas.filter(company => company.nomfan_emp.toUpperCase().includes(filtro.toUpperCase()) || company.cnpj_emp.includes(filtro) || CommonHelper.formatDocument(company.cnpj_emp).includes(filtro))
      .map((company, idx) => {
        let matriz = empresas.find(empresa => empresa.codigo_emp === company.codmat_emp)

        if (company.codmat_emp === empresaLogada.codigo_emp) {
          matriz = empresaLogada
        }

        return (
          <div
            key={company.codigo_emp}
            className={'list-selection ' + (idx === 0 ? 'first' : '')}
            onClick={async () => {
              await handleSelectCompany(company)
            }}
          >
            <div>
              <p>
                {((CommonHelper.isUsuarioAdmin() || CommonHelper.isUsuarioDespachante() || emailUsuario === 'suporte@sances.com.br') && matriz)
                  ? `(${matriz.nomfan_emp}) - `
                  : ''}
                {company.nomfan_emp} - {CommonHelper.formatDocument(company.cnpj_emp)}
                {company.ativo_emp ? '' : ' (INATIVA)'}
              </p>
            </div>
          </div>
        )
      })

    setModalBody(modalBody)
  }

  useEffect(async () => {
    if (visible && !companies) {
      try {
        const empresasAcessoUsuario = await UsuarioService.empresasAcessoUsuarioLogado()
        const companies = empresasAcessoUsuario.filter(company => company.codigo_emp !== getStore().empresaLogada.codigo_emp)
        setEmpresas(companies)
      } catch (e) {
        console.error(e)
      }
    }
  }, [visible])

  useEffect(() => {
    setEmpresas(companies)
  }, [companies])

  useEffect(() => {
    renderCompaniesList('')
  }, [empresas])

  useEffect(() => {
    setActiveCompany(selectedCompany)
  }, [selectedCompany])

  useEffect(() => {
    if (activeCompany) {
      onConfirm()
    }
  }, [activeCompany])

  return (
    <Modal
      header="Selecionar empresa"
      width={50}
      visible={visible}
      onHide={onHide}
    >
      <div className="field col-12">
        <TextInput
          value={filtroCompanies}
          placeholder="Filtrar por nome da empresa"
          className="inputfield w-full"
          onChange={async (e) => {
            setFiltroCompanies(e.target.value)
            renderCompaniesList(e.target.value)
          }}
        />
      </div>
      {modalBody}
    </Modal>
  )
}

export default TrocarEmpresaModal
