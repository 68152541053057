import * as Yup from 'yup'

export const RegistroValidator = Yup.object().shape({
  empresaNomefantasia: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioNome: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioTelefone: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioEmail: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cnpjEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  nomresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emaresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  telresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  custoTransacaoNovos: Yup.number()
    .nullable(),

  custoTransacaoUsados: Yup.number()
    .nullable()
})
