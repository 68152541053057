import ServiceBase from '../base/ServiceBase'

export default class ConfigService {
  static async getAll () {
    return await ServiceBase.getBase('config/getAll')
  }

  static async getConfigGruByGrupoLogado () {
    return await ServiceBase.getBase('config/getConfigGruByGrupoLogado')
  }

  static async getConfigEmpByGrupoLogado () {
    return await ServiceBase.getBase('config/getConfigEmpByGrupoLogado')
  }

  static async saveConfig (data) {
    return await ServiceBase.postBase('config/saveConfig', data)
  }

  static async createConfigEmpAndConfigGru (codigoEmp) {
    return await ServiceBase.postBase(`config/createConfigEmpAndConfigGru/${codigoEmp}`)
  }
}
