import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../components/utils/Message'
import { getEstoqueRenaveDTO } from '../../dtos/renave/EstoqueRenaveDTO'
import VeiculoModal from '../../forms/cadastro/veiculo/VeiculoModal'
import CommonHelper from '../../helpers/CommonHelper'
import { formatCnpj, formatDescricaoVeiculo } from '../../helpers/formaters'
import EntregasConcessionariaService from '../../services/vendaDireta/EntregasConcessionariaService'

class EntregaVeiculosConcessionaria extends List {
  constructor (props) {
    super(
      props,
      getEstoqueRenaveDTO,
      EntregasConcessionariaService,
      '',
      '',
      'pendentes'
    )

    this.state = {
      ...this.state,
      visibleVeiculoModal: false
    }

    this.setColumns([
      {
        key: 'id',
        component: (
          <Column
            style={{ width: '10%' }}
            header="Id Estoque"
            field="id"
            sortable
            headerClassName="id"
          />
        )
      },
      {
        key: 'chassi',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Veiculo"
            body={data => formatDescricaoVeiculo(data.chassi, data.descricaoMarcaModelo)}
            field="chassi"
            sortable
            headerClassName="chassi"
          />
        )
      },
      {
        key: 'entradaEstoque.dataHora',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Vendedor"
            body={data => (data.nomeVendedora ? (data.nomeVendedora + ' - ') : '') + formatCnpj(data.cnpjEstabelecimentoVendedor)}
            field="entradaEstoque.dataHora"
            sortable
            headerClassName="entradaEstoque.dataHora"
          />
        )
      },
      {
        key: 'cnpjEstabelecimentoEntregador',
        component: (
          <Column
            style={{ width: '20%' }}
            header="Entregador"
            body={data => (data.nomeEntregadora ? (data.nomeEntregadora + ' - ') : '') + formatCnpj(data.cnpjEstabelecimentoEntregador)}
            field="cnpjEstabelecimentoEntregador"
            sortable
            headerClassName="cnpjEstabelecimentoEntregador"
          />
        )
      },
      {
        key: 'realizacaoEntrega.dataHoraRegistro',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Entrega"
            body={data => CommonHelper.dateToBrazilian(data.realizacaoEntrega?.dataHoraRegistro)}
            field="realizacaoEntrega.dataHoraRegistro"
            sortable
            headerClassName="realizacaoEntrega.dataHoraRegistro"
          />
        )
      },
      {
        key: 'cancelamentoEntrega.dataHoraRegistro',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Cancelamento"
            body={data => CommonHelper.dateToBrazilian(data.cancelamentoEntrega?.dataHoraRegistro)}
            field="cancelamentoEntrega.dataHoraRegistro"
            sortable
            headerClassName="cancelamentoEntrega.dataHoraRegistro"
          />
        )
      }
    ])
  }

  async entregarVeiculo (idEntrega) {
    try {
      await EntregasConcessionariaService.entregarVeiculo(idEntrega)
      await this.onFilter()
      showSuccessMessage('Veículo entregue com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar entregar o veículo!')
    }
  }

  async cancelarEntrega (idEntrega) {
    try {
      await EntregasConcessionariaService.cancelarEntrega(idEntrega)
      await this.onFilter()
      showSuccessMessage('Entrega cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a entrega!')
    }
  }

  render () {
    const state = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'pendentes':
        filter.situacaoVeiculos = 'pendentes'
        break
      case 'realizados':
        filter.situacaoVeiculos = 'realizados'
        break
      case 'cancelados':
        filter.situacaoVeiculos = 'cancelados'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    const rowExpansionTemplate = (data) => {
      return (
        <>
          <div className="expansion row-expansion flex flex-column justify-content-start align-items-start">
            <label>
            Veículo:
              <span className="black underline cursor-pointer ml-1" onClick={() => {
                if (!data.codigoVeiculo) {
                  showWarnMessage('Não foi possível encontrar o veículo nos cadastros, por favor cadastre o veículo!')
                  return
                }

                this.setState({ codigoVeiculo: data.codigoVeiculo, visibleVeiculoModal: true })
              }}>
                {data.chassi}
              </span>
            </label>
          </div>
          <div className="row-expansion flex justify-content-end mr-3">
            {data.estado === 'PENDENTE' && (
              <Button
                label="Entregar"
                className="mr-2"
                onClick={async () => await this.entregarVeiculo(data.id)}
              />
            )}
            {data.estado === 'REALIZADA' && (
              <Button
                label="Cancelar entrega"
                className="mr-2"
                onClick={async () => await this.cancelarEntrega(data.id)}
              />
            )}
          </div>
        </>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Entregas de veículos na concessionária</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'pendentes' }, 'filter-option')}
              onClick={() => onSelectOption('pendentes')}>
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">
                {state.quantidades.quantidadePendentes}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'realizados' }, 'filter-option')}
              onClick={() => onSelectOption('realizados')}>
              <span className="option-label">Realizados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeRealizados}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'cancelados' }, 'filter-option')}
              onClick={() => onSelectOption('cancelados')}>
              <span className="option-label">Cancelados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeCancelados}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Procurar entregas"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <VeiculoModal
          visible={state.visibleVeiculoModal}
          hide={() => this.setState({ visibleVeiculoModal: false })}
          isVeiculoNovo={true}
          primaryKey={state.codigoVeiculo}
          onRegister={async () => await this.onFilter()}
          edicaoHabilitada={true}
        />
      </Page>
    )
  }
}

export default EntregaVeiculosConcessionaria
