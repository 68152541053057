import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import CheckBoxInput from '../../../../components/inputs/CheckBoxInput'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { getPessoaDTO } from '../../../../dtos/cadastro/PessoaDTO'
import { formatCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import EmpresasAndPessoasJuridicasService from '../../../../services/cadastro/EmpresasAndPessoasJuridicasService'
import VeiculosTransferenciaService from '../../../../services/zeroKm/veiculos/VeiculosTransferenciaService'

const TransferenciaEstabelecimentoModal = ({ visible, onHide, veiculoParaTransferir, getAll }) => {
  const [estabelecimento, setEstabelecimento] = useState(null)
  const [sugestaoEstabelecimentos, setSugestaoEstabelecimentos] = useState([])
  const [valor, setValor] = useState(veiculoParaTransferir.valorVeiculo || 0)
  const [cancelamentoDeTransferencia, setCancelamentoDeTransferencia] = useState(false)

  useEffect(() => {
    if (veiculoParaTransferir.valorVeiculo) {
      setValor(veiculoParaTransferir.valorVeiculo)
    }
  }, [veiculoParaTransferir])

  const sugerirEstabelecimentos = async () => {
    try {
      const empresasAndPessoasJuridicasService = new EmpresasAndPessoasJuridicasService()

      const estabelecimentos = (await empresasAndPessoasJuridicasService.filter(`descricaoFiltro=${estabelecimento.nome}`)).data

      setSugestaoEstabelecimentos(estabelecimentos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um estabelecimento!')
    }
  }

  const handleTransferirVeiculo = async () => {
    try {
      if (!estabelecimento?.cnpj) {
        showErrorMessage('Por favor selecionar um estabelecimento válido')
        return
      }

      if (valor < 0.01) {
        showErrorMessage('Por favor informar um valor válido')
        return
      }

      await VeiculosTransferenciaService.autorizarTransferenciaParaEstabelecimento(estabelecimento.cnpj, veiculoParaTransferir.id, valor, cancelamentoDeTransferencia)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  function handleChangeValor (event) {
    const novoValor = event.target.value

    setValor(formatCurrencyToDecimal(novoValor))
  }

  function handleChangeEstabelecimento (event) {
    setEstabelecimento({ nome: event.target.value })
  }

  function hideModal () {
    setEstabelecimento(null)
    setValor(0)
    onHide()
  }

  return (
    <Modal
      header="Autorizar transferência para estabelecimento"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleTransferirVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <SearchInput
            //AutoComplete
            label="Estabelecimento"
            field="nome"
            value={estabelecimento?.nome && estabelecimento}
            suggestions={sugestaoEstabelecimentos}
            completeMethod={sugerirEstabelecimentos}
            onChange={handleChangeEstabelecimento}
            onSelect={(e) => setEstabelecimento(e.value)}
            className="inputfield w-full"
            //FiltroModal
            filtroTitle="Pesquisa de pessoa jurídica e empresas"
            service={EmpresasAndPessoasJuridicasService}
            model={getPessoaDTO}
            columns={[
              { campo: 'nome', nome: 'Nome' },
              { campo: 'cnpj', nome: 'CNPJ', format: formatCnpj }
            ]}
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            label="Valor"
            placeholder="Valor do veículo"
            className="inputfield w-full"
            onChange={(e) => handleChangeValor(e)}
            value={formatToCurrency(valor)}
          />
        </div>
        <div className="field col-12 md:col-9 flex align-items-center mt-2">
          <CheckBoxInput
            label="Cancelamento de transferência"
            value={cancelamentoDeTransferencia}
            checked={cancelamentoDeTransferencia}
            onChange={() => setCancelamentoDeTransferencia(!cancelamentoDeTransferencia)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default TransferenciaEstabelecimentoModal
