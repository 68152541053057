import './_veiculoForm.scss'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '../../../components/layout/Container'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import Confirm from '../../../components/utils/Confirm'
import VeiculoService from '../../../services/cadastro/VeiculoService'
import { VeiculoValidator } from './VeiculoValidator'
import DadosPrincipaisVei from './DadosPrincipaisVei'
import DadosAdicionaisVei from './DadosAdicionaisVei'

const VeiculoForm = ({ isNew, model, onDelete }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [tipoVeiculo, setTipoVeiculo] = useState('novo')
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setTipoVeiculo(history.location.pathname.includes('novo') ? 'novo' : 'usado')

    if (isNovo) setEdicaoHabilitada(true)
  }, [])

  const isVeiculoNovo = () => {
    return tipoVeiculo === 'novo' ? true : false
  }

  const isInacabado = () => {
    return !form.values.acabadoVei
  }

  const handleSubmit = async (data) => {
    try {
      const veiculo = await VeiculoService.save(data)

      history.push(`/veiculo/${isVeiculoNovo() ? 'novo' : 'usado'}/${veiculo.codigo_vei}`)
      form.setFieldValue('codigoVei', veiculo.codigo_vei)

      setIsNovo(false)
      showSuccessMessage('Registro salvo com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro!')
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const errors = await form.validateForm(form.values)

    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const deleteForm = async () => {
    onDelete(form.values.codigoVei)
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: VeiculoValidator,
    onSubmit: handleSubmit
  })

  return (
    <Container col="12">
      <form onSubmit={submitForm}>
        <div className="flex justify-content-between form-header">
          <h1>Veículo {isVeiculoNovo() ? 'novo' : 'usado'}</h1>
          {(!isNovo && isInacabado()) && (
            <span className="colored-label orange label-veiculo-inacabado">Veículo inacabado</span>
          )}
          {!isNovo && (
            <div className="flex justify-content-between">
              <Button
                label="Excluir"
                icon="pi pi-times"
                type="button"
                onClick={() => setVisibleConfirm(true)}
                className="p-button-danger mr-2"
              />
              <Button
                type="button"
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              />
            </div>
          )}
        </div>
        <div className="grid form-body">
          <AccordionContainer>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisVei
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                isVeiculoNovo={isVeiculoNovo()}
              />
            </AccordionPage>
            <AccordionPage header="Informações adicionais">
              <DadosAdicionaisVei
                form={form}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end form-footer">
          {edicaoHabilitada && (
            <Button
              type="submit"
              label="Salvar"
              className="p-button"
            />
          )}
        </div>
      </form>
      <Confirm
        visible={visibleConfirm}
        onConfirm={() => deleteForm()}
        onCancel={() => setVisibleConfirm(false)}
        title="Confirmação"
        description="Deseja realmente excluir este registro?"
      />
    </Container>
  )
}

export default VeiculoForm
