import * as Yup from 'yup'

export const PessoaValidator = Yup.object().shape({
  nomrazPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cgccpfPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emailPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cepPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  endPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  bairroPes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  codmunPes: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  codufPes: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!')
})
