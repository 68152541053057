import React, { useEffect, useState } from 'react'
import AccordionContainer from '../../components/layout/AccordionContainer'
import AccordionPage from '../../components/layout/AccordionPage'
import Container from '../../components/layout/Container'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import EmpresaService from '../../services/cadastro/EmpresaService'
import ConfigService from '../../services/config/ConfigService'
import ConfigPage from './ConfigPage'

const Configuracoes = () => {
  const [loading, setLoading] = useState(true)
  const [configs, setConfigs] = useState([])
  const [optionValues, setOptionValues] = useState([])
  const [empresasAcesso, setEmpresasAcesso] = useState([])
  const [configEmpPorGrupo, setConfigEmpPorGrupo] = useState([])
  const [configEmpPorEmpresa, setConfigEmpPorEmpresa] = useState([])

  const optionsSelect = ['Por Empresa', 'Por Grupo']

  useEffect(async () => {
    const configs = await ConfigService.getAll()
    const configGrus = await ConfigService.getConfigGruByGrupoLogado()
    const configEmps = await ConfigService.getConfigEmpByGrupoLogado()

    setConfigEmpPorEmpresa(configs.map(config =>
      configEmps.filter(configEmp => configEmp.codcon_coe === config.codigo_con && configEmp.isgru_coe === 0)
        .sort((a, b) => a.codemp_coe - b.codemp_coe)
    ))
    setConfigEmpPorGrupo(configs.map(config =>
      configEmps.filter(configEmp => configEmp.codcon_coe === config.codigo_con && configEmp.isgru_coe === 1).shift()
    ))
    setEmpresasAcesso(await EmpresaService.empresasAcessoByEmpresaLogada())
    setOptionValues(configGrus.map(configGru => configGru.isgru_cog ? 'Por Grupo' : 'Por Empresa'))
    setConfigs(configs)
  }, [])

  const getConfigEmpPorEmpresaAndPorGrupo = async () => {
    const configEmps = await ConfigService.getConfigEmpByGrupoLogado()
    setConfigEmpPorEmpresa(configs.map(config => configEmps.filter(configEmp => configEmp.codcon_coe === config.codigo_con && configEmp.isgru_coe === 0)
      .sort((a, b) => a.codemp_coe - b.codemp_coe)
    ))
    setConfigEmpPorGrupo(configs.map(config =>
      configEmps.filter(configEmp => configEmp.codcon_coe === config.codigo_con && configEmp.isgru_coe === 1).shift()
    ))
  }

  useEffect(() => {
    setLoading(false)
  }, [configs[0]])

  const saveConfig = async (config, idxConfig) => {
    try {
      const data = {
        isGrupo: optionValues[idxConfig] === 'Por Empresa' ? false : true,
        codigoConfig: config.codigo_con
      }

      if (data.isGrupo)
        data.valorConfig = (Boolean)(configEmpPorGrupo[idxConfig].valor_coe)
      else
        data.arrayConfigEmps = [...configEmpPorEmpresa[idxConfig]]

      await ConfigService.saveConfig(data)
      await getConfigEmpPorEmpresaAndPorGrupo()
      showSuccessMessage(`Valor da config: "${config.descri_con}" atualizado com sucesso!`)
    } catch (error) {
      showErrorMessage(`Erro ao atualizar o valor da config: "${config.descri_con}"!`)
    }
  }

  const configEmpPorGrupoChange = (value, idxConfig) => {
    setConfigEmpPorGrupo(configEmpPorGrupo.map((configEmp, idxEmp) => idxEmp === idxConfig ? value : configEmp))
  }

  const setArrayConfigEmps = (novoArray, idxConfig) => {
    setConfigEmpPorEmpresa(configEmpPorEmpresa.map((configEmp, idxConfigEmp) => idxConfig === idxConfigEmp ? novoArray : configEmp))
  }

  const optionValueChange = (value, idxConfig) => {
    setOptionValues(optionValues.map((option, idxOption) => idxConfig === idxOption ? value : option))
  }

  return (
    <Container>
      {!loading && (
        <>
          <div className="flex justify-content-between form-header">
            <h1>Configurações</h1>
          </div>
          <div className="grid form-body">
            {configs.map((config, idxConfig) => {
              return (
                <AccordionContainer key={idxConfig}>
                  <AccordionPage key={idxConfig + 1} header={config.descri_con}>
                    <ConfigPage
                      empresasAcesso={empresasAcesso}
                      saveConfig={async () => await saveConfig(config, idxConfig)}
                      configEmpPorGrupo={configEmpPorGrupo[idxConfig]}
                      configEmpPorGrupoChange={(value) => configEmpPorGrupoChange(value, idxConfig)}
                      arrayConfigEmps={configEmpPorEmpresa[idxConfig]}
                      setArrayConfigEmps={(novoArray) => setArrayConfigEmps(novoArray, idxConfig)}
                      optionsSelect={optionsSelect}
                      optionValue={optionValues[idxConfig]}
                      optionValueChange={(value) => optionValueChange(value, idxConfig)}
                    />
                  </AccordionPage>
                </AccordionContainer>
              )
            })}
          </div>
        </>
      )}
    </Container>
  )
}

export default Configuracoes
