import { server } from '../../../helpers/server'
import ServiceBase from '../../base/ServiceBase'

export default class LoteXmlService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`xml/retornaListaLoteXml?page=${page}&perPage=${perPage}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`xml/getLote/${id}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`xml/retornaListaLoteXml?${query}&page=${page}&perPage=${perPage}`)
  }

  static async processarXmls (listaXmls) {
    const formData = new FormData()

    for (const xml of listaXmls) {
      formData.append('arquivoxml', xml)
    }

    const response = await server().post('xml/processarxml', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

    return response
  }
}
