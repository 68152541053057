import React from 'react'
import Page from '../../components/layout/Page'
import { Crud } from '../../classes/Crud'
import queryString from 'query-string'
import { getRegistroDTO } from '../../dtos/registro/RegistroDTO'
import RegistroForm from '../../forms/registro/RegistroForm'
import RegistroService from '../../services/publicos/RegistroService'

export class Registro extends Crud {
  constructor (props) {
    super(props, getRegistroDTO, RegistroService, 'login')

    this.state = {
      ...this.state,
      isConviteLojista: false,
      codigoEmpDespachante: null,
      nomeEmpDespachante: null,
      codigoConvite: null
    }
  }

  handleQueryParamas () {
    if (!this.props.location.search) return

    const { nome, email, telefone } = queryString.parse(this.props.location.search)
    const { model } = this.state

    model.usuarioNome = nome
    model.usuarioEmail = email
    model.usuarioTelefone = telefone
  }

  handleConviteLojista () {
    const codigoEmpDespachante = this.props.match?.params?.codigoEmpDespachante
    const nomeEmpDespachante = this.props.match?.params?.nomeEmpDespachante
    const codigoConvite = this.props.match?.params?.codigoConvite

    if (codigoEmpDespachante && !this.state.isConviteLojista) {
      this.setState({ isConviteLojista: true, codigoEmpDespachante, nomeEmpDespachante, codigoConvite })
    }
  }

  componentDidUpdate () {
    this.handleQueryParamas()
    this.handleConviteLojista()
  }

  render () {
    const state = this.state
    return (
      <Page>
        <div className="layout-registro-panel">
          {(state.dataLoaded || state.isNew) && (
            <RegistroForm
              model={state.model}
              isConviteLojista={state.isConviteLojista}
              codigoEmpDespachante={state.codigoEmpDespachante}
              nomeEmpDespachante={state.nomeEmpDespachante}
              codigoConvite={state.codigoConvite}
            />
          )}
        </div>
      </Page>
    )
  }
}
