import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class EntregasMontadoraService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`montadora/entregas/montadora?page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`montadora/entregas/montadora?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }
}
