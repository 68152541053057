import React from 'react'
import Modal from '../../../components/utils/Modal'
import AssinarAtpve from './AssinarAtpve'

function AssinarAtpveModal ({ visible, onHide, veiculo }) {
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="ATPV-E ASSINADO"
      width={75}
    >
      <AssinarAtpve
        veiculo={veiculo}
      />
    </Modal>
  )
}

export default AssinarAtpveModal
