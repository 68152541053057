import List from '../../../classes/List'
import React from 'react'
import PessoaService from '../../../services/cadastro/PessoaService'
import { Column } from 'primereact/column'
import { formatCnpj, formatCpf, formatCpfOrCnpj } from '../../../helpers/formaters'
import DataList from '../../../components/utils/DataList'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Button } from 'primereact/button'
import CommonHelper from '../../../helpers/CommonHelper'
import classNames from 'classnames'
import { excelToPessoaDTO, getPessoaDTO } from '../../../dtos/cadastro/PessoaDTO'
import { BsQuestionCircle } from 'react-icons/bs'
import { dispatch } from '../../../redux/store'
import { startLoading, stopLoading } from '../../../redux/actions'
import { showErrorMessage } from '../../../components/utils/Message'
import XLSX from 'xlsx'
import Modal from '../../../components/utils/Modal'
import CEPService from '../../../services/localidade/CEPService'
import EstadosService from '../../../services/localidade/EstadosService'
import MunicipioService from '../../../services/localidade/MunicipioService'

export default class PessoaList extends List {
  constructor (props) {
    super(
      props,
      getPessoaDTO,
      PessoaService,
      'pessoa',
      'codigo_pes'
    )

    this.state = {
      ...this.state,
      visibleLogExcelModal: false,
      logExcel: []
    }

    this.setColumns([
      {
        key: 'nomraz_pes',
        component: (
          <Column
            field="nomraz_pes"
            header="Nome"
            sortable={true}
            style={{ width: '30%', maxWidth: '30%' }}
            headerClassName="nomraz_pes"
          />
        )
      },
      {
        key: 'cgccpf_pes',
        component: (
          <Column
            field="cgccpf_pes"
            header="CPF/CNPJ"
            sortable={true}
            body={(data) => data.cgccpf_pes.length === 11 ? formatCpf(data.cgccpf_pes) : formatCnpj(data.cgccpf_pes)}
            style={{ width: '20%', maxWidth: '20%' }}
            headerClassName="cgccpf_pes"
          />
        )
      },
      {
        key: 'tipo_pes',
        component: (
          <Column
            field="tipo_pes"
            header="Tipo"
            sortable={true}
            body={(data) => 'Pessoa ' + (data.tipo_pes === 'F' ? 'Física' : 'Jurídica')}
            style={{ width: '20%', maxWidth: '20%' }}
            headerClassName="tipo_pes"
          />
        )
      },
      {
        key: 'email_pes',
        component: (
          <Column
            field="email_pes"
            header="Email"
            sortable={true}
            style={{ width: '30%', maxWidth: '30%' }}
            headerClassName="email_pes"
          />
        )
      }
    ])
  }

  async handleUploadExcel (event, openLogExcelModal, onFilter) {
    const file = event.target.files[0]

    dispatch(startLoading())

    try {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = async function () {
        const fileBase64 = reader.result.split(',')[1]
        const workBook = XLSX.read(fileBase64)
        const dadosDasPessoas = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]])

        const logResultadoExcel = []

        let formatoValido = true

        for (const row of dadosDasPessoas) {
          const nomesPropriedadesRow = Object.getOwnPropertyNames(row)

          if (
            !nomesPropriedadesRow.includes('nome') ||
            !nomesPropriedadesRow.includes('cpfOuCnpj') ||
            !nomesPropriedadesRow.includes('email') ||
            !nomesPropriedadesRow.includes('cep')
          ) {
            formatoValido = false
          }
        }

        if (formatoValido) {
          for (const dadosPessoa of dadosDasPessoas) {
            const pessoa = excelToPessoaDTO(dadosPessoa)

            try {
              const endereco = await CEPService.getCEP(pessoa.cepPes, false)
              const estado = await EstadosService.getPorSigla(endereco.uf)
              const municipio = await MunicipioService.getPorId(Number(endereco.ibge))

              pessoa.endPes = endereco.logradouro
              pessoa.bairroPes = endereco.bairro
              pessoa.codufPes = estado.codigo_uf
              pessoa.codmunPes = municipio.codigo_mun
            } catch (error) {
              logResultadoExcel.push(`CPF/CNPJ ${pessoa.cgccpfPes}: CEP inválido!`)
              continue
            }

            try {
              await PessoaService.save(pessoa, false)
              const cpfOuCnpj = formatCpfOrCnpj(pessoa.cgccpfPes)

              logResultadoExcel.push(`CPF/CNPJ ${cpfOuCnpj}: Cadastrado com sucesso!`)
            } catch (error) {
              const erroMensagem = error.message.replace(`CPF/CNPJ: ${pessoa.cgccpfPes} `, '')
              logResultadoExcel.push(`CPF/CNPJ ${pessoa.cgccpfPes}: ${erroMensagem || 'Ocorreu um erro inesperado!'}`)
            }
          }
        } else {
          logResultadoExcel.push('Formato do excel inválido!')
        }

        await onFilter()
        openLogExcelModal(logResultadoExcel)
        dispatch(stopLoading())
      }

      reader.onerror = function (error) {
        console.log(error.message)
        showErrorMessage('Ocorreu um erro inesperado ao tentar ler o arquivo!')
      }

      const fileInput = document.getElementById('file-upload')
      fileInput.value = null
    } catch (error) {
      showErrorMessage('Ocorreu um erro inesperado ao tentar criar as pessoas!')
    }
  }

  openLogExcelModal (logExcel) {
    this.setState({ logExcel, visibleLogExcelModal: true })
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Pessoas</h4>
          <div className="page-header-buttons">
            <BsQuestionCircle
              className="cursor-pointer icone-info veiculosList mr-2"
              onClick={() =>
                window.open('https://sances.movidesk.com/kb/article/332144/formatacao-do-excel-para-cadastro-de-pessoas')
              }
            />
            <label htmlFor="file-upload" className="button-file-upload mr-2">
              <i className="pi pi-cloud-upload mr-2"></i> Importar excel
            </label>
            <input
              className="xml-inputfile"
              id="file-upload"
              type="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={async (event) => await this.handleUploadExcel(event, this.openLogExcelModal.bind(this), this.onFilter.bind(this))}
            />
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todas</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar pessoas"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map(column => column.component)}
          </DataList>
        </Container>
        <Modal
          visible={state.visibleLogExcelModal}
          onHide={() => this.setState({ visibleLogExcelModal: false })}
          header="Log do excel enviado"
          width={60}
        >
          {state.logExcel.map((log, idx) => {
            return (
              <div key={idx}>
                <span>{log}</span>
              </div>
            )
          })}
        </Modal>
      </Page>
    )
  }
}
