import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

function useOutsideAlerter (ref, command) {
  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(() => {
          command()
        }, 200)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function OutsideAlerter (props) {
  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef, props.command)

  return <div ref={wrapperRef}>{props.children}</div>
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
}

export default OutsideAlerter
