import ServiceBase from '../../base/ServiceBase'

export default class XmlImportSuporteService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`xml/getXmlImports?&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`xml/getXmlImports?${query}&page=${page}&perPage=${perPage}`)
  }
}
