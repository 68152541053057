import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import SaidaZeroKmService from '../../../../services/zeroKm/veiculos/SaidaZeroKmService'
import Confirm from '../../../../components/utils/Confirm'
import EstoqueRenaveService from '../../../../services/suporte/EstoqueRenaveService'

const DevolverVeiculoModal = ({ visible, onHide, chassi, idEstoque, idEstoqueOrigemTransferencia, getAll }) => {
  const [chaveNfe, setChaveNfe] = useState('')
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const [listaMovChassi, setListaMovChassi] = useState('')

  const hide = () => {
    setChaveNfe('')
    onHide()
  }

  useEffect(async () => {
    if (visible) {
      await getListaMovChassi()
    }
  }, [visible])

  const handleDevolverVeiculo = async () => {
    try {
      setVisibleConfirm(false)

      await SaidaZeroKmService.devolverSaida({
        chaveNotaFiscal: chaveNfe,
        idEstoque: idEstoque
      })

      hide()

      await getAll()

      showSuccessMessage('Veículo devolvido com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao devolver veículo!')
    }
  }

  const getListaMovChassi = async () => {
    let lista = await EstoqueRenaveService.getByChassi(chassi)
    setListaMovChassi(lista)
  }
  return (
    <>
      <Modal
        header={'Devolver para a montadora o veículo: ' + chassi}
        visible={visible}
        onHide={hide}
        width={50}
        footer={
          <div className="col-12 grid justify-content-end">
            <Button
              label="Confirmar"
              className="p-button-danger"
              onClick={() => {
                let podeDevolver = false
                if (idEstoqueOrigemTransferencia > 0) {
                  if (listaMovChassi) {
                    listaMovChassi.forEach(element => {
                      if (element.idEstoqueOrigemTransferencia == null) {
                        podeDevolver = true
                      }
                    })
                  }
                  if (!podeDevolver) {
                    showErrorMessage('Não é permitido devolver para montadora veículos vindo de transferência!')
                  } else {
                    setVisibleConfirm(true)
                  }
                } else {
                  if (chaveNfe === '') {
                    showErrorMessage('Para devolver um veículo é necessário uma chave NF-e!')
                  } else if (chaveNfe.length !== 44) {
                    showErrorMessage('Chave da Nota fiscal deve ter 44 caracteres')
                  } else {
                    setVisibleConfirm(true)
                  }
                }
              }}
            />
          </div>
        }
      >
        <div className="field col-12">
          <TextInput
            label="Chave da NF-e"
            value={chaveNfe}
            onChange={(e) => setChaveNfe(e.target.value)}
            className="inputfield w-full"
          />
        </div>
      </Modal>
      <Confirm
        visible={visibleConfirm}
        onConfirm={() => handleDevolverVeiculo()}
        onCancel={() => setVisibleConfirm(false)}
        title="Confirmação"
        description="Confirma devolver veículo para montadora?"
        cancelLabel="Não"
      />
    </>
  )
}

export default DevolverVeiculoModal
