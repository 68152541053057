import React, { useEffect, useState } from 'react'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import TextInput from '../../../components/inputs/TextInput'
import { useValidateInput } from '../../../helpers/useValidateInput'
import { classNames } from 'primereact/utils'
import { SelectButton } from 'primereact/selectbutton'
import CommonHelper from '../../../helpers/CommonHelper'
import EstadosService from '../../../services/localidade/EstadosService'
import SelectInput from '../../../components/inputs/SelectInput'
import { showErrorMessage } from '../../../components/utils/Message'
import CEPService from '../../../services/localidade/CEPService'
import MunicipioService from '../../../services/localidade/MunicipioService'

const DadosPrincipaisPes = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])
  const [tipoPessoa, setTipoPessoa] = useState('CPF')
  const tiposPessoa = ['CPF', 'CNPJ']

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  useEffect(async () => {
    await getEstados()

    if (form.values.codufPes) {
      try {
        const estado = await EstadosService.getPorId(form.values.codufPes)
        await getCidadesPorEstado(estado.sigla_uf)
        form.setFieldValue('estado', estado)
      } catch (error) {
        showErrorMessage('Erro ao buscar estado!')
      }
    }
    if (form.values.codmunPes) {
      try {
        const municipio = await MunicipioService.getPorId(form.values.codmunPes)
        form.setFieldValue('municipio', municipio)
      } catch (error) {
        showErrorMessage('Erro ao buscar município!')
      }
    }
  }, [])

  useEffect(() => {
    if (!form.values.codigoPes) {
      form.setFieldValue('tipoPes', tipoPessoa === 'CPF' ? 'F' : 'J')
    } else {
      setTipoPessoa(form.values.tipoPes === 'J' ? 'CNPJ' : 'CPF')
    }
  }, [tipoPessoa])

  const getCidadesPorEstado = async (estadoSigla) => {
    try {
      const cidades = await MunicipioService.getPorEstado(estadoSigla)
      setCidades(cidades)
    } catch (e) {
      showErrorMessage('Erro ao buscar as cidades do estado!')
    }
  }

  const getEstados = async () => {
    try {
      const estados = await EstadosService.getTodos()
      setEstados(estados)
    } catch (error) {
      showErrorMessage('Erro ao buscar os estados!')
    }
  }

  const handleCepComplete = async (e) => {
    const cep = e.value
    if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
      try {
        const endereco = await CEPService.getCEP(cep)
        await getCidadesPorEstado(endereco.uf)
        const estado = await EstadosService.getPorSigla(endereco.uf)
        const municipio = await MunicipioService.getPorId(Number(endereco.ibge))

        form.setFieldValue('estado', { sigla_uf: endereco.uf })
        form.setFieldValue('codufPes', estado.codigo_uf)
        form.setFieldValue('municipio', municipio)
        form.setFieldValue('codmunPes', municipio.codigo_mun)
        form.setFieldValue('bairroPes', endereco.bairro)
        form.setFieldValue('endPes', endereco.logradouro)
        form.validateField('codufPes')
        form.validateField('codmunPes')
        form.validateField('bairroPes')
        form.validateField('endPes')
      } catch (error) {
        showErrorMessage('Erro ao buscar CEP!')
      }
    }
  }

  const handleChangeEstado = (e) => {
    const estado = e.target.value
    form.validateField('codufPes')

    if (estado) {
      form.setFieldValue('codmunPes', null)
      form.setFieldValue('codufPes', estado.codigo_uf)
      form.setFieldValue('estado', estado)
      form.setFieldValue('municipio', {})

      getCidadesPorEstado(estado.sigla_uf)
    }
  }

  const handleChangeCidade = (e) => {
    const cidade = e.target.value

    if (cidade) {
      form.setFieldValue('codmunPes', cidade.codigo_mun)
      form.setFieldValue('municipio', cidade)
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomrazPes')}/>
        <TextInput
          value={form.values.nomrazPes}
          name="nomrazPes"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomrazPes') }, 'inputfield w-full mt-2')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome/Razão Social"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomrazPes')}/>
      </div>
      <div className="field col-12 md:col-3">
        <SelectButton
          value={tipoPessoa}
          options={tiposPessoa}
          className="option-select"
          disabled={!edicaoHabilitada}
          onChange={(e) => {
            if (e.value) {
              setTipoPessoa(e.value)
            }
          }}
        />
        <TextInput
          name="cgccpfPes"
          placeholder="___.___.___-__"
          value={form.values.cgccpfPes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cgccpfPes') }, 'inputfield w-full')}
          mask={tipoPessoa === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('cgccpfPes')}/>
      </div>
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Email" valid={isFormFieldValid('emailPes')}/>
        <TextInput
          value={form.values.emailPes}
          name="emailPes"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('emailPes') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Email"
        />
        <FieldErrorMessage message={getFormErrorMessage('emailPes')}/>
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Telefone residencial"
          placeholder="Telefone residencial"
          name="telresPes"
          value={form.values.telresPes}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Telefone Celular"
          placeholder="Telefone celular"
          name="telcelPes"
          value={form.values.telcelPes}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="CEP" valid={isFormFieldValid('cepPes')}/>
        <TextInput
          placeholder="CEP"
          name="cepPes"
          value={form.values.cepPes}
          onChange={form.handleChange}
          mask="99999-999"
          onComplete={handleCepComplete}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cepPes') }, 'inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cepPes')}/>
      </div>
      <div className="field col-12 md:col-5">
        <RequiredLabel label="Endereço" valid={isFormFieldValid('endPes')}/>
        <TextInput
          value={form.values.endPes}
          name="endPes"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('endPes') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Endereço"
        />
        <FieldErrorMessage message={getFormErrorMessage('endPes')}/>
      </div>
      <div className="field col-6 md:col-1">
        <TextInput
          label="Número"
          placeholder="Número"
          name="numendPes"
          maxLength={10}
          className="inputfield w-full"
          value={form.values.numendPes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          value={form.values.comendPes}
          label="Complemento"
          name="comendPes"
          onChange={form.handleChange}
          className="inputfield w-full"
          disabled={!edicaoHabilitada}
          placeholder="Complemento"
        />
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="Bairro" valid={isFormFieldValid('bairroPes')}/>
        <TextInput
          value={form.values.bairroPes}
          name="bairroPes"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('bairroPes') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Bairro"
        />
        <FieldErrorMessage message={getFormErrorMessage('bairroPes')}/>
      </div>
      <div className="field col-12 md:col-5">
        <RequiredLabel label="Estado" valid={isFormFieldValid('codufPes')}/>
        <SelectInput
          value={form.values.estado}
          options={estados}
          onChange={handleChangeEstado}
          placeholder="Selecione"
          name="estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="sigla_uf"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codufPes') }, 'inputfield w-full')}
          onHide={() => form.setFieldTouched('codufPes')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codufPes')}/>
      </div>
      <div className="field col-12 md:col-4">
        <RequiredLabel label="Município" valid={isFormFieldValid('codmunPes')}/>
        <SelectInput
          value={form.values.municipio}
          options={cidades}
          onChange={handleChangeCidade}
          placeholder="Selecione"
          name="municipio"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_mun"
          dataKey="codigo_mun"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codmunPes') }, 'inputfield w-full')}
          onHide={() => form.setFieldTouched('codmunPes')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codmunPes')}/>
      </div>
    </div>
  )
}

export default DadosPrincipaisPes
