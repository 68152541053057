import ServiceBase from '../base/ServiceBase'

export default class ConviteLojistaService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`convites?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`convites?${query}&page=${page}&perPage=${perPage}`)
  }
}
