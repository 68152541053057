import { Button } from 'primereact/button'
import React from 'react'
import AccordionContainer from '../../components/layout/AccordionContainer'
import AccordionPage from '../../components/layout/AccordionPage'
import Container from '../../components/layout/Container'
import { baseForm } from '../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import { setMensagemGlobal } from '../../redux/actions'
import { dispatch } from '../../redux/store'
import MensagemGlobalService from '../../services/suporte/MensagemGlobalService'
import DadosPrincipaisMen from './DadosPrincipaisMen'

const MensagemGlobalForm = ({ model }) => {
  const handleSubmit = async (data) => {
    try {
      const mensagemGlobal = await MensagemGlobalService.save(data)

      dispatch(setMensagemGlobal(mensagemGlobal))

      showSuccessMessage('Mensagem global atualizada com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao atualizar mensagem global')
    }
  }

  const form = baseForm({
    initialValues: model,
    onSubmit: handleSubmit
  })

  return (
    <Container col="12">
      <form onSubmit={form.handleSubmit}>
        <div className="flex justify-content-between form-header">
          <h1>Mensagem global</h1>
        </div>
        <div className="grid form-body">
          <AccordionContainer>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisMen
                form={form}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end form-footer">
          <Button
            type="submit"
            label="Salvar"
            className="p-button"
          />
        </div>
      </form>
    </Container>
  )
}

export default MensagemGlobalForm
