import ServiceBase from '../../base/ServiceBase'

export default class ImpressaoATPVeService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`veiculosFinalizados?page=${page}&perPage=${perPage}&exibirVeiculosEntregues=true`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`veiculosFinalizados?${query}&page=${page}&perPage=${perPage}&exibirVeiculosEntregues=true`)
  }

  static async filterQuery (query) {
    // console.log('passou por aquui: ', query)
    return await ServiceBase.getBase(`veiculosFinalizados?${query}`)
  }

  static async getTodos () {
    return await ServiceBase.getBase('veiculosFinalizados')
  }
}
