import produce from 'immer'
import { CLEAR_STORE, SET_CERTIFICADO_VALIDO_ITE, SET_CERTIFICADO_VALIDO_MONTADORA, SET_CERTIFICADO_VALIDO_VENDA_VEICULOS, SET_EMPRESA_LOGADA, SET_MENSAGEM_GLOBAL, SET_USUARIO_LOGADO, START_LOADING, START_RENAVE, STOP_LOADING, STOP_RENAVE } from './types'

const initialState = {
  loading: false,
  comunicaRenave: false,
  usuarioLogado: {},
  empresaLogada: {},
  mensagemGlobal: {},
  certificadoValidoVendaVeiculos: false,
  certificadoValidoIte: false,
  certificadoValidoMontadora: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
  case CLEAR_STORE:
    return clearStore()
  case START_LOADING:
    return startLoading()
  case STOP_LOADING:
    return stopLoading()
  case START_RENAVE:
    return startRenave()
  case STOP_RENAVE:
    return stopRenave()
  case SET_USUARIO_LOGADO:
    return setUsuarioLogado(action)
  case SET_EMPRESA_LOGADA:
    return setEmpresaLogada(action)
  case SET_MENSAGEM_GLOBAL:
    return setMensagemGlobal(action)
  case SET_CERTIFICADO_VALIDO_VENDA_VEICULOS:
    return setCertificadoValidoVendaVeiculos(action)
  case SET_CERTIFICADO_VALIDO_ITE:
    return setCertificadoValidoIte(action)
  case SET_CERTIFICADO_VALIDO_MONTADORA:
    return setCertificadoValidoMontadora(action)
  default:
    return state
  }

  function clearStore () {
    return produce(state, draft => {
      draft.loading = false
      draft.comunicaRenave = false
      draft.usuarioLogado = {}
      draft.empresaLogada = {}
      draft.mensagemGlobal = {}
    })
  }

  function startLoading () {
    return produce(state, draft => {
      draft.loading = true
    })
  }

  function stopLoading () {
    return produce(state, draft => {
      draft.loading = false
    })
  }

  function startRenave () {
    return produce(state, draft => {
      draft.comunicaRenave = true
    })
  }

  function stopRenave () {
    return produce(state, draft => {
      draft.comunicaRenave = false
    })
  }

  function setUsuarioLogado (action) {
    return produce(state, draft => {
      draft.usuarioLogado = action.payload
    })
  }

  function setEmpresaLogada (action) {
    return produce(state, draft => {
      draft.empresaLogada = action.payload
    })
  }

  function setMensagemGlobal (action) {
    return produce(state, draft => {
      draft.mensagemGlobal = action.payload
    })
  }

  function setCertificadoValidoVendaVeiculos (action) {
    return produce(state, draft => {
      draft.certificadoValidoVendaVeiculos = action.payload
    })
  }

  function setCertificadoValidoIte (action) {
    return produce(state, draft => {
      draft.certificadoValidoIte = action.payload
    })
  }

  function setCertificadoValidoMontadora (action) {
    return produce(state, draft => {
      draft.certificadoValidoMontadora = action.payload
    })
  }
}
