import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import UsuarioService from '../../services/cadastro/UsuarioService'
import TextInput from '../inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from './Message'
import Modal from './Modal'

const ChangePasswordModal = ({ visible, usuarioLogado, hide, reloadUsuario }) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [hasToChange, setHasToChange] = useState(false)

  useEffect(() => {
    if (usuarioLogado.codigo_usu) {
      setHasToChange(!!usuarioLogado.altsen_usu)
    }
  }, [usuarioLogado])

  const handleChangePassword = async () => {
    try {
      if (currentPassword === '') throw Error('O campo senha atual deve ser preenchido!')
      if (newPassword === '') throw Error('O campo nova senha deve ser preenchido!')
      if (confirmPassword === '') throw Error('O campo confirmar nova senha deve ser preenchido!')
      if (newPassword !== confirmPassword) throw Error('As novas senhas não coincidem!')
      await UsuarioService.changePassword({ senhaatual: currentPassword, novasenha: newPassword })
      showSuccessMessage('Sua senha foi alterada com sucesso!')
      onHide()
      if (usuarioLogado.altsen_usu) {
        clearInputs()
        reloadUsuario()
        setHasToChange(false)
      }
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao tentar trocar a senha!')
    }
  }

  const onHide = () => {
    if (!hasToChange) {
      clearInputs()
      hide()
    }
  }

  const clearInputs = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  return (
    <Modal
      header="Troque sua senha"
      focusOnShow={false}
      visible={usuarioLogado.altsen_usu ? hasToChange : visible}
      width={15}
      onHide={onHide}
      footer={
        <Button
          label="Alterar"
          icon="pi pi-check"
          onClick={() => handleChangePassword()}
          autoFocus={true} />
      }
    >
      <div className="p-grid p-fluid">
        <div className="field col-12">
          <TextInput
            type="password"
            label="Senha atual"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)} />
        </div>
        <div className="field col-12">
          <TextInput
            type="password"
            label="Nova senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div className="field col-12">
          <TextInput
            type="password"
            label="Confirmar nova senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
      </div>
    </Modal>
  )
}

export default ChangePasswordModal
