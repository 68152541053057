import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Column } from 'primereact/column'
import List from '../../../classes/List'
import DataList from '../../../components/utils/DataList'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import Confirm from '../../../components/utils/Confirm'
import { formatDate } from '../../../helpers/formaters'
import DateInput from '../../../components/inputs/DateInput'
import CommonHelper from '../../../helpers/CommonHelper'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import { getVeiculoEntradaDTO } from '../../../dtos/renave/VeiculoEntradaDTO'
import VeiculoConfirmadoService from '../../../services/zeroKm/veiculos/VeiculoConfirmadoService'

import EntradaMontadoraService from '../../../services/montadora/EntradaMontadoraService'

export class CancelarEntradaMontadora extends List {
  constructor (props) {
    super(
      props,
      getVeiculoEntradaDTO,
      VeiculoConfirmadoService,
      '',
      '',
      'confirmados'
    )

    this.state = {
      ...this.state,
      veiculoParaCancelar: {}
    }
  }

  handleCancelarEntrada = async () => {
    try {
      await EntradaMontadoraService.cancelarEntrada(this.state.veiculoParaCancelar.chassi)

      this.setState({ visibleConfirm: false })

      await this.onFilterClick()

      showSuccessMessage('Entrada de veículo cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar entrada!')
    }
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.dataEntradaInicial = CommonHelper.dateToAmerican(this.state.dataEntradaInicial)
    filter.dataEntradaFinal = CommonHelper.dateToAmerican(this.state.dataEntradaFinal)
    filter.montadora = true

    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data de entrada do veículo</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataEntradaInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataEntradaInicial}
              onChange={(e) => this.setState({ dataEntradaInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataEntradaFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataEntradaFinal}
              onChange={(e) => this.setState({ dataEntradaFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          <Button
            label="Cancelar entrada"
            className="expansion-button p-button-danger"
            onClick={() => this.setState({ visibleConfirm: true, veiculoParaCancelar: data })}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Cancelar entrada</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'confirmados' }, 'filter-option')}>
              <span className="option-label">Confirmados</span>
              <div className="option-quantity">
                {totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            responsive
            showFilter
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            <Column
              style={{ width: '60%' }}
              header="Veículo"
              body={data => {
                const chassi = data.chassi
                const marcaModelo = data.marcaModelo

                if (marcaModelo) {
                  return `${chassi} - ${marcaModelo}`
                }

                return chassi
              }}
            />
            <Column
              style={{ width: '10%' }}
              header="Fab/Mod"
              body={(data) => data.anoFab ? (data.anoFab + '/' + data.anoMod) : ''}
            />
            <Column
              style={{ width: '15%' }}
              header="Cor"
              field="cor"
            />
            <Column
              style={{ width: '15%' }}
              header="Data Entrada"
              body={(data) => data.dataEntrada ? formatDate(data.dataEntrada) : ''}
            />
          </DataList>
        </Container>
        <Confirm
          title="Cancelar entrada"
          visible={this.state.visibleConfirm}
          onCancel={() => this.setState({ visibleConfirm: false })}
          onConfirm={() => this.handleCancelarEntrada()}
          description="Confirma cancelar a entrada?"
          cancelLabel="Não"
        />
      </Page>
    )
  }
}
